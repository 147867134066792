// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switcher-tools {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b8b8b8;
    border: 1px solid #ccc;

    border-radius: 25px 0 ;
    overflow: hidden;
    cursor: pointer;
    margin-left: calc(25% - 65px);
    font-size: 18px;
}

.switcher-tools-2 {
    width: 600px;
    height: 35px;
}

.switcher-tools-3 {
    flex-direction: column;
    width: 100px;
    height: 150px;
}

.switcher-option-tools {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    transition: background-color 0.3s;
}

.switcher-tools-2 .switcher-option-tools {
    width: 50%;
    height: 100%;
    text-align: center;
}

.switcher-3 .switcher-option-tools {
    width: 100%;
    height: 33.33%;
    text-align: center;
}

.switcher-option-tools.active-switcher {
    background-color: #E30613;
    color: #fff;
    transition: 0.3s;
}

.switcher-option-tools:hover {
    opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/components/Switcher/SwitcherTools.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,sBAAsB;;IAEtB,sBAAsB;IACtB,gBAAgB;IAChB,eAAe;IACf,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,iCAAiC;AACrC;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,gBAAgB;AACpB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".switcher-tools {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #b8b8b8;\n    border: 1px solid #ccc;\n\n    border-radius: 25px 0 ;\n    overflow: hidden;\n    cursor: pointer;\n    margin-left: calc(25% - 65px);\n    font-size: 18px;\n}\n\n.switcher-tools-2 {\n    width: 600px;\n    height: 35px;\n}\n\n.switcher-tools-3 {\n    flex-direction: column;\n    width: 100px;\n    height: 150px;\n}\n\n.switcher-option-tools {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 10px;\n    transition: background-color 0.3s;\n}\n\n.switcher-tools-2 .switcher-option-tools {\n    width: 50%;\n    height: 100%;\n    text-align: center;\n}\n\n.switcher-3 .switcher-option-tools {\n    width: 100%;\n    height: 33.33%;\n    text-align: center;\n}\n\n.switcher-option-tools.active-switcher {\n    background-color: #E30613;\n    color: #fff;\n    transition: 0.3s;\n}\n\n.switcher-option-tools:hover {\n    opacity: 0.6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
