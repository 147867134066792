import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { RootState } from "../store";
import { checkAuthentication } from "../Slice/AuthSlice/AuthSlice";

interface SessionPolicyProps {
    children: React.ReactNode;
    isAuthenticated: boolean;
    hasBeenAuthenticated: boolean;
    checkAuthentication: () => void;
}

const SessionPolicy: React.FC<SessionPolicyProps> = ({ children, checkAuthentication }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const bearerToken = localStorage.getItem('Bearer');

    useEffect(() => {
        checkAuthentication();
        if (bearerToken) {
            if (location.pathname === '/auth/login' || location.pathname === '/') {
                navigate('/assistance');
                toast.success('Successfully authenticated!', {
                    position: "bottom-center",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    style: {
                        backgroundColor: "#ffffff",
                        color: "#000000",
                        fontFamily: "Segoe UI, Roboto, Arial, sans-serif",
                        fontSize: "16px",
                        fontWeight: "500",
                        width: "30vw",
                        borderRadius: "8px",
                        padding: "16px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                    },
                    autoClose: 2000
                });
            }
        } else {
            if (location.pathname !== '/auth/login') {
                navigate('/auth/login');
                toast.error('Authentication required!', {
                    position: "bottom-center",
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    style: {
                        backgroundColor: "#ffcccc",
                        color: "#660000",
                        fontFamily: "Segoe UI, Roboto, Arial, sans-serif",
                        fontSize: "16px",
                        fontWeight: "500",
                        width: "30vw",
                        borderRadius: "8px",
                        padding: "16px",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                    },
                    autoClose: 3000
                });
            }
        }
    }, [bearerToken, location.pathname, navigate, checkAuthentication]);

    return <>{children}</>;
};

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuthenticated,
    hasBeenAuthenticated: state.auth.hasBeenAuthenticated,
});

const mapDispatchToProps = {
    checkAuthentication,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionPolicy);
