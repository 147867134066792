import React from 'react';
import Modal from 'react-modal';
import './ModalDelete.css';

interface ModalDeleteProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onConfirm: () => void;
    onCancel: () => void;
    message: string;
    confirmText: string;
    cancelText: string;
}

const ModalDelete: React.FC<ModalDeleteProps> = ({ isOpen, onRequestClose, onConfirm, onCancel, message, confirmText, cancelText }) => {
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="warning-modal"
            overlayClassName="overlay"
        >
            <div className="warning">
                <button className="close-button" onClick={onCancel}>&times;</button>
            </div>
            <div className="warning">
                <p>{message}</p>
                <div className="confirmation-buttons">
                    <button className="confirm-button" onClick={onConfirm}>{confirmText}</button>
                    <button className="cancel-button" onClick={onCancel}>{cancelText}</button>
                </div>
            </div>
        </Modal>
    );
};

export default ModalDelete;
