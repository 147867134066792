// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.show-more-list {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    flex: 1 1;
}


.show-more-list-button{
    position: absolute;
    right: 2%;
    transition: 0.2s;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    background-color: #E30613;
    color: white;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
.show-more-list-button:hover{
    cursor: pointer;
    transition: 0.2s;
    transform: scale(1.1);
}


.question-answers-container {
    margin-bottom: 20px;
}

.fragments-container {
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ShowMoreListStep2/ShowMoreListStep2.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,SAAS;IACT,SAAQ;AACZ;;;AAGA;IACI,kBAAkB;IAClB,SAAS;IACT,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,YAAY;IACZ,eAAe;IACf,iBAAiB;IACjB,wCAAwC;AAC5C;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,qBAAqB;AACzB;;;AAGA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".show-more-list {\n    display: flex;\n    flex-wrap: wrap;\n    gap: 16px;\n    flex: 1 ;\n}\n\n\n.show-more-list-button{\n    position: absolute;\n    right: 2%;\n    transition: 0.2s;\n    padding: 10px 20px;\n    border: none;\n    border-radius: 20px;\n    background-color: #E30613;\n    color: white;\n    font-size: 16px;\n    font-weight: bold;\n    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n.show-more-list-button:hover{\n    cursor: pointer;\n    transition: 0.2s;\n    transform: scale(1.1);\n}\n\n\n.question-answers-container {\n    margin-bottom: 20px;\n}\n\n.fragments-container {\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
