import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps } from "react-redux";
import {ItemType, TItem, TMerchanInfo} from "../../../types/TemplateType";
import { Project } from "../../../types/ProjectTypes";
import { get, patch } from "../../../api/APIWraper";
import { updateLocalTemplate } from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import ItemSelectionModal from "../ItemSelectionModal";
import "../Step2Styles/step2.css";
import "../Step2Styles/step2-width.css";
import "../Step2Styles/itemModal.css";
import {getVendorLogo, Vendor} from "../../../Enums/VendorEnum";

interface ItemTypeListCalculationProps {
    fragment: {
        id: number;
        data: {
            item_types: ItemType[];
        };
    };
    fragmentIndex: number;
    templateIndex: number;
    templateId: number;
    project: Project;
    viewMode: 'fragments' | 'types';
    onUpdateProject: (newProject: Project) => void;
    onToggle: () => void;
}

interface SelectedItemInfo {
    merchant_info?: TMerchanInfo;
    vendor: Vendor | null;
    user_item_qty?: number;
    title: string;
    id: number;
    qty: number;
    ordered_qty: number;
    unit: string;
    price: number;
}

interface SelectedItem {
    itemTypeId: number;
    itemId: number;
    index: number;
    user_item_qty: number;
    clone_id: string;
}

interface ItemTypeWithIndex extends ItemType {
    originalIndex: number;
}

const ItemTypeListCalculation: React.FC<ItemTypeListCalculationProps & PropsFromRedux> = ({
                                                                                              fragment,
                                                                                              fragmentIndex,
                                                                                              templateId,
                                                                                              templateIndex,
                                                                                              project,
                                                                                              viewMode,
                                                                                              onUpdateProject,
                                                                                              onToggle
                                                                                          }) => {
    const [selectedItemTypeIndex, setSelectedItemTypeIndex] = useState<number | null>(null);
    const [items, setItems] = useState<TItem[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [selectedItemInfos, setSelectedItemInfos] = useState<{ [id: string]: SelectedItemInfo }>({});
    const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
    const [totalPrice, setTotalPrice] = useState(0);
    const [debouncedOrderedQty, setDebouncedOrderedQty] = useState<{ [id: string]: string }>({});
    const [parsedItemTypes, setParsedItemTypes] = useState<{ [index: number]: any }>({});


    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [totalItems, setTotalItems] = useState(0);
    const [searchTerm, setSearchTerm] = useState<string>('');


    useEffect(() => {
        if (Array.isArray(fragment.data.item_types)) {
            const fragmentItems = fragment.data.item_types.flatMap((itemType, idx) =>
                (itemType.items || []).map((item, itemIdx) => ({
                    itemTypeId: itemType.id,
                    itemId: item.id,
                    index: idx,
                    user_item_qty: item.qty,
                    clone_id: `${item.id}-${idx}-${itemIdx}`
                }))
            );

            const initialSelectedItems = [
                ...fragmentItems,
                ...fragment.data.item_types
                    .map((itemType, index) => ({ ...itemType, originalIndex: index }))
                    .filter(itemType => itemType.item && (viewMode === 'fragments' || (!itemType.labor && !itemType.tool)))
                    .map((itemType, idx) => ({
                        itemTypeId: itemType.id,
                        itemId: itemType.item.id,
                        index: itemType.originalIndex,
                        user_item_qty: itemType.item.user_item_qty,
                        clone_id: `${itemType.id}-${itemType.originalIndex}-${idx}`
                    }))
            ];

            setSelectedItems(initialSelectedItems);

            const initialSelectedItemInfos = initialSelectedItems.reduce((acc, selectedItem) => {
                const itemType = fragment.data.item_types[selectedItem.index];
                const item = itemType.items ? itemType.items.find(it => it.id === selectedItem.itemId) : null;
                if (itemType && item) {
                    acc[selectedItem.clone_id] = {
                        merchant_info: {} as TMerchanInfo,
                        vendor: item.vendor,
                        user_item_qty: selectedItem.user_item_qty,
                        title: item.title,
                        id: item.id,
                        qty: item.qty || 0,
                        ordered_qty: item.ordered_qty || 0,
                        unit: item.unit,
                        price: item.price || 0
                    };
                }
                return acc;
            }, {} as { [id: string]: SelectedItemInfo });

            setSelectedItemInfos(initialSelectedItemInfos);

            const parsedItemTypes = fragment.data.item_types.reduce((acc, itemType, index) => {
                if (viewMode === 'types' && (itemType.labor || itemType.tool)) {
                    return acc;
                }
                const clone_id = `${itemType.id}-${index}-${index}`;
                const item = initialSelectedItemInfos[clone_id];
                const title = itemType.title;
                let qty = item ? item.qty : itemType.qty;
                if (!qty) {
                    qty = 0;
                }
                let orderedQty = item ? item.ordered_qty : itemType.ordered_qty;
                if (!orderedQty) {
                    orderedQty = 0;
                }
                const price = itemType.item ? itemType.item.price : 0;
                const priceCurrentQty = item ? item.price * orderedQty : 0;
                const priceTotalQty = item ? item.price * qty : 0;
                const isItemSelected = !!itemType.item;
                const unit = itemType.item ? itemType.item.unit : '';

                acc[index] = {
                    index,
                    title,
                    qty,
                    orderedQty,
                    price,
                    priceCurrentQty,
                    priceTotalQty,
                    isItemSelected,
                    unit
                };

                return acc;
            }, {} as { [index: number]: any });

            setParsedItemTypes(parsedItemTypes);
            calculateTotalPrice();
        }
    }, [fragment.data.item_types, viewMode]);


    useEffect(() => {
        calculateTotalPrice();
    }, [selectedItemInfos]);

    useEffect(() => {
        const handler = setTimeout(() => {
            Object.keys(debouncedOrderedQty).forEach(id => {
                setSelectedItemInfos((prev) => {
                    const newOrderedQty = parseInt(debouncedOrderedQty[id]) || 0;
                    return {
                        ...prev,
                        [id]: {
                            ...prev[id],
                            ordered_qty: newOrderedQty,
                        },
                    };
                });
            });
            calculateTotalPrice();
        }, 500);

        return () => {
            clearTimeout(handler);
        };
    }, [debouncedOrderedQty, selectedItems]);

    const calculateTotalPrice = () => {
        let totalPrice = 0;
        selectedItems.forEach((selectedItem) => {
            const item = selectedItemInfos[selectedItem.clone_id];
            if (item) {
                const generalPrice = item.price * (item.user_item_qty || item.qty);
                const difTotalPrice = Math.max(0, item.price * Math.min(item.ordered_qty, (item.user_item_qty || item.qty)));
                totalPrice += (generalPrice - difTotalPrice);
            }
        });
        setTotalPrice(totalPrice);
    };

    const storeId = project?.store?.id_store ? project.store.id_store : 0;

    const fetchItems = async (index: number, page: number = 1,searchTerm: string = '') => {
        setSelectedItemTypeIndex(index);
        setLoading(true);
        try {
            const url = searchTerm
                ? `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${fragment.data.item_types[index].id}?page=${page}&perPage=${itemsPerPage}&title=${searchTerm}&storeIdFromProjects=${storeId}`
                : `${process.env.REACT_APP_BASE_URL_CUSTOMER}/item_types/${fragment.data.item_types[index].id}?page=${page}&perPage=${itemsPerPage}&storeIdFromProjects=${storeId}`

            const response = await get(url);
            setTotalItems(response.data.items.pagination.lastPage);
            const itemsWithQty = (Array.isArray(response.data.items.data) ? response.data.items.data : [response.data.items.data]).map(
                (item: ItemType) => ({
                    ...item,
                    qty_item_type: fragment.data.item_types[index].qty,
                })
            );
            setItems(itemsWithQty);
        } catch (error) {
            console.error("Failed to get elements:", error);
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, pageNumber, searchTerm);
        }
    };


    const handleButtonClick = (index: number) => {
        setIsModalOpen(true);
        fetchItems(index, currentPage);
    };

    const handleReload = () => {
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, currentPage);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleItemClick = (items: TItem[], itemTypeIndex: number | null) => {
        if (itemTypeIndex !== null) {
            const updatedSelectedItems = [
                ...selectedItems.filter(selectedItem => selectedItem.index !== itemTypeIndex),
                ...items.map((item, idx) => ({
                    itemTypeId: fragment.data.item_types[itemTypeIndex].id,
                    itemId: item.id,
                    index: itemTypeIndex,
                    user_item_qty: item.user_item_qty,
                    clone_id: `${item.id}-${itemTypeIndex}-${idx}`
                }))
            ];

            const updatedItemInfos = items.reduce((acc, item, idx) => {
                const clone_id = `${item.id}-${itemTypeIndex}-${idx}`;
                acc[clone_id] = {
                    merchant_info: {} as TMerchanInfo,
                    vendor: item.vendor,
                    title: item.title,
                    id: item.id,
                    qty: item.qty,
                    ordered_qty: item.ordered_qty || 0,
                    user_item_qty: item.user_item_qty || 0,
                    unit: item.unit,
                    price: item.price || 0
                };
                return acc;
            }, { ...selectedItemInfos });

            setSelectedItemInfos(updatedItemInfos);
            setSelectedItems(updatedSelectedItems);
            setParsedItemTypes((prev) => ({
                ...prev,
                [itemTypeIndex]: {
                    ...prev[itemTypeIndex],
                    isItemSelected: items.length > 0,
                    qty: items.length > 0 ? items[0].qty : 0,
                    ordered_qty: items.length > 0 ? items[0].ordered_qty || 0 : 0,
                    user_item_qty: items.length > 0 ? items[0].user_item_qty || 0 : 0,
                    unit: items.length > 0 ? items[0].unit : '',
                    price: items.length > 0 ? items[0].price || 0 : 0,
                    priceCurrentQty: items.length > 0 ? (items[0].price || 0) * (items[0].user_item_qty || 0) : 0,
                    priceTotalQty: items.length > 0 ? (items[0].price || 0) * items[0].qty : 0,
                },
            }));

            calculateTotalPrice();
        }
        setIsModalOpen(false);
    };

    const handleQtyChange = (clone_id: string, newQty: string) => {
        const parsedQty = parseInt(newQty);
        if (!isNaN(parsedQty)) {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: newQty,
            }));
        } else {
            setDebouncedOrderedQty((prev) => ({
                ...prev,
                [clone_id]: '',
            }));
        }
        setSelectedItemInfos((prev) => ({
            ...prev,
            [clone_id]: {
                ...prev[clone_id],
                ordered_qty: parsedQty,
            },
        }));
        calculateTotalPrice();
    };

    const handleSave = async () => {
        const groupedItems: Record<string, {
            item_type_id: number,
            item_ids: { id: number, qty: number }[],
            template_index: number,
            fragment_index: number,
            item_type_index: number,
            template_id: number,
            fragment_id: number
        }> = selectedItems.reduce((acc, selection) => {
            const key = `${selection.itemTypeId}-${selection.index}`;
            if (!acc[key]) {
                acc[key] = {
                    item_type_id: selection.itemTypeId,
                    item_ids: [],
                    template_index: templateIndex,
                    fragment_index: fragmentIndex,
                    item_type_index: selection.index,
                    template_id: templateId,
                    fragment_id: fragment.id
                };
            }
            acc[key].item_ids.push({ id: selection.itemId, qty: selection.user_item_qty });
            return acc;
        }, {} as Record<string, {
            item_type_id: number,
            item_ids: { id: number, qty: number }[],
            template_index: number,
            fragment_index: number,
            item_type_index: number,
            template_id: number,
            fragment_id: number
        }>);

        fragment.data.item_types.forEach((itemType, idx) => {
            const key = `${itemType.id}-${idx}`;
            if (!groupedItems[key]) {
                groupedItems[key] = {
                    item_type_id: itemType.id,
                    item_ids: [],
                    template_index: templateIndex,
                    fragment_index: fragmentIndex,
                    item_type_index: idx,
                    template_id: templateId,
                    fragment_id: fragment.id
                };
            }
        });

        const body = Object.keys(groupedItems).map(key => groupedItems[key]);

        let updatedProject;
        try {
            const response = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/add_item`, { data: body });

            if (response.status === 200 || response.status === 201) {
                updatedProject = response.data;
                const itemOrderedQtyBody = selectedItems.map(selection => ({
                    ordered_qty: selectedItemInfos[selection.clone_id]?.ordered_qty || 0,
                    template_index: templateIndex,
                    fragment_index: fragmentIndex,
                    item_type_index: selection.index,
                    template_id: templateId,
                    fragment_id: fragment.id,
                    item_type_id: selection.itemTypeId,
                    item_id: selection.itemId,
                }));

                const responseOrderedQTY = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project.id}/item_ordered_qty`, { data: itemOrderedQtyBody });

                if (responseOrderedQTY.status === 200 || responseOrderedQTY.status === 201) {
                    updatedProject = responseOrderedQTY.data;
                }
            } else {
                throw new Error("Failed to update project.");
            }
            handleCloseModal();
        } catch (error) {
            console.error("Failed to save selections:", error);
        } finally {
            if (updatedProject != null) {
                onUpdateProject(updatedProject);
                onToggle();
            }
        }
    };


    if (!fragment.data.item_types || !Array.isArray(fragment.data.item_types)) {
        return null;
    }

    const filteredItemTypes = fragment.data.item_types
        .map((itemType, index) => ({ ...itemType, originalIndex: index }))
        .filter(itemType => !itemType.labor && !itemType.tool);

    const laborItems = viewMode === 'fragments' ? fragment.data.item_types
        .map((itemType, index) => ({ ...itemType, originalIndex: index }))
        .filter(itemType => itemType.labor) : [];

    const toolItems = viewMode === 'fragments' ? fragment.data.item_types
        .map((itemType, index) => ({ ...itemType, originalIndex: index }))
        .filter(itemType => itemType.tool) : [];

    const handleSearch = (query: string) => {
        setSearchTerm(query);
        if (selectedItemTypeIndex !== null) {
            fetchItems(selectedItemTypeIndex, 1, query);
        }
    };


    return (
        <div>
            {filteredItemTypes.length > 0 && (
                <>
                    {viewMode === 'fragments' && <h4 className='view-fragments-h3'>Product Items</h4>}
                    {filteredItemTypes.map((itemType: ItemTypeWithIndex, index: number) => {
                        const parsedItem = parsedItemTypes[itemType.originalIndex];
                        if (!parsedItem) return null;

                        const {
                            title,
                            qty,
                            orderedQty,
                            price,
                            priceTotalQty,
                        } = parsedItem;
                        const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === itemType.id && item.index === itemType.originalIndex);

                        return (
                            <div key={`${itemType.originalIndex}-${index}`} className='step2-item-type'>
                                <label
                                    className="step2-item-type-label"
                                    htmlFor={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                    style={{ color: "#828282" }}
                                >
                                    {title}
                                </label>
                                <div className='step2-item'>
                                    {selectedItemsForType.length === 0 ? (
                                        <>
                                            <button
                                                className="step2-item-button"
                                                id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                onClick={() => handleButtonClick(itemType.originalIndex)}
                                                disabled={loading}
                                            >
                                                {loading && selectedItemTypeIndex === itemType.originalIndex
                                                    ? "Loading..."
                                                    : "Select Products"}
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                          stroke="#0053A0" strokeWidth="1.5" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                    <circle cx="13" cy="13" r="12" stroke="#0053A0" strokeWidth="2"/>
                                                </svg>
                                            </button>
                                            <div className="step2-tooltip-container">
                                                <input
                                                    type="number"
                                                    className="step3-other-width-input step3-ordered-qty-input"
                                                    min="0"
                                                    disabled={!loading}
                                                />
                                            </div>
                                            <p className="step2- step2-other-width step2-margin-right">{qty} </p>
                                            <p className="step2- step2-other-width step2-margin-right">R {parseInt(price).toFixed(2)}</p>
                                            <p className="step2-item-total-ordered step2-margin-right">
                                                {(isNaN(orderedQty * price) || orderedQty > qty ? 0 : (orderedQty * price)).toFixed(2)}
                                            </p>
                                            <p className="step2- step2-other-width">R {Math.max(0, priceTotalQty).toFixed(2)}</p>
                                        </>
                                    ) : (
                                        <div className='step2-item-column'>
                                            {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const difPrice = item.ordered_qty ? item.price * item.ordered_qty : 0;
                                                const oldPrice = item?.price * (item.user_item_qty || item.qty);
                                                const difTotalPrice = isNaN(oldPrice - difPrice) ? 0 : oldPrice - difPrice;
                                                return (
                                                    <div key={`${selectedItem.clone_id}`} className='step2-item'>
                                                        <button
                                                            className="step2-item-button"
                                                            id={`button-${fragment.id}-${itemType.originalIndex}-${idx}`}
                                                            onClick={() => handleButtonClick(itemType.originalIndex)}
                                                            disabled={loading}
                                                        >
                                                            {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                ? "Loading..."
                                                                : item.title}
                                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                                      stroke="#0053A0" strokeWidth="1.5"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                        strokeWidth="2"/>
                                                            </svg>
                                                        </button>
                                                        <div className="step2-tooltip-container">
                                                            <input
                                                                type="number"
                                                                className="step3-other-width-input step3-ordered-qty-input"
                                                                value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                min="0"
                                                            />
                                                        </div>
                                                        <p className="step2-other-width step2-margin-right">{item.user_item_qty || item.qty} ({item.unit})</p>
                                                        <p className="step2-other-width step2-margin-right">R {parseInt(String(item.price)).toFixed(2)}</p>
                                                        <p className=" step2-item-total-ordered step2-margin-right">
                                                            {(isNaN(item.ordered_qty * item.price) || item.ordered_qty > item.qty ? 0 : (item.ordered_qty * item.price)).toFixed(2)}
                                                        </p>
                                                        <p className="step2-other-width">R {Math.max(0, difTotalPrice).toFixed(2)}</p>
                                                            <div className='step2-other-width-vendor'>
                                                                <img className='step3-vendor-img'     src={getVendorLogo(item.vendor as Vendor)}/>
                                                            </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            {viewMode === 'fragments' && laborItems.length > 0 && (
                <>
                    <h4 className='view-fragments-h3'>Labor Items</h4>
                    {laborItems.map((itemType: ItemTypeWithIndex, index: number) => {
                        const parsedItem = parsedItemTypes[itemType.originalIndex];
                        if (!parsedItem) return null;

                        const {
                            title,
                            qty,
                            orderedQty,
                            price,
                            priceTotalQty,
                        } = parsedItem;
                        const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === itemType.id && item.index === itemType.originalIndex);
                        return (
                            <div key={`${itemType.originalIndex}-${index}`} className='step2-item-type'>
                                <label
                                    className="step2-item-type-label"
                                    htmlFor={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                    style={{ color: "#828282" }}
                                >
                                    {title}
                                </label>
                                <div className='step2-item'>
                                    {selectedItemsForType.length === 0 ? (
                                        <>
                                            <button
                                                className="step2-item-button"
                                                id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                onClick={() => handleButtonClick(itemType.originalIndex)}
                                                disabled={loading}
                                            >
                                                {loading && selectedItemTypeIndex === itemType.originalIndex
                                                    ? "Loading..."
                                                    : "Select Labors"}
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                          stroke="#0053A0" strokeWidth="1.5" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                    <circle cx="13" cy="13" r="12" stroke="#0053A0" strokeWidth="2"/>
                                                </svg>
                                            </button>
                                            <div className="step2-tooltip-container">
                                                <input
                                                    type="number"
                                                    className="step3-other-width-input step3-ordered-qty-input"
                                                    min="0"
                                                    disabled={!loading}
                                                />
                                            </div>
                                            <p className="step2-other-width step2-margin-right">{qty}</p>
                                            <p className="step2-other-width step2-margin-right">R {parseInt(price).toFixed(2)}</p>
                                            <p className="step2-item-total-ordered step2-margin-right">
                                                {(isNaN(orderedQty * price) || orderedQty > qty ? 0 : (orderedQty * price)).toFixed(2)}
                                            </p>
                                            <p className="step2-other-width">R {Math.max(0, priceTotalQty).toFixed(2)}</p>

                                        </>
                                    ) : (
                                        <div className='step2-item-column'>
                                            {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const difPrice = item.ordered_qty ? item.price * item.ordered_qty : 0;
                                                const oldPrice = item?.price * (item.user_item_qty || item.qty);
                                                const difTotalPrice = isNaN(oldPrice - difPrice) ? 0 : oldPrice - difPrice;
                                                return (
                                                    <div key={`${selectedItem.clone_id}`} className='step2-item'>
                                                        <button
                                                            className="step2-item-button"
                                                            id={`button-${fragment.id}-${itemType.originalIndex}-${idx}`}
                                                            onClick={() => handleButtonClick(itemType.originalIndex)}
                                                            disabled={loading}
                                                        >
                                                            {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                ? "Loading..."
                                                                : item.title}
                                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                                      stroke="#0053A0" strokeWidth="1.5"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                        strokeWidth="2"/>
                                                            </svg>
                                                        </button>
                                                        <div className="step2-tooltip-container">
                                                            <input
                                                                type="number"
                                                                className="step3-other-width-input step3-ordered-qty-input"
                                                                value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                min="0"
                                                            />
                                                        </div>
                                                        <p className="step2-other-width step2-margin-right">{item.user_item_qty || item.qty} ({item.unit})</p>
                                                        <p className="step2-other-width step2-margin-right">R {parseInt(String(item.price)).toFixed(2)}</p>
                                                        <p className=" step2-item-total-ordered step2-margin-right">
                                                            {(isNaN(item.ordered_qty * item.price) || item.ordered_qty > item.qty ? 0 : (item.ordered_qty * item.price)).toFixed(2)}
                                                        </p>
                                                        <p className="step2-other-width">R {Math.max(0, difTotalPrice).toFixed(2)}</p>
                                                            <div className='step2-other-width-vendor'>
                                                                <img className='step3-vendor-img'     src={getVendorLogo(item.vendor as Vendor)}/>
                                                            </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            {viewMode === 'fragments' && toolItems.length > 0 && (
                <>
                    <h4 className='view-fragments-h3'>Tool Items</h4>
                    {toolItems.map((itemType: ItemTypeWithIndex, index: number) => {
                        const parsedItem = parsedItemTypes[itemType.originalIndex];
                        if (!parsedItem) return null;

                        const {
                            title,
                            qty,
                            orderedQty,
                            price,
                            priceTotalQty,
                        } = parsedItem;
                        const selectedItemsForType = selectedItems.filter(item => item.itemTypeId === itemType.id && item.index === itemType.originalIndex);
                        return (
                            <div key={`${itemType.originalIndex}-${index}`} className='step2-item-type'>
                                <label
                                    className="step2-item-type-label"
                                    htmlFor={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                    style={{ color: "#828282" }}
                                >
                                    {title}
                                </label>
                                <div className='step2-item'>
                                    {selectedItemsForType.length === 0 ? (
                                        <>
                                            <button
                                                className="step2-item-button"
                                                id={`button-${fragment.id}-${itemType.originalIndex}-${index}`}
                                                onClick={() => handleButtonClick(itemType.originalIndex)}
                                                disabled={loading}
                                            >
                                                {loading && selectedItemTypeIndex === itemType.originalIndex
                                                    ? "Loading..."
                                                    : "Select Tools"}
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                     xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                          stroke="#0053A0" strokeWidth="1.5" strokeLinecap="round"
                                                          strokeLinejoin="round"/>
                                                    <circle cx="13" cy="13" r="12" stroke="#0053A0" strokeWidth="2"/>
                                                </svg>
                                            </button>
                                            <div className="step2-tooltip-container">
                                                <input
                                                    type="number"
                                                    className="step3-other-width-input step3-ordered-qty-input"
                                                    min="0"
                                                    disabled={!loading}
                                                />
                                            </div>
                                            <p className="step2-other-width step2-margin-right">{qty}</p>
                                            <p className="step2-other-width step2-margin-right">R {parseInt(price).toFixed(2)}</p>
                                            <p className=" step2-item-total-ordered step2-margin-right">
                                                {(isNaN(orderedQty * price) || orderedQty > qty ? 0 : (orderedQty * price)).toFixed(2)}
                                            </p>
                                            <p className="step2-other-width">R {Math.max(0, priceTotalQty).toFixed(2)}</p>
                                        </>
                                    ) : (
                                        <div className='step2-item-column'>
                                            {selectedItemsForType.map((selectedItem, idx) => {
                                                const item = selectedItemInfos[selectedItem.clone_id];
                                                if (!item) return null;
                                                const difPrice = item.ordered_qty ? item.price * item.ordered_qty : 0;
                                                const oldPrice = item?.price * (item.user_item_qty || item.qty);
                                                const difTotalPrice = isNaN(oldPrice - difPrice) ? 0 : oldPrice - difPrice;

                                                return (
                                                    <div key={`${selectedItem.clone_id}`} className='step2-item'>
                                                        <button
                                                            className="step2-item-button"
                                                            id={`button-${fragment.id}-${itemType.originalIndex}-${idx}`}
                                                            onClick={() => handleButtonClick(itemType.originalIndex)}
                                                            disabled={loading}
                                                        >
                                                            {loading && selectedItemTypeIndex === itemType.originalIndex
                                                                ? "Loading..."
                                                                : item.title}
                                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none"
                                                                 xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7 13L18 13M18 13L13.875 17M18 13L13.875 9"
                                                                      stroke="#0053A0" strokeWidth="1.5"
                                                                      strokeLinecap="round" strokeLinejoin="round"/>
                                                                <circle cx="13" cy="13" r="12" stroke="#0053A0"
                                                                        strokeWidth="2"/>
                                                            </svg>
                                                        </button>
                                                        <div className="step2-tooltip-container">
                                                            <input
                                                                type="number"
                                                                className="step3-other-width-input step3-ordered-qty-input"
                                                                value={debouncedOrderedQty[selectedItem.clone_id] ?? item.ordered_qty}
                                                                onChange={(e) => handleQtyChange(selectedItem.clone_id, e.target.value)}
                                                                min="0"
                                                            />
                                                        </div>
                                                        <p className="step2-other-width step2-margin-right">{item.user_item_qty || item.qty} ({item.unit})</p>
                                                        <p className="step2-other-width step2-margin-right">R {parseInt(String(item.price)).toFixed(2)}</p>
                                                        <p className=" step2-item-total-ordered step2-margin-right">
                                                            {(isNaN(item.ordered_qty * item.price) || item.ordered_qty > item.qty ? 0 : (item.ordered_qty * item.price)).toFixed(2)}
                                                        </p>
                                                        <p className="step2-other-width">R {Math.max(0, difTotalPrice).toFixed(2)}</p>
                                                            <div className='step2-other-width-vendor'>
                                                                <img className='step3-vendor-img'     src={getVendorLogo(item.vendor as Vendor)}/>
                                                            </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </>
            )}
            <ItemSelectionModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                items={items}
                loading={loading}
                handleItemClick={(items) => handleItemClick(items, selectedItemTypeIndex)}
                selectedItems={selectedItems.filter(item => item.index === selectedItemTypeIndex)}
                onReload={handleReload}
                currentPage={currentPage}
                totalItems={totalItems}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                onSearch={handleSearch}
                totalSearchResults={totalItems}
                storeId={storeId}
            />
            <div className='step2-bottom-block'>
                <p>Total:</p>
                {totalPrice ? (
                    <span> R {totalPrice.toFixed(2)}</span>
                ) : (
                    <span> R 0</span>
                )}
                <button className='step2-bottom-block-btn-cart' onClick={handleSave}>Save</button>
            </div>
        </div>
    );
};

const mapDispatchToProps = {
    updateLocalTemplate,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypeListCalculation);
