import React, { useState } from 'react';
import './stylePaginationAssistance.css';

interface PaginationProps {
    projectsPerPage: number;
    totalProjects: number;
    paginate: (pageNumber: number) => void;
    currentPage: number;
}

const PaginationAssistance: React.FC<PaginationProps> = ({ projectsPerPage, totalProjects, paginate, currentPage }) => {
    const [inputPage, setInputPage] = useState('');
    const [inputError, setInputError] = useState(false);

    const totalPages = Math.ceil(totalProjects / projectsPerPage);
    const maxPagesToShow = 5;
    const pageNumbers = [];
    let startPage, endPage;

    if (totalPages <= maxPagesToShow) {
        startPage = 1;
        endPage = totalPages;
    } else {
        const maxPagesBeforeCurrentPage = Math.floor(maxPagesToShow / 2);
        const maxPagesAfterCurrentPage = Math.ceil(maxPagesToShow / 2) - 1;
        if (currentPage <= maxPagesBeforeCurrentPage) {
            startPage = 1;
            endPage = maxPagesToShow;
        } else if (currentPage + maxPagesAfterCurrentPage >= totalPages) {
            startPage = totalPages - maxPagesToShow + 1;
            endPage = totalPages;
        } else {
            startPage = currentPage - maxPagesBeforeCurrentPage;
            endPage = currentPage + maxPagesAfterCurrentPage;
        }
    }

    for (let i = startPage; i <= endPage; i++) {
        pageNumbers.push(i);
    }

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) {
            if (!value) {
                setInputPage('');
                setInputError(false);
            } else {
                setInputPage(value);
                setInputError(false);
            }
        }
    };

    const validatePageNumber = () => {
        const pageNumber = Number(inputPage);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setInputError(false);
        } else if (inputPage) {
            setInputError(true);
            setInputPage(totalPages.toString());
            setTimeout(() => {
                setInputError(false);
            }, 500);
        }
    };

    const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            validatePageNumber();
            const pageNumber = Number(inputPage);
            if (pageNumber > 0 && pageNumber <= totalPages) {
                paginate(pageNumber);
            }
        }
    };

    const handleGoToPageClick = () => {
        const pageNumber = Number(inputPage);
        if (pageNumber > 0 && pageNumber <= totalPages) {
            paginate(pageNumber);
            setInputPage('')
        }
    };

    const handleNextPageClick = () => {
        if (currentPage < totalPages) {
            paginate(currentPage + 1);
        }
    };

    const handleButtonClick = () => {
        if (inputPage) {
            handleGoToPageClick();
        } else {
            handleNextPageClick();
        }
    };

    const handleInputBlur = () => {
        validatePageNumber();
    };

    return (
        <nav>
            <ul className='pagination'>
                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                    <a onClick={() => currentPage > 1 && paginate(currentPage - 1)} className='page-link-arrow prev'
                       aria-disabled={currentPage === 1}>Prev</a>
                </li>
                {pageNumbers[0] > 1 && (
                    <>
                        <li className='page-item'>
                            <a onClick={() => paginate(1)} className='page-link'>1...</a>
                        </li>
                    </>
                )}
                {pageNumbers.map(number => (
                    <li key={number} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                        <a onClick={() => paginate(number)} className='page-link'>
                            {number}
                        </a>
                    </li>
                ))}
                {pageNumbers[pageNumbers.length - 1] < totalPages && (
                    <>
                        {pageNumbers[pageNumbers.length - 1] < totalPages - 1 && (
                            <li className='page-item'>
                                <a onClick={() => paginate(totalPages)} className='page-link'>...{totalPages}</a>
                            </li>
                        )}
                    </>
                )}
                {totalPages > 1 && (
                    <li className='page-item'>
                        <input
                            title={`${inputPage && Number(inputPage) > totalPages ? 'Enter page' : 'Press Enter'}`}
                            type={"text"}
                            placeholder={'Enter page'}
                            value={inputPage}
                            onChange={handleInputChange}
                            onKeyPress={handleInputKeyPress}
                            onBlur={handleInputBlur}
                            className={`page-input ${inputError ? 'error' : ''}`}
                        />
                    </li>
                )}
                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                    <a onClick={handleButtonClick}
                       className='page-link-arrow next'
                       aria-disabled={currentPage === totalPages}>
                        {inputPage ? `Go to ${inputPage}` : 'Next'}
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default PaginationAssistance;
