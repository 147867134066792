import {Link} from "react-router-dom";
import React from "react";

interface ReceiptPDFProps {
    id?: number;
}

export const ReceiptPDF: React.FC<ReceiptPDFProps> = ({ id }) => {
    return(
        <Link to={`${process.env.REACT_APP_BASE_URL_ADMIN}/generate-pdf/project/${id}?token=${process.env.REACT_APP_BASE_TOKEN}`}>
            <button className='add-template-btn receipt'>Receipt PDF</button>
        </Link>
    )
}