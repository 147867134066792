import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getOrderHistory } from '../../api/APIWraper';
import { OrderHistory } from "../../types/OrderHistory";

interface TemplateByIdState {
    orderHistory: OrderHistory[] | null;
    totalOrders: number;
    isFetching: boolean;
    error: string | null;
}

const initialState: TemplateByIdState = {
    orderHistory: null,
    totalOrders: 0,
    isFetching: false,
    error: null,
};

export const fetchOrderHistory = createAsyncThunk<{ orders: OrderHistory[], totalOrders: number }, { page: number; perPage: number }>(
    'orderHistory/fetchOrderHistory',
    async ({ page, perPage }) => {
        return await getOrderHistory(page, perPage);
    }
);
const OrderHistorySlice = createSlice({
    name: 'orderHistory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOrderHistory.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchOrderHistory.fulfilled, (state, action: PayloadAction<{ orders: OrderHistory[], totalOrders: number }>) => {
                state.isFetching = false;
                state.orderHistory = action.payload.orders;
                state.totalOrders = action.payload.totalOrders;
            })
            .addCase(fetchOrderHistory.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default OrderHistorySlice.reducer;
