import Footer from "../../components/ProjectAssistanceFooter/Footer";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchTemplateById } from "../../Slice/getByIdTemplate/ByIdTemplateSlice";
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import './style.css';
import ModalAddTemplate from "../../components/ProjectAssistanceTemplatesAdd/ModalAddTemplate";
import { fetchAllProjects } from "../../Slice/getProjectAssistance/ProjectAssistanceSlice";
import CreateProjectModal from "../../components/Modals/ModalCreateProject/ModalCreateProject";
import { Project } from "../../types/ProjectTypes";
import { addTemplate } from "../../Slice/LocalTemplate/LocalTemplateSlice";
import {Loader} from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {IconReview} from "../../IconComponents/IconComponents";

const TemplatePage: React.FC<PropsFromRedux> = ({ template, error, isFetching, fetchTemplateById, fetchAllProjects, addTemplate }) => {
    const { tempId } = useParams<{ tempId: string }>();
    const navigate = useNavigate();
    const isFirstRender = useRef(true);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalOpenProject, setIsModalOpenProject] = useState<boolean>(false);
    const [selectedTempId, setSelectedTempId] = useState<number>(0);
    const [selectedTempTitle, setSelectedTempTitle] = useState<string>('');

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsModalOpenProject(false);
    };

    const handleOpenModal = () => setIsModalOpenProject(true);

    const infoTemplateToModal = () => {
        setIsModalOpen(true);
        if (tempId != null) {
            setSelectedTempId(parseInt(tempId));
        }
        if (template) {
            setSelectedTempTitle(template.title);
        }
    };

    const handleProjectCreation = (project: Project) => {
        navigate('/assistance/create', { state: { projectId: project.id } });
        if (template) addTemplate(template);
    };

    useEffect(() => {
        if (isFirstRender.current && tempId) {
            fetchTemplateById(parseInt(tempId));
            fetchAllProjects();
            isFirstRender.current = false;
        }
    }, [fetchTemplateById, tempId, fetchAllProjects]);

    if(isFetching) {
        return (<Loader/>)
    }

    const categories = template?.categories?.[0]?.category ?? 'Other';

    const addTemplateButton = (containerClassName: string) => (
        <div className={`${containerClassName}`}>
            <button onClick={infoTemplateToModal} className='blog-media-section-btn-exis'>Add to existing project group</button>
            <button onClick={handleOpenModal} className='blog-media-section-btn-new'>Add to new project space</button>
        </div>
    );

    const renderMediaSections = () => {
        if (!template || !template?.galleries[0]?.medias[template?.galleries[0]?.medias.length - 1]?.url) {
            return (
                <div className='blog-section'>
                    <div className='blog-media-section'>
                        <div className='blog-media-img template-img-shadow' style={{ backgroundImage: "url('/img_projects/projectdefault.png')" }}></div>
                        <div className='blog-text-block block-odd'>
                            <h1 className='blog-text-block-title'>Description</h1>
                            <p className='blog-text-block-p' dangerouslySetInnerHTML={{ __html: template?.description || 'Description' }}></p>
                            {addTemplateButton("blog-media-section-button")}
                        </div>
                    </div>
                </div>
            );
        }

        return template?.galleries[0]?.medias.map((imageUrl, index) => {
            const isOdd = index % 2 !== 0;
            return (
                <div className='blog-section' key={index}>
                    <div className={`blog-media-section ${isOdd ? 'odd' : 'even'}`}>
                        {isOdd ? (
                            <>
                                <div className='blog-text-block block-even'>
                                    <h2 className='blog-text-block-title-right'>Description</h2>
                                    <p className='blog-text-block-p' dangerouslySetInnerHTML={{ __html: template?.description || 'Description' }}></p>
                                    {index === 0 && (addTemplateButton("blog-media-section-button-right"))}
                                </div>
                                {template?.galleries && template?.galleries[0]?.medias[index].url && (
                                    <img className='blog-media-img' src={template?.galleries[0]?.medias[index].url} alt="Media" />
                                )}
                            </>
                        ) : (
                            <>
                                {template?.galleries && template?.galleries[0]?.medias[index].url && (
                                    <img className='blog-media-img' src={template?.galleries[0]?.medias[index].url} alt="Media" />
                                )}
                                <div className='blog-text-block block-odd'>
                                    <h2 className='blog-text-block-title'>Description {index === 0 && (<span className='blog-text-block-reviews'>/{template?.reviews || 'No'} Reviews</span>)}
                                    </h2>
                                    <p className='blog-text-block-p' dangerouslySetInnerHTML={{ __html: template?.description || 'Description' }}></p>
                                    {index === 0 && (addTemplateButton("blog-media-section-button"))}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className='wrapper'>

            {isSmallScreen ? (
                <MobileNavigation
                    links={[
                        { to: '/', label: 'Cashbuild' },
                        { to: '', label: '>' },
                        { to: '/', label: 'Home' },
                        { to: '', label: '>' },
                        { to: '/assistance', label: 'Project assistance' },
                        { to: '', label: '>' },
                        { to: '/', label: 'Project page' }
                    ]}
                />
            ): (
                <div className='template-blog-placeholder'>
                    <p>Home/</p>
                    <p onClick={() => navigate('/assistance')} className='template-link-assistance'>Project group
                        assistance</p>
                    <p className='name-link'>/Project page</p>
                </div>
            )}

            <div className='blog-name'>
                <p className='blog-name-title'>{template?.title}</p>
                <span>/</span>
                <p className='blog-name-category'>Category: {categories}</p>
                <img className='blog-name-img' src='/img_projects/templates.png' alt='Template' />
            </div>
            {isSmallScreen ? (
                (template?.galleries && template?.galleries[0]?.medias.length > 0) ? (
                    template?.galleries[0]?.medias.map((imageUrl, index) => (
                        <div className='blog-section' key={index}>
                            <div className={`blog-media-section`}>
                                {template?.galleries[0]?.medias[index].url ? (
                                    <img className='blog-media-img' src={template?.galleries[0]?.medias[index].url} alt="Media" />
                                ) : (
                                    <img className='blog-media-img default-img-template' src="/img_projects/projectdefault.png" alt="Default Project" />
                                )}
                                {index === 0 && (
                                    <span className='blog-text-block-reviews'><IconReview /> ({template?.reviews})</span>
                                )}
                                <div className='blog-text-block'>
                                    <p className='blog-text-block-mobile' dangerouslySetInnerHTML={{ __html: template?.description || 'Description' }}></p>
                                    {index === 0 && (
                                        <div className='blog-media-section-button'>
                                            <button onClick={infoTemplateToModal} className='blog-media-section-btn-exis'>Add to existing project group</button>
                                            <button onClick={handleOpenModal} className='blog-media-section-btn-new'>Add to new project space</button>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className='blog-section'>
                        <div className='blog-media-section'>
                            <img className='blog-media-img default-img-template' src="/img_projects/projectdefault.png" alt="Default Project" />
                            <span className='blog-text-block-reviews'><IconReview /> ({template?.reviews})</span>
                            <div className='blog-text-block'>
                                <p className='blog-text-block-mobile' dangerouslySetInnerHTML={{ __html: template?.description || 'Description' }}></p>
                                <div className='blog-media-section-button'>
                                    <button onClick={infoTemplateToModal} className='blog-media-section-btn-exis'>Add to existing project group</button>
                                    <button onClick={handleOpenModal} className='blog-media-section-btn-new'>Add to new project space</button>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                renderMediaSections()
            )}

            <Footer/>
            <ModalAddTemplate
                isOpen={isModalOpen}
                tempId={selectedTempId}
                tempTitle={selectedTempTitle}
                onClose={handleCloseModal}
            />
            <CreateProjectModal
                isOpen={isModalOpenProject}
                onRequestClose={handleCloseModal}
                template={template}
                onProjectCreated={handleProjectCreation}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    template: state.templateById.template,
    error: state.templateById.error,
    isFetching: state.templateById.isFetching,
});

const mapDispatchToProps = {
    fetchTemplateById,
    fetchAllProjects,
    addTemplate
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TemplatePage);
