import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from "react-redux";
import { ItemType } from "../../../types/TemplateType";
import { RootState } from "../../../store";
import "../Step2Styles/projectUpdateItems.css";
import {
    setSelectedItemsTotal,
    setSelectedLaborTotal,
    setSelectedToolsTotal
} from "../../../Slice/SelectedItems/SelectedItemsSlice";
import {getVendorLogo, Vendor} from "../../../Enums/VendorEnum";

interface ItemTypeWithIndex extends ItemType {
    originalIndex: number;
    clone_id?: number;
}

interface ItemTypeListProps {
    fragment: {
        id: number;
        data: {
            item_types: ItemType[];
        };
    };
    fragmentIndex: number;
    templateIndex: number;
    templateId: number;
    viewMode: 'fragments' | 'types';
}

const ItemTypeList: React.FC<ItemTypeListProps & PropsFromRedux> = ({
                                                                        fragment,
                                                                        fragmentIndex,
                                                                        templateIndex,
                                                                        templateId,
                                                                        viewMode,
                                                                        setSelectedItemsTotal,
                                                                        setSelectedToolsTotal,
                                                                        setSelectedLaborTotal,
                                                                    }) => {
    const fragmentKey = `${fragment.id}-${fragmentIndex}-${templateIndex}`;
    const generateItemTypeKey = (itemType: ItemTypeWithIndex) => {
        return `${itemType.id}-${itemType.clone_id || itemType.originalIndex}-${fragmentIndex}`;
    };

    const generateUniqueKey = (itemType: ItemTypeWithIndex, itemIndex: number, item: any) => {
        return `${itemType.id}-${itemType.clone_id || itemType.originalIndex}-${itemIndex}`;
    };

    const [selectedItems, setSelectedItemsState] = useState<{ [key: string]: boolean }>({});
    const [selectedLaborItems, setSelectedLaborItemsState] = useState<{ [key: string]: boolean }>({});
    const [selectedToolItems, setSelectedToolItemsState] = useState<{ [key: string]: boolean }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);

    useEffect(() => {
        syncSelectionsWithLocalStorage();
    }, [fragment, fragmentIndex, templateIndex, viewMode]);

    useEffect(() => {
        getTotalPrice(selectedItems, selectedLaborItems, selectedToolItems);
    }, [selectedItems, selectedLaborItems, selectedToolItems, viewMode]);

    const syncSelectionsWithLocalStorage = () => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const initialSelectedItems = storedSelectedFragments[fragmentKey]?.items || {};
        const laborSelectedItems = storedSelectedFragments[fragmentKey]?.LaborItemTypes || {};
        const toolsSelectedItems = storedSelectedFragments[fragmentKey]?.ToolsItemTypes || {};

        const updatedItemsSelections: { [key: string]: boolean } = {};
        const updatedLaborSelections: { [key: string]: boolean } = {};
        const updatedToolSelections: { [key: string]: boolean } = {};

        fragment.data.item_types.forEach((itemType, i) => {
            const itemTypeKey = generateItemTypeKey({ ...itemType, originalIndex: i });

            if (initialSelectedItems[itemTypeKey]) {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({ ...itemType, originalIndex: i }, i, itemType);
                    updatedItemsSelections[key] = initialSelectedItems[itemTypeKey].items?.some((it: { id: number; }) => it.id === itemType.id) || false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({ ...itemType, originalIndex: i }, index, item);
                        updatedItemsSelections[key] = initialSelectedItems[itemTypeKey].items?.some((it: { id: number; }) => it.id === item.id) || false;
                    });
                }
            } else {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({ ...itemType, originalIndex: i }, i, itemType);
                    updatedItemsSelections[key] = false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({ ...itemType, originalIndex: i }, index, item);
                        updatedItemsSelections[key] = false;
                    });
                }
            }

            if (laborSelectedItems[itemTypeKey]) {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({ ...itemType, originalIndex: i }, i, itemType);
                    updatedLaborSelections[key] = laborSelectedItems[itemTypeKey].items?.some((it: { id: number; }) => it.id === itemType.id) || false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({ ...itemType, originalIndex: i }, index, item);
                        updatedLaborSelections[key] = laborSelectedItems[itemTypeKey].items?.some((it: { id: number; }) => it.id === item.id) || false;
                    });
                }
            } else {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({ ...itemType, originalIndex: i }, i, itemType);
                    updatedLaborSelections[key] = false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({ ...itemType, originalIndex: i }, index, item);
                        updatedLaborSelections[key] = false;
                    });
                }
            }

            if (toolsSelectedItems[itemTypeKey]) {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({ ...itemType, originalIndex: i }, i, itemType);
                    updatedToolSelections[key] = toolsSelectedItems[itemTypeKey].items?.some((it: { id: number; }) => it.id === itemType.id) || false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({ ...itemType, originalIndex: i }, index, item);
                        updatedToolSelections[key] = toolsSelectedItems[itemTypeKey].items?.some((it: { id: number; }) => it.id === item.id) || false;
                    });
                }
            } else {
                if (!itemType.items || itemType.items.length === 0) {
                    const key = generateUniqueKey({ ...itemType, originalIndex: i }, i, itemType);
                    updatedToolSelections[key] = false;
                } else {
                    itemType.items?.forEach((item, index) => {
                        const key = generateUniqueKey({ ...itemType, originalIndex: i }, index, item);
                        updatedToolSelections[key] = false;
                    });
                }
            }
        });

        setSelectedItemsState(updatedItemsSelections);
        setSelectedLaborItemsState(updatedLaborSelections);
        setSelectedToolItemsState(updatedToolSelections);
        getTotalPrice(updatedItemsSelections, updatedLaborSelections, updatedToolSelections);

    };

    const getTotalPrice = (items: { [key: string]: boolean }, laborItems: { [key: string]: boolean }, toolItems: { [key: string]: boolean }) => {
        let totalPrice = 0;

        fragment.data.item_types.forEach((itemType, index) => {
            if (itemType.labor) {
                if (viewMode === 'types') return;
            }
            if (itemType.tool) {
                if (viewMode === 'types') return;
            }
            if (!itemType.items || itemType.items.length === 0) {
                const key = generateUniqueKey({ ...itemType, originalIndex: index }, index, itemType);
                if (items[key]) {
                    totalPrice += 0;
                }
            } else {
                itemType.items?.forEach((item, i) => {
                    const key = generateUniqueKey({ ...itemType, originalIndex: index }, i, item);
                    if (items[key] || laborItems[key] || toolItems[key]) {
                        const priceTotalQty = parseFloat(String(item.price)) * item.qty;
                        const difTotalQty = item.ordered_qty ? Math.max(0, priceTotalQty - (item.ordered_qty * parseFloat(String(item.price)))) : priceTotalQty;
                        totalPrice += difTotalQty;
                    }
                });
            }
        });
        setTotalPrice(totalPrice);
    };

    const handleCheckboxChange = (index: number, itemIndex: number, itemType: ItemTypeWithIndex) => {
        const item = itemType.items ? itemType.items[itemIndex] : itemType;
        const key = generateUniqueKey(itemType, itemIndex, item);

        const isSelected = !selectedItems[key];
        const isLaborSelected = !selectedLaborItems[key];
        const isToolSelected = !selectedToolItems[key];

        const updatedSelections = { ...selectedItems, [key]: isSelected };
        const updatedLaborSelections = { ...selectedLaborItems, [key]: isLaborSelected };
        const updatedToolSelections = { ...selectedToolItems, [key]: isToolSelected };

        if (itemType.labor) {
            setSelectedLaborItemsState(updatedLaborSelections);
            setSelectedLaborTotal(Date.now());
        } else if (itemType.tool) {
            setSelectedToolItemsState(updatedToolSelections);
            setSelectedToolsTotal(Date.now());
        } else {
            setSelectedItemsState(updatedSelections);
            setSelectedItemsTotal(Date.now());
        }

        updateLocalStorage(itemType, item, isSelected, isLaborSelected, isToolSelected);
        getTotalPrice(updatedSelections, updatedLaborSelections, updatedToolSelections);
    };

    const updateLocalStorage = (itemType: ItemTypeWithIndex, item: any, isSelected: boolean, isLaborSelected: boolean, isToolSelected: boolean) => {
        const storedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');

        if (!storedFragments[fragmentKey]) {
            storedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
        }

        const itemTypeKey = generateItemTypeKey(itemType);

        const fragmentItems = storedFragments[fragmentKey].items || {};
        const laborItemTypes = storedFragments[fragmentKey].LaborItemTypes || {};
        const toolsItemTypes = storedFragments[fragmentKey].ToolsItemTypes || {};

        const addItemToLocalStorage = (storage: any, item: any) => {
            if (!storage[itemTypeKey]) {
                storage[itemTypeKey] = {
                    ...itemType,
                    items: [{
                        ...item,
                        payload: generatePayload(itemType, [item.id])
                    }],
                    payload: generatePayload(itemType, itemType.items ? [item.id] : [])
                };
            } else if (itemType.items) {
                if (!storage[itemTypeKey].items) {
                    storage[itemTypeKey].items = [];
                }
                storage[itemTypeKey].items.push({
                    ...item,
                    payload: generatePayload(itemType, [item.id])
                });
                if (!storage[itemTypeKey].payload) {
                    storage[itemTypeKey].payload = { item_ids: [] };
                }
                if (!storage[itemTypeKey].payload.item_ids) {
                    storage[itemTypeKey].payload.item_ids = [];
                }
                storage[itemTypeKey].payload.item_ids.push(item.id);
            }
        };

        const removeItemFromLocalStorage = (storage: any, item: any) => {
            if (storage[itemTypeKey] && itemType.items) {
                if (storage[itemTypeKey].items) {
                    storage[itemTypeKey].items = storage[itemTypeKey].items.filter((i: any) => i.id !== item.id) || [];
                }
                if (storage[itemTypeKey].payload && storage[itemTypeKey].payload.item_ids) {
                    storage[itemTypeKey].payload.item_ids = storage[itemTypeKey].payload.item_ids.filter((id: number) => id !== item.id);
                }

                if (storage[itemTypeKey].items.length === 0) {
                    delete storage[itemTypeKey];
                }
            }
        };

        switch (true) {
            case itemType.labor:
                if (isLaborSelected) {
                    addItemToLocalStorage(laborItemTypes, item);
                } else {
                    removeItemFromLocalStorage(laborItemTypes, item);
                }
                break;
            case itemType.tool:
                if (isToolSelected) {
                    addItemToLocalStorage(toolsItemTypes, item);
                } else {
                    removeItemFromLocalStorage(toolsItemTypes, item);
                }
                break;
            default:
                if (isSelected) {
                    addItemToLocalStorage(fragmentItems, item);
                } else {
                    removeItemFromLocalStorage(fragmentItems, item);
                }
                break;
        }

        storedFragments[fragmentKey] = {
            items: fragmentItems,
            LaborItemTypes: laborItemTypes,
            ToolsItemTypes: toolsItemTypes
        };

        localStorage.setItem('selectedFragments', JSON.stringify(storedFragments));
        syncSelectionsWithLocalStorage();
    };

    const generatePayload = (itemType: ItemTypeWithIndex, itemIds: number[]): any => ({
        template_index: templateIndex,
        fragment_index: fragmentIndex,
        item_type_index: itemType.originalIndex,
        template_id: templateId,
        fragment_id: fragment.id,
        item_type_id: itemType.id,
        item_ids: itemIds,
    });

    const filteredItemTypes = fragment.data.item_types
        .map((itemType, index) => ({ ...itemType, originalIndex: index, clone_id: itemType.clone_id || index } as ItemTypeWithIndex))
        .filter(itemType => !itemType.labor && !itemType.tool);

    const laborItems = fragment.data.item_types
        .map((itemType, index) => ({ ...itemType, originalIndex: index, clone_id: itemType.clone_id || index } as ItemTypeWithIndex))
        .filter(itemType => itemType.labor);

    const toolItems = fragment.data.item_types
        .map((itemType, index) => ({ ...itemType, originalIndex: index, clone_id: itemType.clone_id || index } as ItemTypeWithIndex))
        .filter(itemType => itemType.tool);

    if (filteredItemTypes.length === 0 && laborItems.length === 0 && toolItems.length === 0) {
        return <div className="step2-div-no-materials">No product</div>;
    }

    return (
        <div>
            {filteredItemTypes.length > 0 && <h3>Products name</h3>}
            {filteredItemTypes.map((itemType: ItemTypeWithIndex, index: number) => (
                <React.Fragment key={`${itemType.originalIndex}`}>
                    {itemType.items && itemType.items.length > 0 && (
                        <React.Fragment>
                            <p style={{ color: "#828282", marginBottom: "5px" }}>{itemType.title}</p>
                        </React.Fragment>
                    )}
                    {(!itemType.items || itemType.items.length === 0) && (
                        <div key={itemType.originalIndex} className='step2-item-type'>
                            <div className='step2-material-width'>
                                <label style={{color: "#828282"}}>{itemType.title}</label>
                            </div>
                            <p className="step2-other-width">0</p>
                            <p className="step2-other-width">{itemType.qty}</p>
                            <p className="step2-other-width">R 0.00</p>
                            <p className="step2-other-width">R 0.00</p>
                            <p className="step2-other-width">R 0.00</p>
                        </div>
                    )}
                    {itemType.items && itemType.items.map((item, itemIndex) => {
                        const key = generateUniqueKey(itemType, itemIndex, item);
                        const price = parseFloat(String(item.price)) || 0;
                        const orderedQty = item.ordered_qty || 0;
                        const difPrice = orderedQty * price ;
                        const totalDifPrice = Math.max(0, (item.qty * price) - (orderedQty * price));
                        return (
                            <div key={`${itemType.originalIndex}-${itemIndex}`} className='step2-item-type'>
                                <div className='step2-material-width'>
                                    <div className="step2-item-checkbox-block">
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                            checked={!!selectedItems[key] || !!selectedLaborItems[key] || !!selectedToolItems[key]}
                                            onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                        />
                                        <label
                                            htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                            {item.title}
                                        </label>
                                    </div>
                                </div>
                                <p className="step2-other-width">{orderedQty} ({item.unit})</p>
                                <p className="step2-other-width">{item.qty} ({item.unit})</p>
                                <p className="step2-other-width">R {price.toFixed(2)}</p>
                                <p className="step2-other-width">R {isNaN(difPrice) ? 0 : difPrice.toFixed(2)}</p>
                                <p className="step2-other-width">R {isNaN(totalDifPrice) ? 0 : totalDifPrice.toFixed(2)}</p>
                                    <div className='step2-other-width-vendor'>
                                        <img className='step3-vendor-img'  src={getVendorLogo(item.vendor as Vendor)}/>
                                    </div>
                            </div>
                        );
                    })}
                </React.Fragment>
            ))}
            {viewMode === 'fragments' && (
                <>
                    {laborItems.length > 0 && (
                        <>
                            <h3>Labor Items</h3>
                            {laborItems.map((itemType: ItemTypeWithIndex, index: number) => (
                                <React.Fragment key={`${itemType.originalIndex}`}>
                                    {itemType.items && itemType.items.length > 0 && (
                                        <React.Fragment>
                                            <p style={{color: "#828282", marginBottom: "5px"}}>{itemType.title}</p>
                                        </React.Fragment>
                                    )}
                                    {(!itemType.items || itemType.items.length === 0) && (
                                        <div className='step2-item-type'>
                                            <div className='step2-material-width'>
                                                <label className="item-type-list-label">{itemType.title}</label>
                                            </div>
                                            <p className="step2-other-width">0</p>
                                            <p className="step2-other-width">{itemType.qty}</p>
                                            <p className="step2-other-width">R 0.00</p>
                                            <p className="step2-other-width">R 0.00</p>
                                            <p className="step2-other-width">R 0.00</p>
                                        </div>
                                    )}
                                    {itemType.items && itemType.items.map((item, itemIndex) => {
                                        const key = generateUniqueKey(itemType, itemIndex, item);
                                        const price = parseFloat(String(item.price)) || 0;
                                        const orderedQty = item.ordered_qty || 0;
                                        const difPrice = item.ordered_qty * (parseFloat(String(item.price)) || 0);
                                        const totalDifPrice = Math.max(0, (item.qty * price) - (item.ordered_qty * price));
                                        return (
                                            <div key={`${itemType.originalIndex}-${itemIndex}`}
                                                 className='step2-item-type step2-item-type-labor'>
                                                <div className='step2-material-width'>
                                                    <div className="step2-item-checkbox-block">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                            checked={!!selectedLaborItems[key]}
                                                            onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                        />
                                                        <label
                                                            htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                            {item.title}
                                                        </label>
                                                    </div>
                                                </div>
                                                <p className="step2-other-width">{orderedQty} ({item.unit})</p>
                                                <p className="step2-other-width">{item.qty} ({item.unit})</p>
                                                <p className="step2-other-width">R {price.toFixed(2)}</p>
                                                <p className="step2-other-width">R {isNaN(difPrice) ? 0 : difPrice.toFixed(2)}</p>
                                                <p className="step2-other-width">R {isNaN(totalDifPrice) ? 0 : totalDifPrice.toFixed(2)}</p>
                                                    <div className='step2-other-width-vendor'>
                                                        <img className='step3-vendor-img'  src={getVendorLogo(item.vendor as Vendor)}/>
                                                    </div>
                                            </div>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                        </>
                    )}
                    {toolItems.length > 0 && (
                        <>
                            <h3>Tool Items</h3>
                            {toolItems.map((itemType: ItemTypeWithIndex, index: number) => (
                                <React.Fragment key={`${itemType.originalIndex}`}>
                                    {itemType.items && itemType.items.length > 0 && (
                                        <React.Fragment>
                                            <p style={{color: "#828282", marginBottom: "5px"}}>{itemType.title}</p>
                                        </React.Fragment>
                                    )}
                                    {(!itemType.items || itemType.items.length === 0) && (
                                        <div className='step2-item-type'>
                                            <div className='step2-material-width'>
                                                <label className="item-type-list-label">{itemType.title}</label>
                                            </div>
                                            <p className="step2-other-width">0</p>
                                            <p className="step2-other-width">{itemType.qty}</p>
                                            <p className="step2-other-width">R 0.00</p>
                                            <p className="step2-other-width">R 0.00</p>
                                            <p className="step2-other-width">R 0.00</p>
                                        </div>
                                    )}
                                    {itemType.items && itemType.items.map((item, itemIndex) => {
                                        const key = generateUniqueKey(itemType, itemIndex, item);
                                        const price = parseFloat(String(item.price)) || 0;
                                        const orderedQty = item.ordered_qty || 0;
                                        const difPrice = item.ordered_qty * (parseFloat(String(item.price)) || 0);
                                        const totalDifPrice = Math.max(0, (item.qty * price) - (item.ordered_qty * price));
                                        return (
                                            <div key={`${itemType.originalIndex}-${itemIndex}`}
                                                 className='step2-item-type step2-item-type-tool'>
                                                <div className='step2-material-width'>
                                                    <div className="step2-item-checkbox-block">
                                                        <input
                                                            type="checkbox"
                                                            id={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}
                                                            checked={!!selectedToolItems[key]}
                                                            onChange={() => handleCheckboxChange(index, itemIndex, itemType)}
                                                        />
                                                        <label
                                                            htmlFor={`checkbox-${fragment.id}-${fragmentIndex}-${itemType.originalIndex}-${itemIndex}`}>
                                                            {item.title}
                                                        </label>
                                                    </div>
                                                </div>
                                                <p className="step2-other-width">{orderedQty} ({item.unit})</p>
                                                <p className="step2-other-width">{item.qty} ({item.unit})</p>
                                                <p className="step2-other-width">R {price.toFixed(2)}</p>
                                                <p className="step2-other-width">R {isNaN(difPrice) ? 0 : difPrice.toFixed(2)}</p>
                                                <p className="step2-other-width">R {isNaN(totalDifPrice) ? 0 : totalDifPrice.toFixed(2)}</p>
                                                    <div className='step2-other-width-vendor'>
                                                        <img className='step3-vendor-img' src={getVendorLogo(item.vendor as Vendor)}/>
                                                    </div>
                                            </div>
                                        );
                                    })}
                                </React.Fragment>
                            ))}
                        </>
                    )}

                </>
            )}
            <div className='step2-bottom-block'>
                <p>Total:</p>
                {totalPrice ? (
                    <span> R {totalPrice.toFixed(2)}</span>
                ) : (
                    <span> R 0</span>
                )}
                <button className='step2-bottom-block-btn-cart'>Add products to cart</button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedLabor: state.selectedItems.selectedLabor,
    selectedTools: state.selectedItems.selectedTools,
});

const mapDispatchToProps = {
    setSelectedItemsTotal,
    setSelectedToolsTotal,
    setSelectedLaborTotal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypeList);
