const BASE_URL = process.env.REACT_APP_BASE_URL_CUSTOMER;

if (!BASE_URL) {
    throw new Error('REACT_APP_BASE_URL_CUSTOMER is not defined in the environment variables.');
}
const VALID_BASE_URL: string = BASE_URL;

function isValidISO88591String(str: string): boolean {
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 255) {
            return false;
        }
    }
    return true;
}

function ensureAppUrl(url: string = ''): string {
    if (!url) {
        throw new Error("URL is required");
    }
    return url.startsWith(VALID_BASE_URL) ? url : `${VALID_BASE_URL}${url}`;
}

const abortControllers: { [key: string]: { controller: AbortController, config: RequestInit } } = {};

interface HttpRequestConfig extends RequestInit {}

function getRequestKey(url: string, config: RequestInit, shouldAbortWithDifferentAttribute: boolean): string {
    const baseUrl = url.split('?')[0];
    if (shouldAbortWithDifferentAttribute) {
        return `${baseUrl}_${JSON.stringify(config)}`;
    }
    return baseUrl;
}

async function HttpFormData(
    url: string,
    config: HttpRequestConfig,
    IsAllowMultiRequest: boolean = true,
    shouldAbortWithDifferentAttribute: boolean = true,
    abortUrls: string[] = []
): Promise<any> {
    const authToken = localStorage.getItem("Bearer");

    const headers: HeadersInit = {};

    if (authToken) {
        if (!isValidISO88591String(authToken)) {
            throw new Error("Bearer token contains non ISO-8859-1 code point");
        }

        headers["Authorization"] = `Bearer ${authToken}`;
    }

    const requestKey = getRequestKey(url, config, shouldAbortWithDifferentAttribute);

    if (!IsAllowMultiRequest && abortControllers[requestKey]) {
        abortControllers[requestKey].controller.abort();
    }

    if (!IsAllowMultiRequest) {
        abortUrls.forEach((abortUrl) => {
            const abortRequestKey = getRequestKey(abortUrl, config, shouldAbortWithDifferentAttribute);
            if (abortControllers[abortRequestKey]) {
                abortControllers[abortRequestKey].controller.abort();
            }
        });
    }

    const controller = new AbortController();
    abortControllers[requestKey] = { controller, config };

    config = {
        ...config,
        headers: {
            ...headers,
            ...config.headers
        },
        signal: controller.signal,
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer"
    };

    const request = new Request(ensureAppUrl(url), config);

    try {
        const response = await fetch(request);
        if (response.status === 401 && authToken) {
        }
        const data = await response.json();
        return { data: data, status: response.status };
    } catch (error) {
        if (error instanceof Error) {
            if (error.name !== 'AbortError') {
                throw error;
            }
        } else {
            console.error('Unexpected error', error);
        }
    }
}

export async function PostFormData(
    url: string,
    body: FormData,
    config?: HttpRequestConfig,
    IsAllowMultiRequest: boolean = true,
    shouldAbortWithDifferentAttribute: boolean = true,
    abortUrls: string[] = []
): Promise<any> {
    const init: HttpRequestConfig = {
        method: "POST",
        body: body,
        ...config
    };

    return await HttpFormData(url, init, IsAllowMultiRequest, shouldAbortWithDifferentAttribute, abortUrls);
}
