import React from 'react';
import './style.css';
import TopHeader from "../../components/TopHeader/TopHeader";
import { Outlet } from "react-router-dom";
import ToastNotification from "../../components/Toast/AssisCreateToastNotification";
import Footer from "../../components/ProjectAssistanceFooter/Footer";

function Home() {
    return (
        <div className='home'>
            <TopHeader/>
            <div className='content-wrapper'>
                <Outlet/>
                <ToastNotification/>
            </div>
            <Footer/>
        </div>
    );
}

export default Home;
