// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.switcher {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #b8b8b8;
    border: 1px solid #ccc;
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;
    font-weight: 600;
    font-size: 18px;
    margin:0 0 20px 35%;
}

.switcher-2 {
    width: 600px;
    height: 35px;
}

.switcher-3 {
    flex-direction: column;
    width: 100px;
    height: 150px;
}

.switcher-option {
    flex: 1 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    transition: background-color 0.3s;
}

.switcher-2 .switcher-option {
    width: 50%;
    height: 100%;
    text-align: center;
}

.switcher-3 .switcher-option {
    width: 100%;
    height: 33.33%;
    text-align: center;
}

.switcher-option.active-switcher {
    background-color: #E30613;
    color: #fff;
    transition: 0.3s;
}

.switcher-option:hover {
opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/components/Switcher/SwitcherStep2.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,yBAAyB;IACzB,sBAAsB;IACtB,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,iCAAiC;AACrC;;AAEA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,WAAW;IACX,gBAAgB;AACpB;;AAEA;AACA,YAAY;AACZ","sourcesContent":["\n.switcher {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background-color: #b8b8b8;\n    border: 1px solid #ccc;\n    border-radius: 25px;\n    overflow: hidden;\n    cursor: pointer;\n    font-weight: 600;\n    font-size: 18px;\n    margin:0 0 20px 35%;\n}\n\n.switcher-2 {\n    width: 600px;\n    height: 35px;\n}\n\n.switcher-3 {\n    flex-direction: column;\n    width: 100px;\n    height: 150px;\n}\n\n.switcher-option {\n    flex: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 10px;\n    transition: background-color 0.3s;\n}\n\n.switcher-2 .switcher-option {\n    width: 50%;\n    height: 100%;\n    text-align: center;\n}\n\n.switcher-3 .switcher-option {\n    width: 100%;\n    height: 33.33%;\n    text-align: center;\n}\n\n.switcher-option.active-switcher {\n    background-color: #E30613;\n    color: #fff;\n    transition: 0.3s;\n}\n\n.switcher-option:hover {\nopacity: 0.6;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
