import React, { useState, useEffect } from 'react';
import { TItem } from "../../types/TemplateType";
import "./Step2Styles/itemModal.css";
import Modal from "../../components/Modals/Modal/Modal";
import 'react-toastify/dist/ReactToastify.css';
import {
    toastItemCheckboxFalse,
    toastItemError,
    toastItemTrue
} from "../../components/Toast/AssisCreateToastNotification";
import { IconArrowBottom, IconArrowTop, IconReload, IconSearch } from "../../IconComponents/IconComponents";
import { Loader } from "../../components/Loader/Loader";
import PaginationInModal from "../../components/PaginationAssistance/paginationInModal";
import { getVendorLogo, Vendor } from "../../Enums/VendorEnum";

interface ItemSelectionModalProps {
    isOpen: boolean,
    onClose: () => void,
    items: TItem[],
    loading: boolean,
    handleItemClick: (items: TItem[]) => void,
    selectedItems: { itemId: number; user_item_qty: number }[],
    onReload?: () => void,
    currentPage: number,
    totalItems: number,
    itemsPerPage: number,
    onPageChange: (page: number) => void,
    onSearch?: (query: string, page: number) => void;
    totalSearchResults?: number,
    storeId: number
}

const ItemSelectionModal: React.FC<ItemSelectionModalProps> = ({
                                                                   isOpen,
                                                                   onClose,
                                                                   items,
                                                                   loading,
                                                                   handleItemClick,
                                                                   selectedItems,
                                                                   onReload,
                                                                   currentPage,
                                                                   totalItems,
                                                                   itemsPerPage,
                                                                   onPageChange,
                                                                   onSearch,
                                                                   totalSearchResults,
                                                                   storeId
                                                               }) => {
    const [selectedItemsState, setSelectedItemsState] = useState<{ [id: number]: TItem }>({});
    const [userItemQuantities, setUserItemQuantities] = useState<{ [id: number]: number }>({});
    const [isError, setIsError] = useState(false);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [filteredItems, setFilteredItems] = useState<TItem[]>(items);
    const [deletedItems, setDeletedItems] = useState<{ [id: number]: boolean }>({});
    const [searchTerm, setSearchTerm] = useState('');
    const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout | null>(null);
    useEffect(() => {
        if (onSearch) {
            if (searchTimer) {
                clearTimeout(searchTimer);
            }
            if (searchTerm) {
                const timer = setTimeout(() => {
                    onSearch(searchTerm, currentPage);
                }, 2000);
                setSearchTimer(timer);
            } else {
                onSearch('', currentPage);
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.body.classList.add('modal-open');
            window.addEventListener('keydown', handleKeyDown);
        } else {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);
            resetState();
        }

        if (isOpen && items.length > 0) {
            initializeState();
        }

        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, items]);

    const initializeState = () => {
        const initialQuantities = selectedItems.reduce((acc: { [x: number]: number }, item: { itemId: number; user_item_qty: number; }) => {
            acc[item.itemId] = item.user_item_qty;
            return acc;
        }, {});

        setUserItemQuantities(prevQuantities => ({ ...prevQuantities, ...initialQuantities }));

        const initialSelectedItems = items.reduce((acc: { [id: number]: TItem }, item) => {
            if (selectedItems.some(selected => selected.itemId === item.id) && !deletedItems[item.id]) {
                acc[item.id] = item;
            }
            return acc;
        }, {});

        setSelectedItemsState(prevSelectedItems => ({ ...prevSelectedItems, ...initialSelectedItems }));
        setFilteredItems(items);
    };

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onClose();
        }
    };

    const resetState = () => {
        setSelectedItemsState({});
        setUserItemQuantities({});
        setIsError(false);
        setSearchTerm('');
        setDeletedItems({});
    };

    const handleAddItemClick = (item: TItem) => {
        setSelectedItemsState(prevState => ({ ...prevState, [item.id]: item }));
        setDeletedItems(prevDeletedItems => {
            const newDeletedItems = { ...prevDeletedItems };
            delete newDeletedItems[item.id];
            return newDeletedItems;
        });
        toastItemTrue(`${item.title} added`);
    };

    const handleRemoveItemClick = (item: TItem) => {
        setDeletedItems(prevDeletedItems => ({
            ...prevDeletedItems,
            [item.id]: true
        }));

        setSelectedItemsState(prevState => {
            const newSelectedItems = { ...prevState };
            delete newSelectedItems[item.id];
            return newSelectedItems;
        });

        setUserItemQuantities(prevQuantities => {
            const newQuantities = { ...prevQuantities };
            delete newQuantities[item.id];
            return newQuantities;
        });

        toastItemCheckboxFalse(`${item.title} removed`);
    };

    const handleQtyChange = (id: number, value: number) => {
        const totalQty = calculateTotalQty();
        const qty_item_type = items[0]?.qty_item_type || 0;
        const currentQty = userItemQuantities[id] || 0;

        const availableQty = qty_item_type - (totalQty - currentQty);

        if (value <= availableQty) {
            setUserItemQuantities(prevState => ({ ...prevState, [id]: value }));
        } else {
            toastItemError(`Total quantity cannot exceed ${qty_item_type}`);
        }
    };

    const calculateTotalQty = () => {
        return Object.keys(userItemQuantities).reduce((total, itemId) => {
            const id = Number(itemId);
            if (!deletedItems[id]) {
                return total + (userItemQuantities[id] || 0);
            }
            return total;
        }, 0);
    };

    const handleSave = () => {
        const updatedItems = Object.values(selectedItemsState).map(item => ({
            ...item,
            user_item_qty: userItemQuantities[item.id] || 0
        }));

        const totalQty = calculateTotalQty();
        const qty_item_type = items[0]?.qty_item_type || 0;

        if (updatedItems.length === 0) {
            handleItemClick(updatedItems);
            onClose();
            return;
        }

        if (totalQty !== qty_item_type) {
            setIsError(true);
            toastItemError(`The total quantity must be equal to ${qty_item_type}. You entered: ${totalQty}`);
            setTimeout(() => setIsError(false), 1000);
            return;
        }

        handleItemClick(updatedItems);
        onClose();
    };


    const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const highlightMatch = (text: string, match: string) => {
        const regex = new RegExp(`(${match})`, 'gi');
        return text.replace(regex, (matched) => `<span class="modal-highlight">${matched}</span>`);
    };

    const sortedItems = filteredItems.sort((a, b) => {
        return sortDirection === 'asc' ? a.price - b.price : b.price - a.price;
    });

    const totalQty = calculateTotalQty();
    const qty_item_type = items[0]?.qty_item_type || 0;
    const totalPrice = Object.values(selectedItemsState).reduce((total, item) => {
        const itemPrice = parseFloat(String(item.price));
        const itemQty = userItemQuantities[item.id] || 0;

        if (!isNaN(itemPrice) && !isNaN(itemQty) && !deletedItems[item.id]) {
            return total + itemPrice * itemQty;
        }
        return total;
    }, 0).toFixed(2);

    const getStoreQuantity = (item: TItem) => {
        if (!item.merchant_info || !item.merchant_info.stores || !item.merchant_info.stores[storeId]) {
            return null;
        }
        return item.merchant_info.stores[storeId]?.quantity;
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose} onClick={(e) => e.currentTarget === e.target && onClose()}>
            <section className="item-selection-modal-section">
                <div className='item-selection-modal-header'>
                    <div className='item-selection-modal-header-block-text'>
                        <h2 className="item-selection-modal-title">Please, select your product</h2>
                        <p className="item-selection-modal-info-select"><strong>{filteredItems.length}</strong> products available</p>
                    </div>
                    <div>
                        <p className='item-selection-modal-header-price-p'>Total products selected: <span
                            className='item-selection-modal-header-price'>R {totalPrice}</span></p>
                        <div className={`item-selection-modal-header-calc-qty ${isError ? 'error' : ''}`}>
                            <p>Calculated QTY: </p>
                            <p><strong>{totalQty}</strong></p>
                            <p> <strong>{qty_item_type}</strong></p>
                        </div>
                    </div>
                </div>
                <div className='search-modal'>
                    <div className='search-icon-modal'>
                        <IconSearch />
                    </div>
                    <input
                        className='search-input-modal'
                        placeholder='Search'
                        type='search'
                        value={searchTerm}
                        onChange={handleSearchInput}
                    />
                </div>
                <div className='item-selection-modal-list-icons-sort'>
                    <div className='item-selection-modal-button-sort'>
                        <div className='item-selection-modal-list-button' onClick={onReload}>
                            <IconReload />
                        </div>
                        <div className='item-selection-modal-list-button' onClick={() => setSortDirection('desc')}>
                            <IconArrowTop />
                        </div>
                        <div className='item-selection-modal-list-button' onClick={() => setSortDirection('asc')}>
                            <IconArrowBottom />
                        </div>
                    </div>
                </div>
                {loading ? (
                    <Loader />
                ) : (
                    <ul className="item-selection-modal-list">
                        {sortedItems.map((item: TItem) => {
                            const isSelected = !!selectedItemsState[item.id] && !deletedItems[item.id];
                            const initialQty = userItemQuantities[item.id] || 0;
                            const quantity = getStoreQuantity(item);

                            return (
                                <li key={item.id} className="item-selection-modal-item">
                                    <section className="item-selection-modal-item-content">
                                        <div className="item-selection-modal-item-image">
                                            {item.galleries.length > 0 && item.galleries[0].medias[item.galleries[0].medias.length - 1]?.url ? (
                                                <img
                                                    className="item-selection-modal-image"
                                                    src={item.galleries[0].medias[item.galleries[0].medias.length - 1].url}
                                                    alt="Item"
                                                />
                                            ) : (
                                                <svg className="item-selection-modal-image" width="60" height="60"
                                                     viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <rect x="1" y="1" width="58" height="58" rx="3" stroke="#5F6368"
                                                          strokeWidth="2" />
                                                    <path d="M2 1.6001L58.4 58.4001" stroke="#5F6368" strokeWidth="2" />
                                                    <path d="M58 1.6001L1.6 58.4001" stroke="#5F6368" strokeWidth="2" />
                                                </svg>
                                            )}
                                            <section className="item-selection-modal-item-info">
                                                <p
                                                    className="item-selection-modal-item-title"
                                                    dangerouslySetInnerHTML={{
                                                        __html: highlightMatch(item.title, searchTerm)
                                                    }}
                                                />
                                                <p className="item-selection-modal-item-description">
                                                    {item.merchant_info?.description_short ||
                                                        item.merchant_info?.description ||
                                                        item.merchant_info?.meta_description}
                                                </p>
                                                <p className="item-selection-modal-item-price">R {item.price ? Number(item.price).toFixed(2) : '0.00'}</p>
                                            </section>
                                        </div>
                                        <div className='item-selection-modal-action-block'>
                                            <div className='item-selection-modal-action-block-img'>
                                                <img
                                                    className='step3-vendor-img'
                                                    src={getVendorLogo(item.vendor as Vendor)} />
                                            </div>
                                            {quantity === 0 ? (
                                                <>
                                                    <div className='item-selection-modal-input'>
                                                        <button
                                                            disabled={!isSelected}
                                                            className="qty-button minus"
                                                            onClick={() => handleQtyChange(item.id, Math.max(0, initialQty - 1))}>-
                                                        </button>
                                                        <input
                                                            type="number"
                                                            value={''}
                                                            className="item-selection-modal-input-field"
                                                            disabled={true}
                                                        />
                                                        <button
                                                            disabled={!isSelected}
                                                            className="qty-button plus"
                                                            onClick={() => handleQtyChange(item.id, initialQty + 1)}>+
                                                        </button>
                                                    </div>
                                                <button className="item-selection-modal-button out-of-stock">
                                                    Out of stock
                                                </button>
                                                </>
                                            ) : (
                                                <div className='item-selection-modal-input'>
                                                    <button
                                                        disabled={!isSelected}
                                                        className="qty-button minus"
                                                        onClick={() => handleQtyChange(item.id, Math.max(0, initialQty - 1))}>-
                                                    </button>
                                                    <input
                                                        type="number"
                                                        value={isSelected ? initialQty : ''}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            handleQtyChange(item.id, Math.max(0, Number(newValue)));
                                                        }}
                                                        className="item-selection-modal-input-field"
                                                        disabled={!isSelected}
                                                        min={0}
                                                    />
                                                    <button
                                                        disabled={!isSelected}
                                                        className="qty-button plus"
                                                        onClick={() => handleQtyChange(item.id, initialQty + 1)}>+
                                                    </button>
                                                </div>
                                            )}
                                            {isSelected && quantity !== 0 ? (
                                                <button
                                                    onClick={() => handleRemoveItemClick(item)}
                                                    className="item-selection-modal-button remove-item">Remove product
                                                </button>
                                            ) : (
                                                quantity !== 0 && (
                                                    <button
                                                        onClick={() => handleAddItemClick(item)}
                                                        className="item-selection-modal-button">Add product
                                                    </button>
                                                )
                                            )}
                                        </div>
                                    </section>
                                </li>
                            );
                        })}
                    </ul>
                )}
                <div className='item-selection-modal-save-button-block'>
                    <div>
                        <PaginationInModal
                            perPage={itemsPerPage}
                            total={searchTerm ? totalSearchResults : totalItems}
                            paginate={onPageChange}
                            currentPage={currentPage}
                        />
                    </div>
                    <button onClick={handleSave} className="item-selection-modal-save-button">Confirm selection</button>
                </div>
            </section>
        </Modal>
    );
};

export default ItemSelectionModal;
