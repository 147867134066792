import React, { useEffect, useState } from 'react';
import '../../components/Style List View/style.css';
import { useNavigate } from "react-router-dom";
import HeaderList from "../../components/HeaderRoutList/HeaderListRout";
import ElementList from "../../components/ElementList/ElementList";
import ProgressBar from "../../components/ProgressBar/ProgressBar";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import { deleteProject, fetchAllProjects } from "../../Slice/getProjectAssistance/ProjectAssistanceSlice";
import PaginationAssistance from "../../components/PaginationAssistance/pagination";
import { Project, User } from "../../types/ProjectTypes";
import DeleteModal from "../../components/Modals/ModalDelete/ModalDelete";
import {IconEyes, IconRemove, IconSettings, IconUserDefaultProfile} from "../../IconComponents/IconComponents";
import {Loader} from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";

const ProjectList: React.FC<PropsFromRedux> = ({ allProjects, isFetching, error, fetchAllProjects, deleteProject }) => {
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [projectToDelete, setProjectToDelete] = useState<Project | null>(null);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const projectsPerPage = 10;

    const getUniqueUsers = (users: User[], permissionsUsers: User[]): User[] => {
        const allUsers = [...users, ...permissionsUsers];
        const uniqueUsersMap = new Map<number, User>();
        allUsers.forEach(user => {
            if (user && !uniqueUsersMap.has(user.id)) {
                uniqueUsersMap.set(user.id, user);
            }
        });
        return Array.from(uniqueUsersMap.values());
    };

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        fetchAllProjects();
    }, [fetchAllProjects]);

    useEffect(() => {
        const loadUserImages = () => {
            allProjects.forEach(project => {
                const uniqueUsers = getUniqueUsers(project.users, project.permissions.map(permission => permission.user));
                uniqueUsers.forEach(user => {
                });
            });
        };

        if (allProjects.length > 0) {
            loadUserImages();
        }
    }, [allProjects]);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const handleDelete = (project: Project) => {
        setProjectToDelete(project);
    };

    const handleRemoveProject = () => {
        if (projectToDelete) {
            deleteProject(projectToDelete.id);
            setProjectToDelete(null);
        }
    };

    if (isFetching) return <Loader/>
    if (error) return <p>Error: {error}</p>;

    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    const currentProjects = allProjects.slice(indexOfFirstProject, indexOfLastProject);

    const columns = ['Name', 'Date', 'Progress', 'Members', 'Action'];
    const columnsWidthClasses = ['width-project-title', 'width-project-date',
        'width-project-progress', 'width-project-customers',
        'width-project-customers'];

    return (
        <>
            {isSmallScreen && (
                <MobileNavigation
                    links={[
                        {to: '/', label: 'Cashbuild'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Home'},
                        {to: '', label: '>'},
                        {to: '/assistance', label: 'Project assistance'},
                        {to: '', label: '>'},
                        {to: '/projects', label: 'Project list'}
                    ]}
                >
                </MobileNavigation>
            )}
            <HeaderList />
            <div>
                <div className='name-content-project all-projects'>
                    <p>Project group</p>
                    <img src='/img_name_content/allProject.svg' alt='All projects' />
                </div>
                {!isSmallScreen && (
                <ElementList columns={columns} columnsWidthClasses={columnsWidthClasses} />
                )}
                <div className='wrapper'>
                    <ul className="table-content-data-section-container-project">
                        {currentProjects.map((project: Project) => {
                            const uniqueUsers = getUniqueUsers(project.users, project.permissions.map(permission => permission.user));
                            const userImagesToShow = uniqueUsers.slice(0, 3);
                            const remainingUsersCount = uniqueUsers.length - userImagesToShow.length;

                            return (
                                <li key={project.id}>
                                    <section className="table-content-data-section-project">
                                        <div className="table-content-data-section-block-project">
                                            <p className='project-table-content-data-section-field-center-project width-project-title'>{project.title}</p>
                                            <p className='project-table-content-data-section-field-center-date width-project-date'>{new Date(project.created_at).toLocaleDateString()}</p>
                                            <div className='table-content-data-section-field-progress-project width-project-progress'>
                                                <ProgressBar project={project} showPercentagePosition={'below'}/>
                                            </div>
                                            <div className='template-add-project-members width-project-customers'>
                                                {userImagesToShow.map((user, index) => {
                                                    let imageUrl: string | null;
                                                    imageUrl = user.galleries && user.galleries.length > 0 && user.galleries[0].medias.length > 0
                                                        ? user.galleries[0].medias[user.galleries[0].medias.length - 1].url
                                                        : null;

                                                    return imageUrl ? (
                                                        <img key={index} src={imageUrl} alt={user.username} className="member-avatar" />
                                                    ) : (
                                                        <IconUserDefaultProfile key={index} className="custom-avatar-member" />
                                                    );
                                                })}

                                                {remainingUsersCount > 0 && (
                                                    <div className="more-members">
                                                        <p>+{remainingUsersCount}</p>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="table-content-data-section-field-actions-3btn">
                                            <div className='project-btn-view'>
                                                <button onClick={() => navigate(`/costs/${project.id}`)}
                                                        className='btn-view-project'>
                                                    <span className='project-btn-view-span'>View project</span>
                                                    <IconEyes/>
                                                </button>
                                            </div>
                                            <div className='action-block'>
                                                <button onClick={() => navigate(`/users/${project.id}`)}
                                                        className='team-action-btn'>
                                                    <div className='setting'><IconSettings/></div>
                                                </button>
                                                <button onClick={() => handleDelete(project)} className='action-btn'>
                                                    <div className='delete'><IconRemove/></div>
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <PaginationAssistance
                projectsPerPage={projectsPerPage}
                totalProjects={allProjects.length}
                paginate={paginate}
                currentPage={currentPage}
            />
            {projectToDelete && (
                <DeleteModal
                    isOpen={true}
                    onRequestClose={() => setProjectToDelete(null)}
                    onConfirm={handleRemoveProject}
                    onCancel={() => setProjectToDelete(null)}
                    message={`Are you sure you want to delete the project space "${projectToDelete.title}"?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    allProjects: state.projectAssistance.allProjects,
    isFetching: state.projectAssistance.isFetching,
    error: state.projectAssistance.error,
});
const mapDispatchToProps = {
    fetchAllProjects,
    deleteProject
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProjectList);
