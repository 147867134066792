import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from "react-redux";
import './StepCalc.css';
import { Template } from "../../types/TemplateType";
import { updateLocalTemplate, setCalculatedTemplate, clearTemplates } from '../../Slice/LocalTemplate/LocalTemplateSlice';
import { updateProject } from "../../Slice/updateProject/updateProjectSlice";
import { transformTemplate } from "../../utils/TransformTemplate";
import { clearLocalStorageExceptToken } from "../../utils/ClearLocalStorage";
import parseTemplateToRequestObject from "../../Parsers/TemplateParse/templateToTemplateRequestParser";
import { RootState } from "../../store";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface StepCalcProps {
    template: Template | null;
    selectedAnswers: Record<string, string>;
    setSelectedAnswers: Dispatch<SetStateAction<Record<string, string>>>;
    selectedTemplates: Template[];
    templIndex?: number;
}

const StepCalc: React.FC<StepCalcProps & PropsFromRedux> = ({
                                                                project,
                                                                updateLocalTemplate,
                                                                updateProject,
                                                                setCalculatedTemplate,
                                                                template,
                                                                selectedAnswers,
                                                                setSelectedAnswers,
                                                                clearTemplates,
                                                            }) => {
    const [expandedFragments, setExpandedFragments] = useState<Set<number>>(new Set([0]));
    const [isTemplateExpanded, setIsTemplateExpanded] = useState<boolean>(true);
    const [fragmentsComplete, setFragmentsComplete] = useState<Record<number, boolean>>({});
    const navigate = useNavigate();
    const location = useLocation();
    const { projectId, templIndex } = location.state || {};

    useEffect(() => {
        if (template?.data) {
            const initialSelectedAnswers: Record<string, string> = {};
            Object.entries(template.data || {}).forEach(([key, item]: [string, any]) => {
                if (!isNaN(parseInt(key)) && item?.value) {
                    initialSelectedAnswers[`${template.id}-calc-${key}`] = item.value;
                }
            });
            template.data.fragments?.forEach((fragment: any, fragmentIndex: number) => {
                Object.entries(fragment.data || {}).forEach(([key, item]: [string, any]) => {
                    const uniqueKey = `fragment-${fragmentIndex}-${key}`;
                    if (item?.value) {
                        initialSelectedAnswers[uniqueKey] = item.value;
                    }
                });
            });

            setSelectedAnswers(initialSelectedAnswers);
        }
    }, [template, setSelectedAnswers]);

    useEffect(() => {
        checkFragmentsCompletion();
    }, [template, selectedAnswers]);

    const toggleTemplateVisibility = () => {
        setIsTemplateExpanded(prevState => !prevState);
    };

    const handleAnswerChange = (key: string, value: string) => {
        setSelectedAnswers((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleInputChange = (key: string, value: string) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setSelectedAnswers((prevState) => ({
                ...prevState,
                [key]: value
            }));
        }
    };

    const handleInputBlur = (key: string) => {
        setSelectedAnswers((prevState) => ({
            ...prevState,
            [key]: prevState[key] === '' ? '' : prevState[key]
        }));
    };

    const handleSubmit = async () => {
        if (!template) {
            console.error("Template is undefined.");
            return;
        }
        const updatedTemplate = {
            ...template,
            isCalculated: true,
            data: {
                ...template.data,
                ...Object.entries(template.data || {})
                    .filter(([key, _]) => !isNaN(parseInt(key)))
                    .reduce((acc: Record<string, any>, [key, item]: [string, any]) => {
                        acc[key] = {
                            ...item,
                            value: selectedAnswers[`${template.id}-calc-${key}`] || item?.value,
                        };
                        return acc;
                    }, {}),
                fragments: template.data.fragments.map((fragment: any, fragmentIndex: number) => ({
                    ...fragment,
                    data: Object.entries(fragment.data || {}).reduce((acc: Record<string, any>, [key, item]: [string, any]) => {
                        const uniqueKey = `fragment-${fragmentIndex}-${key}`;
                        acc[key] = {
                            ...item,
                            value: selectedAnswers[uniqueKey] || item?.value,
                            answers: item?.answers ? item.answers.map((answer: any) => ({
                                ...answer,
                                value: item.select === 'input' ? selectedAnswers[uniqueKey] : answer?.value,
                                selected: item.select === 'input' ? true : selectedAnswers[uniqueKey] === answer?.value?.toString()
                            })) : item?.answers
                        };
                        return acc;
                    }, {})
                }))
            }
        };
        updateLocalTemplate(updatedTemplate);
        setCalculatedTemplate(template.id);

        let currentProject = project;
        if (!currentProject) {
            const storedProject = localStorage.getItem('project');
            if (storedProject) {
                currentProject = JSON.parse(storedProject);
            }
        }

        if (currentProject && currentProject.data && currentProject.data.templates) {
            const updatedTemplates = currentProject.data.templates.map(t => t.id === updatedTemplate.id ? { ...updatedTemplate, counted: true } : t);
            const transformedTemplates = updatedTemplates.map(transformTemplate);

            const updatedProjectData = {
                data: {
                    templates: transformedTemplates
                }
            };
            const toastId = toast.info("Waiting for server response...");

            try {
                const resultAction = await updateProject({ projectId: currentProject.id, projectData: updatedProjectData });
                if (resultAction.meta.requestStatus === 'fulfilled') {
                    toast.update(toastId, { render: "Project updated successfully!", type: "success", isLoading: false, autoClose: 3000 });
                    clearTemplates();
                    clearLocalStorageExceptToken();
                    setTimeout(() => {
                        navigate('/assistance/step2', { state: { projectId, templIndex } });
                    }, 2000);
                } else if (resultAction.meta.requestStatus === 'rejected') {
                    throw new Error("Update project failed");
                }
            } catch (error) {
                toast.update(toastId, { render: "Failed to update project", type: "error", isLoading: false, autoClose: 3000 });
            }
        } else {
            console.error("Unknown navigation source.");
        }
    };

    const allQuestions = Object.entries(template?.data || {})
        .filter(([key, item]: [string, any]) => !isNaN(parseInt(key)) && item?.question && item?.select);

    const toggleFragment = (index: number) => {
        setExpandedFragments(prevState => {
            const newSet = new Set(prevState);
            if (newSet.has(index)) {
                newSet.delete(index);
            } else {
                newSet.add(index);
            }
            return newSet;
        });
    };

    const checkFragmentsCompletion = () => {
        if (template?.data?.fragments) {
            const updatedFragmentsStatus: Record<number, boolean> = {};

            template.data.fragments.forEach((fragment: any, fragmentIndex: number) => {

                const hiddenKeys = new Set<string>();
                if (fragment?.data?.var_mapping) {
                    const mappings = fragment.data.var_mapping.split('&&').map((map: string) => map.trim());
                    mappings.forEach((mapping: string) => {
                        const [left, right] = mapping.split('=').map((s: string) => s.trim());
                        hiddenKeys.add(right);
                    });
                }

                const allFieldsComplete = Object.entries(fragment.data || {}).every(([key, fragmentData]: [string, any]) => {
                    const isVisible = !hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select;
                    if (!isVisible) {
                        return true;
                    }
                    const uniqueKey = `fragment-${fragmentIndex}-${key}`;

                    if (fragmentData.select === 'select') {
                        const isSelected = selectedAnswers[uniqueKey] && selectedAnswers[uniqueKey] !== '';
                        return isSelected;
                    }
                    if (fragmentData.select === 'input') {
                        const isFilled = selectedAnswers[uniqueKey] && selectedAnswers[uniqueKey] !== '';
                        return isFilled;
                    }
                    return false;
                });
                updatedFragmentsStatus[fragmentIndex] = allFieldsComplete;
            });

            setFragmentsComplete(updatedFragmentsStatus);
        }
    };




    if (!template) {
        return <div>Loading...</div>;
    }

    const parserTemplate = parseTemplateToRequestObject(template);

    return (
        <div className="step-calc">
            <div>
                <div className='step-calc-template-fragment-info'>
                    <p className='step-calc-template-title'>Template: {template.title}</p>
                    {parserTemplate?.data?.fragments?.map((fragment: any, fragmentIndex: number) => {
                        return (
                            <div key={`fragment-list-${fragmentIndex}`} className="fragment-list-item">
                                <label className='fragment-list-item-label'>
                                    {fragmentIndex + 1}. {fragment.title || `Fragment ${fragmentIndex + 1}`}
                                    <input
                                        type="checkbox"
                                        checked={fragmentsComplete[fragmentIndex] || false}
                                        readOnly
                                    />
                                </label>
                            </div>
                        );
                    })}
                </div>
                <div className={`step-calc-fragment ${isTemplateExpanded ? 'expanded' : 'collapsed'}`}
                     key={template.id}>
                    <h3 className="step-calc-fragment-title" onClick={toggleTemplateVisibility}>
                        Required Information:
                        <button className={`toggle-button ${isTemplateExpanded ? 'expanded' : 'collapsed'}`}>
                            {isTemplateExpanded ? '▲' : '▼'}
                        </button>
                    </h3>
                    <div className="step-calc-fragment-content">
                        {allQuestions.map(([key, templateQuestion]) => (
                            <div className="step-answer-block" key={`${template.id}-calc-${key}`}>
                                <div className="step-question">{templateQuestion?.question || 'Default Question'}</div>
                                <div className='step-calc-field-fragment'>
                                    <input
                                        className={`step-calc-field-fragment ${selectedAnswers[`${template.id}-calc-${key}`] ? 'filled' : ''}`}
                                        type="text"
                                        value={selectedAnswers[`${template.id}-calc-${key}`] || ''}
                                        onChange={(e) => handleInputChange(`${template.id}-calc-${key}`, e.target.value)}
                                        onBlur={() => handleInputBlur(`${template.id}-calc-${key}`)}
                                    />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>


                <div className='step-calc-fragment-block'>
                    {parserTemplate?.data?.fragments?.map((fragment: any, fragmentIndex: number) => {
                        const hiddenKeys = new Set<string>();

                        if (fragment?.data?.var_mapping) {
                            const mappings = fragment.data.var_mapping.split('&&').map((map: string) => map.trim());
                            mappings.forEach((mapping: string) => {
                                const [left, right] = mapping.split('=').map((s: string) => s.trim());
                                hiddenKeys.add(right);
                            });
                        }

                        const hasQuestions = Object.entries(fragment.data || {}).some(([key, fragmentData]: [string, any]) => {
                            return !hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select;
                        });

                        if (!hasQuestions) {
                            return null;
                        }

                        const isExpanded = expandedFragments.has(fragmentIndex);
                        const fragmentClass = fragmentsComplete[fragmentIndex] ? 'complete' : 'incomplete';

                        return (
                            <div key={`fragment-${fragmentIndex}`}
                                 className={`step-calc-fragment fragment-${fragmentIndex} ${isExpanded ? 'expanded' : 'collapsed'} ${fragmentClass}`}>
                                <h3 className="step-calc-fragment-title" onClick={() => toggleFragment(fragmentIndex)}>
                                    {fragment.title || `Fragment ${fragmentIndex + 1}`}
                                    <button
                                        className={`toggle-button ${isExpanded ? '' : 'collapsed'}`}>{isExpanded ? '▲' : '▼'}</button>
                                </h3>
                                <div className={`step-calc-fragment-content ${isExpanded ? 'expanded' : ''}`}>
                                    {Object.entries(fragment.data || {}).map(([key, fragmentData]: [string, any], index: number) => {
                                        const uniqueKey = `fragment-${fragmentIndex}-${key}`;

                                        if (!hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select) {
                                            return (
                                                <div key={`${fragmentIndex}-${key}`} className="step-answer-block">
                                                    <div className="step-question">{fragmentData.question}</div>
                                                    {fragmentData.select === 'select' ? (
                                                        fragmentData.answers ? (
                                                            fragmentData.answers.map((answer: any, idx: number) => (
                                                                <label key={idx} className="step-answer">
                                                                    <input
                                                                        type="radio"
                                                                        name={`fragment-${fragmentIndex}-${key}`}
                                                                        value={answer.value}
                                                                        checked={selectedAnswers[uniqueKey] === answer.value}
                                                                        onChange={() => handleAnswerChange(uniqueKey, answer.value)}
                                                                    />
                                                                    <span className="radio-custom"></span>
                                                                    {answer.title}
                                                                </label>
                                                            ))
                                                        ) : <p>No answers available</p>
                                                    ) : (
                                                        <div className='step-calc-field-fragment'>
                                                            <input
                                                                type="text"
                                                                value={selectedAnswers[uniqueKey] || ''}
                                                                onChange={(e) => handleInputChange(uniqueKey, e.target.value)}
                                                                onBlur={() => handleInputBlur(uniqueKey)}
                                                                className={selectedAnswers[uniqueKey] ? 'filled' : ''}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            );
                                        }
                                        return null;
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className='step-bottom-block'>
                <button className='step-bottom-btn' onClick={handleSubmit}>Next</button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
    isFetching: state.updateProject.isFetching
});

const mapDispatchToProps = {
    updateProject,
    updateLocalTemplate,
    setCalculatedTemplate,
    clearTemplates
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(StepCalc);
