// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --main-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}


.step2-header-list{
    display: flex;
    padding: 0 15px;
    border-radius: 10px;
    color: #626262;
    justify-content: space-between;
    background-color: #e0e0e0;
    font-weight: 500;
    min-width: -moz-fit-content;
    min-width: fit-content;
}
.new-step2-material-list{
    display: flex;
    align-items: center;
}
.new-step2-material-list-name{
    display: flex;
    align-items: center;
    width: 100%;
    min-width: -moz-fit-content;
    min-width: fit-content;
    font-family: var(--main-font);
    font-weight: 600;
    font-size: 22px !important;
}
.new-step2-btn-setting{
    width: 25px;
}
.new-step2-btn-setting:hover{
    transform: scale(0.9);
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Assis Step2 Component/step2HeaderList.css"],"names":[],"mappings":"AAAA;IACI,kMAAkM;AACtM;;;AAGA;IACI,aAAa;IACb,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,8BAA8B;IAC9B,yBAAyB;IACzB,gBAAgB;IAChB,2BAAsB;IAAtB,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,WAAW;IACX,2BAAsB;IAAtB,sBAAsB;IACtB,6BAA6B;IAC7B,gBAAgB;IAChB,0BAA0B;AAC9B;AACA;IACI,WAAW;AACf;AACA;IACI,qBAAqB;IACrB,eAAe;AACnB","sourcesContent":[":root {\n    --main-font: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';\n}\n\n\n.step2-header-list{\n    display: flex;\n    padding: 0 15px;\n    border-radius: 10px;\n    color: #626262;\n    justify-content: space-between;\n    background-color: #e0e0e0;\n    font-weight: 500;\n    min-width: fit-content;\n}\n.new-step2-material-list{\n    display: flex;\n    align-items: center;\n}\n.new-step2-material-list-name{\n    display: flex;\n    align-items: center;\n    width: 100%;\n    min-width: fit-content;\n    font-family: var(--main-font);\n    font-weight: 600;\n    font-size: 22px !important;\n}\n.new-step2-btn-setting{\n    width: 25px;\n}\n.new-step2-btn-setting:hover{\n    transform: scale(0.9);\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
