import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deleteProjectById, getAllProjectsAssistance } from "../../api/APIWraper";
import { initialState, Project } from "../../types/ProjectTypes";
import { updateProjectPermissions } from "../../Slice/updateProject/updateProjectSlice";

export const fetchAllProjects = createAsyncThunk(
    'projectAssistance/fetchAllProjects',
    async () => {
        const response = await getAllProjectsAssistance(1, 1000);
        return response.projects;
    }
);

export const deleteProject = createAsyncThunk(
    'projectAssistance/deleteProject',
    async (projectId: number) => {
        await deleteProjectById(projectId);
        return projectId;
    }
);

const projectAssistanceSlice = createSlice({
    name: 'projectAssistance',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProjects.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchAllProjects.fulfilled, (state, action: PayloadAction<Project[] | undefined>) => {
                state.isFetching = false;

                if (action.payload && Array.isArray(action.payload)) {
                    state.allProjects = action.payload;
                    state.projects = action.payload.slice(0, 11);
                    state.total = action.payload.length;
                } else {
                    state.allProjects = [];
                    state.projects = [];
                    state.total = 0;
                }
            })
            .addCase(fetchAllProjects.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteProject.fulfilled, (state, action: PayloadAction<number>) => {
                state.allProjects = state.allProjects.filter(project => project.id !== action.payload);
                state.projects = state.projects.filter(project => project.id !== action.payload);
                state.total = state.allProjects.length;
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.error = action.error.message ?? null;
            })
            .addCase(updateProjectPermissions.fulfilled, (state, action: PayloadAction<Project>) => {
                const updatedProject = action.payload;
                state.allProjects = state.allProjects.map(project =>
                    project.id === updatedProject.id ? updatedProject : project
                );
                state.projects = state.projects.map(project =>
                    project.id === updatedProject.id ? updatedProject : project
                );
            });
    },
});

export default projectAssistanceSlice.reducer;
