import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import './style.css';

interface EditPermissionsModalProps {
    isOpen: boolean;
    onRequestClose: () => void;
    onSave: (email: string, role: string) => Promise<void>;
    currentEmail: string;
    currentRole: string;
}

const EditPermissionsModal: React.FC<EditPermissionsModalProps> = ({ isOpen, onRequestClose, onSave, currentEmail, currentRole }) => {
    const [email, setEmail] = useState<string>(currentEmail);
    const [role, setRole] = useState<string>(currentRole);

    useEffect(() => {
        setEmail(currentEmail);
        setRole(currentRole);
    }, [currentEmail, currentRole]);

    const handleSave = async () => {
        await onSave(email, role);
        onRequestClose();
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} className="edit-user-modal" overlayClassName="overlay">
            <div className="edit-user-modal-header">
                <h2>Edit Member Permissions</h2>
                <button onClick={onRequestClose} className="close-button">&times;</button>
            </div>
            <div className="edit-user-modal-content">
                <label className='permissions-email'>
                    Email:
                    <input type="text" value={email} readOnly className="readonly-input" />
                </label>
                <label className='permissions-role'>
                    Role:
                    <select value={role} onChange={(e) => setRole(e.target.value)}>
                        <option value="User">User</option>
                        <option value="Admin">Admin</option>
                    </select>
                </label>
            </div>
            <div className="edit-user-modal-footer">
                <button onClick={handleSave} className="save-button">Save</button>
            </div>
        </Modal>
    );
};

export default EditPermissionsModal;
