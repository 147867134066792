import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import { addTemplatesToProjects } from "../../api/APIWraper";
import { User } from "../../types/ProjectTypes";
import {IconUserDefaultProfile} from "../../IconComponents/IconComponents";
import {notifyModalSuccess, notifySuccess} from "../Toast/AssisCreateToastNotification";

interface ModalAddTemplateProps {
    isOpen: boolean;
    tempId: number;
    tempTitle: string;
    onClose: () => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type CombineProps = ModalAddTemplateProps & PropsFromRedux;

const ModalAddTemplate: React.FC<CombineProps> = ({ allProjects, isOpen, onClose, tempId, tempTitle }) => {
    const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
    const [userImages, setUserImages] = useState<Map<number, string | null>>(new Map());

    useEffect(() => {
        const loadUserImages = () => {
            const imagesMap = new Map<number, string | null>();
            allProjects.forEach(project => {
                const uniqueUsers = getUniqueUsers(project.users, project.permissions.map(permission => permission.user));
                uniqueUsers.forEach(user => {
                    const imageUrl = user.galleries && user.galleries.length > 0 && user.galleries[0].medias.length > 0
                        ? user.galleries[0].medias[0].url
                        : null;
                    imagesMap.set(user.id, imageUrl);
                });
            });
            setUserImages(imagesMap);
        };

        if (allProjects.length > 0) {
            loadUserImages();
        }
    }, [allProjects]);

    const handleCheckboxChange = (projectId: number) => {
        setSelectedProjects(prevSelectedProjects =>
            prevSelectedProjects.includes(projectId)
                ? prevSelectedProjects.filter(id => id !== projectId)
                : [...prevSelectedProjects, projectId]
        );
    };

    const handleAddTemplate = async () => {
        const selectedProjectTitles = allProjects
            .filter(project => selectedProjects.includes(project.id))
            .map(project => `${project.title}`);

        const payload = {
            projects: selectedProjects,
        };
        await addTemplatesToProjects(tempId, payload);
        notifySuccess(`The template "${tempTitle}" was successfully added to ${selectedProjectTitles.join(', ')}`);
        setSelectedProjects([]);
        onClose();
    };

    const getUniqueUsers = (users: User[], permissionsUsers: User[]): User[] => {
        const allUsers = [...users, ...permissionsUsers];
        const uniqueUsersMap = new Map<number, User>();
        allUsers.forEach(user => {
            if (user && !uniqueUsersMap.has(user.id)) {
                uniqueUsersMap.set(user.id, user);
            }
        });
        return Array.from(uniqueUsersMap.values());
    };

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={onClose} className='modal-template' overlayClassName='overlay'>
                <div className='modal-template-header'>
                    <div className="modal-template-header-content">
                        <p>Select which project group you want to add the <strong>"{tempTitle}"</strong> project to</p>
                        <button onClick={onClose} className="template-close-button">&times;</button>
                    </div>
                </div>
                <div className="modal-template-content">
                    {allProjects.map((project) => {
                        const uniqueUsers = getUniqueUsers(project.users, project.permissions.map(permission => permission.user));
                        const usersToShow = uniqueUsers.slice(0, 3);
                        const remainingUsersCount = uniqueUsers.length - usersToShow.length;

                        return (
                            <div key={project.id} className="template-project-item">
                                <div className="template-project-details">
                                    <div className="template-add-project-title">{project.title}</div>
                                    <div
                                        className="template-add-project-date">{new Date(project.created_at).toLocaleDateString()}</div>
                                    <div className="template-add-project-members">
                                        {usersToShow.map((user, index) => {
                                            const imageUrl = userImages.get(user.id);
                                            return imageUrl !== null ? (
                                                <img key={index} src={imageUrl} alt="user" className="member-avatar"/>
                                            ) : (
                                                <IconUserDefaultProfile className='custom-avatar-member' key={index}/>
                                            );
                                        })}
                                        {remainingUsersCount > 0 && (
                                            <div className="more-members"><p>+{remainingUsersCount}</p></div>
                                        )}
                                    </div>
                                </div>
                                <input
                                    type="checkbox"
                                    className="template-project-checkbox"
                                    onChange={() => handleCheckboxChange(project.id)}
                                    checked={selectedProjects.includes(project.id)}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className='template-add-project-btn'>
                    <button onClick={handleAddTemplate} className="template-add-btn">Add to projects group</button>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    allProjects: state.projectAssistance.allProjects,
    isFetching: state.projectAssistance.isFetching,
    error: state.projectAssistance.error,
});

const connector = connect(mapStateToProps, null);

export default connector(ModalAddTemplate);
