import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getUsersFromOwnProjects } from "../../api/APIWraper";
import { User } from "../../types/ProjectTypes";

interface UsersFromOwnProjectsState {
    users: User[];
    isFetching: boolean;
    error: string | null;
    totalUsers: totalUsers;
}
interface totalUsers{
    currentPage:number;
    from:number;
    lastPage:number;
    perPage:number;
    to:number;
    total:number;
}

const initialState: UsersFromOwnProjectsState = {
    users: [],
    isFetching: false,
    error: null,
    totalUsers: {
        currentPage: 1,
        from: 0,
        lastPage: 1,
        perPage: 10,
        to: 0,
        total: 0,
    },
};

export const fetchUsersFromOwnProjects = createAsyncThunk(
    'usersFromOwnProjects/fetchUsers',
    async ({ page, perPage }: { page: number; perPage: number }) => {
        const response = await getUsersFromOwnProjects(page, perPage);
        return {
            users: response.users.data,
            total: response.total,
        };
    }
);


const usersFromOwnProjectsSlice = createSlice({
    name: 'usersFromOwnProjects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchUsersFromOwnProjects.pending, (state) => {
                state.isFetching = true;
                state.error = null;
            })
            .addCase(fetchUsersFromOwnProjects.fulfilled, (state, action: PayloadAction<{ users: User[]; total: totalUsers }>) => {
                state.isFetching = false;
                state.users = action.payload.users;
                state.totalUsers = action.payload.total;
            })
            .addCase(fetchUsersFromOwnProjects.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message || 'Failed to fetch users';
            });
    },
});

export default usersFromOwnProjectsSlice.reducer;
