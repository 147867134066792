// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay-main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 500;
}

.modal-main {
    background: white;
    border-radius: 8px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
}

.closeButton-main {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    border-radius: 100%;
    border: none;
    font-size: 24px;
    cursor: pointer;
    padding: 10px;
    transition: 0.3s background-color ease;
}
.closeButton-main:hover {
    background-color: #e3e3e3;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/Modal/modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,uBAAkB;IAAlB,kBAAkB;IAClB,aAAa;IACb,yCAAyC;IACzC,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,eAAe;IACf,eAAe;IACf,aAAa;IACb,sCAAsC;AAC1C;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".overlay-main {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.5);\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 500;\n}\n\n.modal-main {\n    background: white;\n    border-radius: 8px;\n    width: fit-content;\n    padding: 20px;\n    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n    position: relative;\n}\n\n.closeButton-main {\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    background-color: white;\n    border-radius: 100%;\n    border: none;\n    font-size: 24px;\n    cursor: pointer;\n    padding: 10px;\n    transition: 0.3s background-color ease;\n}\n.closeButton-main:hover {\n    background-color: #e3e3e3;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
