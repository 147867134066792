import './step2HeaderList.css';
import "../../Page/Assistance Step2/Step2Styles/step2-width.css";

interface HeaderListProps {
    isLabor?: boolean;
    isTool?: boolean;
    showSettingsButton?: boolean;
}

const HeaderList: React.FC<HeaderListProps> = ({ isLabor, showSettingsButton, isTool }) => {
    return (
        <>
            {!showSettingsButton ? (
                <div className='step2-header-list '>
                    <p className='step2-material-width'>Product</p>
                    <p className='step2-other-width'>QTY to order</p>
                    <p className='step2-other-width'>QTY ordered</p>
                    <p className='step2-other-width'>Unit price</p>
                    <p className='step2-other-width'>Total Value</p>
                    <p className='step2-other-width step2-item-total-ordered'>Current order</p>
                    <p className='step2-item-vedor'>Vendor</p>
                </div>
            ) : (
                <div className='step2-header-list '>
                    <p className='step2-material-width'>Product</p>
                    <p className='step2-other-width'>QTY ordered</p>
                    <p className='step2-other-width'>Calculated QTY</p>
                    <p className='step2-other-width'>Unit price</p>
                    <p className='step2-other-width step2-item-total-ordered'>Total ordered</p>
                    <p className='step2-other-width'>Total</p>
                    <p className='step2-item-vedor'>Vendor</p>

                </div>
            )}
        </>
    );
};

export default HeaderList;
