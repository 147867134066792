import React, { useState } from 'react';
import './style.css';

const Footer = () => {
    const [activeSection, setActiveSection] = useState('');

    const toggleSection = (section: React.SetStateAction<string>) => {
        setActiveSection(activeSection === section ? '' : section);
    };
    return (
        <footer className='footer'>
            <div className='ft-header'>
                <div className='ft-header-icon-block'>
                    <div className='ft-header-span'>
                        <span>Follow us on:</span>
                    </div>
                    <div className='ft-header-icon'>
                        <img title='facebook' src='/img_footer/facebook.png' alt='facebook' />
                        <img title='twitter' src='/img_footer/twitter.png' alt='twitter' />
                        <img title='youtube' src='/img_footer/youtube.png' alt='youtube' />
                        <img title='instagram' src='/img_footer/insta.png' alt='instagram' />
                    </div>
                </div>
            </div>
            <div className='ft-info'>
                <div className='ft-info-block'>
                    <div className='ft-h' onClick={() => toggleSection('information')}>
                        <h1>INFORMATION</h1>
                        <span className={`arrow ${activeSection === 'information' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'information' ? 'show' : ''}`}>
                        <p>About As</p>
                        <p>FAQ</p>
                        <p>Terms and Conditions</p>
                        <p>Delivery Policy</p>
                        <p>Returns Policy</p>
                        <p>Disclaimer</p>
                        <p>PoPI Act</p>
                    </div>
                </div>
                <div className='ft-info-block'>
                    <div className='ft-h' onClick={() => toggleSection('account')}>
                        <h1>MY ACCOUNT</h1>
                        <span className={`arrow ${activeSection === 'account' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'account' ? 'show' : ''}`}>
                        <p>Order History</p>
                        <p>Account</p>
                        <p>Check Out</p>
                        <p>Cart</p>
                        <p>Manage my Cookies</p>
                    </div>
                </div>
                <div className='ft-info-block contact-us'>
                    <div className='ft-h' onClick={() => toggleSection('contact')}>
                        <h1>CONTACT US</h1>
                        <span className={`arrow ${activeSection === 'contact' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'contact' ? 'show' : ''}`}>
                        <p>Contact Form</p>
                        <div className='contact-us-show'>
                            <p className='contact-us-stor'>Select Stores</p>
                            <span>/</span>
                            <p className='contact-us-map'>Show on map</p>
                            <span>/</span>
                            <p className='contact-us-det'>Store Details</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ft-footer'>
                <p>Powered by GoBuild</p>
            </div>
        </footer>
    );
}

export default Footer




