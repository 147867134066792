import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AuthStyle.css';
import { PuffLoader } from "react-spinners";
import { post } from "../../api/APIWraper";

interface AuthFields {
    email: string;
    password: string;
    confirmPassword: string;
    username: string;
}

const Registration: React.FC = () => {
    const navigate = useNavigate();

    const [authFields, setAuthFields] = useState<AuthFields>({
        email: "",
        password: "",
        confirmPassword: "",
        username: ""
    });

    const [isBadEmail, setIsBadEmail] = useState(false);
    const [isBadConfirmPassword, setIsBadConfirmPassword] = useState(false);
    const [isBadUsername, setIsBadUsername] = useState(false);
    const [isWaitingForSignIn, setIsWaitingForSignIn] = useState(false);

    const validEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validPasswordRegex = /^(?!\s*$).+/;

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        setAuthFields({ ...authFields, email });
        setIsBadEmail(!validEmailRegex.test(email));
    };

    const handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const username = e.target.value;
        setAuthFields({ ...authFields, username });
        setIsBadUsername(username.length < 6);
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthFields({
            ...authFields,
            password: e.target.value
        });
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthFields({
            ...authFields,
            confirmPassword: e.target.value
        });
    };

    const handleConfirmPasswordBlur = () => {
        if (authFields.password !== authFields.confirmPassword) {
            setIsBadConfirmPassword(true);
        } else {
            setIsBadConfirmPassword(false);
        }
    };

    const validateForm = () => {
        const { email, password, confirmPassword, username } = authFields;

        if (!validEmailRegex.test(email)) {
            toast.error("Invalid email format");
            return false;
        }
        if (username.length < 6) {
            toast.error("Username must be at least 6 characters long");
            return false;
        }
        if (!validPasswordRegex.test(password)) {
            toast.error("Password must not be empty");
            return false;
        }
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return false;
        }

        return true;
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!validateForm()) return;

        setIsWaitingForSignIn(true);
        try {
            const { email, password, username } = authFields;
            const response = await post(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/register`, { email, password, username });

            if (response.status === 409) {
                toast.error("Email is already registered");
            } else if (response.status === 201) {
                toast.success("Registration successful");
                navigate("/auth/login");
            } else {
                toast.error("Registration failed");
            }
        } catch (error) {
            toast.error("An error occurred during registration");
        } finally {
            setIsWaitingForSignIn(false);
        }
    };

    return (
        <div className="layout-login">
            <div className="login-card">
                <div className="login-card-top">
                    <div className="login-card-svg-container">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24"
                             width="24"
                             height="24"
                             fill="white">
                            <path
                                d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/>
                        </svg>
                    </div>
                </div>

                <form className="login-card-form" onSubmit={handleSubmit}>
                    <div>
                        <div className={`login-card-form-input-container ${isBadEmail ? 'red-font-error' : ''}`}>
                            <input
                                className={`login-card-form-input 
                                ${isBadEmail ? 'red-font-error ' : ''}
                                `}
                                type="email"
                                value={authFields.email}
                                onChange={handleEmailChange}
                                placeholder="Email *"
                            />
                        </div>
                    </div>

                    <div>
                        <div className={`login-card-form-input-container ${isBadUsername ? 'red-font-error' : ''}`}>
                            <input
                                className={`login-card-form-input`}
                                type="text"
                                value={authFields.username}
                                onChange={handleUsernameChange}
                                placeholder="Username *"
                            />
                        </div>
                        {isBadUsername && <p className="login-card-form-input-message">Username must be at least 6 characters</p>}
                    </div>

                    <div>
                        <div className="login-card-form-input-container">
                            <input
                                className="login-card-form-input"
                                type="password"
                                value={authFields.password}
                                onChange={handlePasswordChange}
                                placeholder="Password *"
                            />
                        </div>
                    </div>

                    <div>
                        <div className="login-card-form-input-container">
                            <input
                                className={`login-card-form-input ${isBadConfirmPassword ? 'red-font-error' : ''}`}
                                type="password"
                                value={authFields.confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                onBlur={handleConfirmPasswordBlur}
                                placeholder="Confirm Password *"
                            />
                        </div>
                        {isBadConfirmPassword && (
                            <p className="login-card-form-input-message">Passwords do not match</p>
                        )}
                    </div>

                    <button
                        className={`login-card-form-button ${isWaitingForSignIn ? 'gray-background' : ''}`}
                        type="submit"
                    >
                        {!isWaitingForSignIn && "REGISTER"}
                        {isWaitingForSignIn &&
                            <div className="login-loading-spin">
                                <PuffLoader size={25}/>
                            </div>
                        }
                    </button>
                </form>
            </div>

            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                    backgroundColor: "#333",
                    color: "#fff",
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
            />
        </div>
    );
};

export default Registration;
