export const clearLocalStorageExceptToken = () => {
    const authToken = localStorage.getItem('Bearer');
    const visitedItems = localStorage.getItem('visitedItems');
    localStorage.clear();
    if (authToken) {
        localStorage.setItem('Bearer', authToken);
    }
    if (visitedItems) {
        localStorage.setItem('visitedItems', visitedItems);
    }
};
