import './style.css';
import { useCallback, useState} from 'react';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {clearTemplates} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {IconLogOut} from "../../IconComponents/IconComponents";
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TopHeader() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSpinning, setIsSpinning] = useState(false);
    const [activeButton, setActiveButton] = useState('Home');

    const buttons = ['Home', 'About Us', 'Pricing', 'Functionality', 'Support'];
    const activeIndex = buttons.indexOf(activeButton);
    const buttonCount = buttons.length;

    const navigateToAssistance = () => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
        navigate('/assistance');
    };


    const handleButtonClick = useCallback((button: string) => {
        setActiveButton(button);
        if(button === 'Home') {
            navigateToAssistance();
        }
    }, [navigateToAssistance]);


    const handleLogOut = () => {
        toast.info('Wait, we\'re coming out...');
        setIsSpinning(true);

        setTimeout(() => {
            localStorage.removeItem('Bearer');
            navigate('/auth/login');
            setIsSpinning(false);
            toast.success('You have successfully logged out!!');
        }, 1500);
    };

    return (
        <div className='top-header'>
            <div className='top-header-red'>
                <div className='top-header-left-container'>
                    <button onClick={handleLogOut} className='top-header-btn-logout'>
                        <div title='Log out' className={`icon-container ${isSpinning ? 'spin-logout' : ''}`}>
                            <IconLogOut />
                        </div>
                    </button>
                    <div className='header-logo'>
                        <img className='header-logo-img' src='/img_projects/gobuild.svg' alt='Logo' />
                    </div>
                </div>
                <div className='header-switcher'>
                    <div
                        className="active-background"
                        style={{
                            left: `${(activeIndex * 100) / buttonCount}%`,
                            width: `${100 / buttonCount}%`
                        }}
                    />
                    {buttons.map(button => (
                        <button
                            key={button}
                            className={`header-switcher-btn ${activeButton === button ? 'active-header-btn' : ''}`}
                            onClick={() => handleButtonClick(button)}
                        >
                            {button}
                        </button>
                    ))}
                </div>

                <div className='top-header-right-container'>
                    <button className='top-header-btn-calc'>
                        Get Started <span className="arrow-header">-&gt;</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;
