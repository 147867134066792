import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import './Step1.css';
import 'react-toastify/dist/ReactToastify.css';
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import StepCalc from "../../components/AssisStepCalc/StepCalc";
import { updateLocalTemplate } from '../../Slice/LocalTemplate/LocalTemplateSlice';
import { Template } from '../../types/TemplateType';
import { clearLocalStorageExceptToken } from "../../utils/ClearLocalStorage";
import {Loader} from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";

const Step1: React.FC<PropsFromRedux> = ({ selectedTemplates, project }) => {
    const { templateId } = useParams<{ templateId: string }>();
    const navigate = useNavigate();
    const location = useLocation();
    const { templIndex } = location.state;
    const [template, setTemplate] = useState<Template | null>(null);
    const [isReady, setIsReady] = useState(false);
    const [selectedAnswers, setSelectedAnswers] = useState<Record<string, string>>({});
    const [percentage, setPercentage] = useState(0);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    useEffect(() => {
        const storedTemplateId = templateId || localStorage.getItem('currentTemplateId');
        const storedTemplate = location.state?.template || JSON.parse(localStorage.getItem(`selectedTemplate-${storedTemplateId}`) || 'null');
        if (storedTemplate) {
            setTemplate(storedTemplate);
            setIsReady(true);
        } else {
            navigate('/assistance');
        }
    }, [templateId, navigate, location.state]);

    useEffect(() => {
        if (template) {
            localStorage.setItem(`selectedTemplate-${template.id}`, JSON.stringify(template));
        }
    }, [template]);

    useEffect(() => {
        if (isReady) {
            document.body.style.overflow = 'unset';
        }
    }, [isReady]);

    useEffect(() => {
        if (project) {
            localStorage.setItem(`project`, JSON.stringify(project));
            setPercentage(project.progress);
        }
    }, [project]);

    const navigateToAssistance = () => {
        clearLocalStorageExceptToken()
        navigate('/assistance');
    };

    if (!isReady) {
            return <Loader/>
    }
    if (!template) return null;

    const descriptionText = template.short_description || 'No description';

    return (
        <>
            <div className='template-name-content'>
                {isSmallScreen ? (
                    <MobileNavigation
                        links={[
                            { to: '/', label: 'Cashbuild' },
                            { to: '', label: '>' },
                            { to: '/', label: 'Home' },
                            { to: '', label: '>' },
                            { to: '/assistance', label: 'Project assistance' },
                            { to: '', label: '>' },
                            { to: '', label: 'Create new project space' }
                        ]}
                    />
                ) : (
                <div className='template-blog-placeholder'>
                    <p>Home/</p>
                    <p onClick={() => navigateToAssistance()} className='template-link-assistance'>Project group assistance</p>
                    <p className='name-link'>/Create new project space</p>
                </div>
                )}
            </div>
            <div className='step-info'>
                <div className='step-info-block'>
                    <h3>Step 1</h3>
                    <span>/</span>
                    <p className='step-info-block-name'>Specify parameters of building</p>
                </div>
            </div>
            <div className='step-template-container'>
                <div className='step-template-block left'>
                    <h2>Type:</h2>
                    <p className='step-template-block-title'>{template.title}</p>
                </div>
                <div className='step-template-block right'>
                    <h2>Description:</h2>
                    <p className='step-template-block-desc'>{descriptionText}</p>
                </div>
                {!isSmallScreen && (
                    <div className='step-template-block-percent'>
                        <div className='progress-bar-wrapper'>
                            <CircularProgressbar
                                value={percentage}
                                styles={buildStyles({
                                    pathColor: 'var(--basic-btn-dark-color)',
                                    textColor: '#000000',
                                    trailColor: '#d6d6d6',
                                    textSize: '24px',
                                    pathTransitionDuration: 0.5,
                                })}
                            />
                            <div className='progress-bar-text'>
                                {percentage}%
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <p className='step1-info-text'>This building project requires you to insert dimensions for the following sections below:</p>
            <StepCalc
                templIndex={templIndex}
                selectedTemplates={selectedTemplates}
                template={template}
                selectedAnswers={selectedAnswers}
                setSelectedAnswers={setSelectedAnswers}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedTemplates: state.localTemplate.templates,
    project: state.projectById.project,
});

const mapDispatchToProps = {
    updateLocalTemplate,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step1);
