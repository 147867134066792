import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { fetchTemplateById } from "../../Slice/getByIdTemplate/ByIdTemplateSlice";
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import './style.css';
import {Loader} from "../../components/Loader/Loader";
import Footer from "../../components/ProjectAssistanceFooter/Footer";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {Project} from "../../types/ProjectTypes";
import {addTemplate} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import CreateProjectModal from "../../components/Modals/ModalCreateProject/ModalCreateProject";
import ModalAddTemplate from "../../components/ProjectAssistanceTemplatesAdd/ModalAddTemplate";
import {fetchAllProjects} from "../../Slice/getProjectAssistance/ProjectAssistanceSlice";
import {IconTemplatesAssistance} from "../../IconComponents/IconComponents";

const TemplatePage: React.FC<PropsFromRedux> = ({ template, isFetching, fetchTemplateById }) => {
    const { tempId } = useParams<{ tempId: string }>();
    const navigate = useNavigate();
    const isFirstRender = useRef(true);

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isModalOpenProject, setIsModalOpenProject] = useState<boolean>(false);
    const [selectedTempId, setSelectedTempId] = useState<number>(0);
    const [selectedTempTitle, setSelectedTempTitle] = useState<string>('');

    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handleOpenModal = () => setIsModalOpenProject(true);

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setIsModalOpenProject(false);
    };

    const infoTemplateToModal = () => {
        setIsModalOpen(true);
        if (tempId != null) {
            setSelectedTempId(parseInt(tempId));
        }
        if (template) {
            setSelectedTempTitle(template.title);
        }
    };

    const handleProjectCreation = (project: Project) => {
        navigate('/assistance/create', { state: { projectId: project.id } });
        if (template) addTemplate(template);
    };


    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isFirstRender.current && tempId) {
            fetchTemplateById(parseInt(tempId));
            isFirstRender.current = false;
        }
    }, [fetchTemplateById, tempId]);

    if (isFetching) {
        return (<Loader/>)
    }
    const renderCategories = () => {
        if (template){
        if (template.categories.length === 0) return null;
            return (
                <div className='blog-categories'>
                    <p>Category: {template.categories.map(category => category.category).join(', ')}</p>
                    <IconTemplatesAssistance />
                </div>
            );
        }
    };


    const renderMediaSections = () => {
        if (!template?.description) {
            return <p>No content available</p>;
        }

        const hasOnlyText = template.description && !template.description.includes('<img');

        if (hasOnlyText) {
            return (
                <div className="blog-section">
                    <div className="blog-media-section full-width-text blog-text-block-only">
                        <div className="blog-text-block-only">
                            <h2 className="blog-text-block-title">Description</h2>
                            <p
                                className="blog-text-block-p"
                                dangerouslySetInnerHTML={{ __html: template.description }}
                            ></p>
                        </div>
                    </div>
                </div>
            );
        }

        const parser = new DOMParser();
        const parsedHtml = parser.parseFromString(template.description, "text/html");
        const blocks = parsedHtml.querySelectorAll(".image-block");
        return Array.from(blocks).map((block, index) => {
            const isOdd = index % 2 !== 0;
            const image = block.querySelector("img");
            const text = block.querySelector(".image-text");

            if (image) {
                image.removeAttribute("class");
            }

            const buttons = block.querySelectorAll("button");
            buttons.forEach(button => button.remove());

            const hasImage = !!image;
            const hasText = !!(text?.innerHTML && text.innerHTML.trim());

            let mediaClass = '';
            if (hasImage && !hasText) {
                mediaClass = 'full-width-image';
            } else if (!hasImage && hasText) {
                mediaClass = 'full-width-text';
            }

            return (
                <div className='blog-section' key={index}>
                    <div className={`blog-media-section ${isOdd ? 'odd' : 'even'} ${mediaClass}`}>
                        {isOdd ? (
                            <>
                                {hasText && (
                                    <div className='blog-text-block block-even'>
                                        <h2 className='blog-text-block-title-right'>
                                            Description
                                        </h2>
                                        <p className='blog-text-block-p' dangerouslySetInnerHTML={{ __html: text?.innerHTML || 'No text' }}></p>
                                        {index === 0 && addTemplateButton("blog-media-section-button-right")}
                                    </div>
                                )}
                                {hasImage && <img className='blog-media-img' src={image?.src} alt="Block Image" />}
                            </>
                        ) : (
                            <>
                                {hasImage && <img className='blog-media-img' src={image?.src} alt="Block Image" />}
                                {hasText && (
                                    <div className='blog-text-block block-odd'>
                                        {!isSmallScreen && renderCategories()}
                                        <h2 className='blog-text-block-title'>
                                            Description {index === 0 && (
                                            <span
                                                className='blog-text-block-reviews'>/{template?.reviews || 'No'} Reviews</span>
                                        )}
                                        </h2>
                                        <p className='blog-text-block-p'
                                           dangerouslySetInnerHTML={{__html: text?.innerHTML || 'No text'}}></p>
                                        {index === 0 && addTemplateButton("blog-media-section-button")}
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            );
        });
    };

    const addTemplateButton = (containerClassName: string) => (
        <div className={`${containerClassName}`}>
            <button onClick={infoTemplateToModal} className='blog-media-section-btn-exis'>Add to existing project
                group
            </button>
            <button onClick={handleOpenModal} className='blog-media-section-btn-new'>Add to new project space</button>
        </div>
    );

    return (
        <div className='wrapper'>
            {isSmallScreen ? (
                <MobileNavigation
                    links={[
                        { to: '/', label: 'Cashbuild' },
                        { to: '', label: '>' },
                        { to: '/', label: 'Home' },
                        { to: '', label: '>' },
                        { to: '/assistance', label: 'Project assistance' },
                        { to: '', label: '>' },
                        { to: '/', label: 'Project page' }
                    ]}
                />
            ) : (
                <div className='template-blog-placeholder'>
                    <p>Home/</p>
                    <p onClick={() => navigate('/assistance')} className='template-link-assistance'>Project group assistance/</p>
                    <p className='name-link'>Project page</p>
                </div>
            )}

            <div className='blog-name'>
                <p className='blog-name-title'>{template?.title}</p>
                {isSmallScreen && renderCategories()}
            </div>

            {renderMediaSections()}

            <Footer />
            <ModalAddTemplate
                isOpen={isModalOpen}
                tempId={selectedTempId}
                tempTitle={selectedTempTitle}
                onClose={handleCloseModal}
            />
            <CreateProjectModal
                isOpen={isModalOpenProject}
                onRequestClose={handleCloseModal}
                template={template}
                onProjectCreated={handleProjectCreation}
            />
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    template: state.templateById.template,
    error: state.templateById.error,
    isFetching: state.templateById.isFetching,
});

const mapDispatchToProps = {
    fetchTemplateById,
    addTemplate
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TemplatePage);
