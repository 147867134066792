import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from "./Page/Home";
import ProjectAssistance from "./Page/Project Assistance/ProjectAssistance";
import AssisCreate from "./Page/Assistance Create Project/AssisCreate";
import Step1 from "./Page/Assistance Step1/Step1";
import Step2 from "./Page/Assistance Step2/Step2";
import Step3 from "./Page/Assistance Step3/Step3";
import ProjectPage from "./Page/Projects/ProjectPage";
import OrderHistory from "./Page/Order History/OrderHistory";
import Users from "./Page/Users/Users";
import TeamManagerPage from "./Page/TeamMeneger/TeamManagerPage";
import ProjectCosts from "./Page/ProjectCosts/ProjectCosts";
import UserRoleRouteMiddleware from "./Middlewares/UserRoleRouteMiddleware";
import SessionPolicy from "./Middlewares/SessionPolicy";
import { AuthPage } from "./Page/AuthPage/AuthPage";
import TemplagePage from "./Page/Assistance Template page/TemplagePage";
import RedirectBasedOnAuth from "./utils/RedirectBasedOnAuth";
import ScrollToTop from "./utils/ScrolToTop";

function App() {
    return (
        <Router>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<SessionPolicy><Home /></SessionPolicy>}>
                    <Route index element={<RedirectBasedOnAuth />} />

                    <Route path="assistance" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <ProjectAssistance />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="assistance/create" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <AssisCreate />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="assistance/step1/:templateId" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <Step1 />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="assistance/step2" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <Step2 />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="assistance/step3" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <Step3 />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="projects" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <ProjectPage />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="order_history" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <OrderHistory />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="users/:projectId" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <Users />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="team" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <TeamManagerPage />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="costs/:projectId" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <ProjectCosts />
                        </UserRoleRouteMiddleware>
                    }/>
                    <Route path="template/:tempId" element={
                        <UserRoleRouteMiddleware redirectPath="/auth/login">
                            <TemplagePage/>
                        </UserRoleRouteMiddleware>
                    }/>

                </Route>
                <Route path="/auth/*" element={<AuthPage />} />
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
        </Router>
    );
}

export default App;
