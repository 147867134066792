import { useDispatch } from 'react-redux';
import { setAuthenticated } from "../../Slice/AuthSlice/AuthSlice";
import {toast, ToastContainer} from "react-toastify";
import {PuffLoader} from "react-spinners";
import {post} from "../../api/APIWraper";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

interface AuthFields {
    email: string;
    password: string;
}

const Login: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [authFields, setAuthFields] = useState<AuthFields>({
        email: "",
        password: ""
    });

    const [isBadEmail, setIsBadEmail] = useState(false);
    const [isBadPassword, setIsBadPassword] = useState(false);
    const [isWaitingForSignIn, setIsWaitingForSignIn] = useState(false);
    const [isInputEmailEmpty, setIsInputEmailEmpty] = useState(true);
    const [isInputPasswordEmpty, setIsInputPasswordEmpty] = useState(true);

    const validRegex = /^(?!\s*$).+/;

    useEffect(() => {
        setIsBadEmail(false);
        if (authFields.email.match(validRegex)) {
            setIsInputEmailEmpty(false);
        } else {
            setIsInputEmailEmpty(true);
        }
    }, [authFields.email]);

    useEffect(() => {
        if (authFields.password.match(validRegex)) {
            setIsInputPasswordEmpty(false);
        } else {
            setIsInputPasswordEmpty(true);
            setIsBadPassword(true);
        }
        setIsBadPassword(false);
    }, [authFields.password]);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthFields({
            ...authFields,
            email: e.target.value
        });
    };

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setAuthFields({
            ...authFields,
            password: e.target.value
        });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        setIsWaitingForSignIn(true);
        e.preventDefault();
        try {
            const userData = {
                email: authFields.email,
                password: authFields.password,
            };

            let isError = false;
            if (!userData.email.match(validRegex)) {
                setIsBadEmail(true);
                toast.error("Invalid email format");
                setIsWaitingForSignIn(false);
                isError = true;
            }
            if (!userData.password.match(validRegex)) {
                setIsBadPassword(true);
                toast.error("Invalid password");
                setIsWaitingForSignIn(false);
                isError = true;
            }

            if (!isError) {
                const response = await post(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/login`, userData);

                if (response.data.token) {
                    localStorage.setItem("Bearer", response.data.token);
                    dispatch(setAuthenticated(true));
                    toast.success("Login successful");
                    setTimeout(() => {
                        navigate('/assistance');
                    }, 3000);
                } else {
                    toast.error("Login failed");
                }
            }

        } catch (error) {
            toast.error("An error occurred");
        } finally {
            setIsWaitingForSignIn(false);
        }
    };

    const handleForgotPassword = () => {
        navigate("/auth/forgot-password");
    };

    const handleRegistration = () => {
        navigate("/auth/register");
    };

    return (
        <div className="layout-login">
            <div className="login-card">
                <div className="login-card-top">
                    <div className="login-card-svg-container">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 24 24"
                             width="24"
                             height="24"
                             fill="white">
                            <path
                                d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/>
                        </svg>
                    </div>
                </div>

                <form className="login-card-form" onSubmit={handleSubmit}>
                    <div>
                        <div className={`login-card-form-input-container ${isBadEmail ? 'red-font-error' : ''}`}>
                            <input
                                className={`login-card-form-input 
                                ${isBadEmail ? 'red-font-error ' : ''}
                                ${isInputEmailEmpty ? '' : 'login-card-form-input-not-empty'}`}
                                type="email"
                                value={authFields.email}
                                onChange={handleEmailChange}
                                placeholder="Email *"
                            />
                            <label className={`login-card-form-label ${isBadEmail ? 'red-font-error' : ''}`}>Email *</label>
                        </div>
                        {isBadEmail && (
                            <p className="login-card-form-input-message">Invalid email</p>
                        )}
                    </div>

                    <div>
                        <div className={`login-card-form-input-container ${isBadPassword ? 'red-font-error' : ''}`}>
                            <input
                                className={`login-card-form-input 
                                ${isBadPassword ? 'red-font-error' : ''}
                                ${isInputPasswordEmpty ? '' : 'login-card-form-input-not-empty'}`}
                                type="password"
                                value={authFields.password}
                                onChange={handlePasswordChange}
                                placeholder="Password *"
                            />
                            <label className={`login-card-form-label ${isBadPassword ? 'red-font-error' : ''}`}>Password *</label>
                        </div>
                        {isBadPassword && (
                            <p className="login-card-form-input-message">Invalid password</p>
                        )}
                    </div>

                    <button
                        className={`login-card-form-button ${isWaitingForSignIn ? 'gray-background' : ''}`}
                        type="submit"
                    >
                        {!isWaitingForSignIn && "SIGN IN"}
                        {isWaitingForSignIn && (
                            <div className="login-loading-spin">
                                <PuffLoader size={25} />
                            </div>
                        )}
                    </button>

                    <div className='auth-block-button'>
                        <button
                            className={`login-card-form-button-forgot ${isWaitingForSignIn ? 'gray-background' : ''}`}
                            type="button"
                            onClick={handleForgotPassword}
                        >
                            FORGOT PASSWORD
                        </button>

                        <button
                            className={`login-card-form-button-registration ${isWaitingForSignIn ? 'gray-background' : ''}`}
                            type="button"
                            onClick={handleRegistration}
                        >
                            REGISTRATION
                        </button>
                    </div>
                </form>
            </div>

            <ToastContainer
                position="bottom-center"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                toastStyle={{
                    backgroundColor: "#333",
                    color: "#fff",
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "16px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                }}
            />
        </div>
    );
};

export default Login;
