import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {buildStyles, CircularProgressbar} from "react-circular-progressbar";
import {connect, ConnectedProps, useDispatch} from 'react-redux';
import './Step2Styles/step2.css';
import store, {RootState} from '../../store';
import {fetchProjectById} from '../../Slice/projectById/projectByIdSlice';
import {clearTemplates, updateLocalTemplate} from "../../Slice/LocalTemplate/LocalTemplateSlice";
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {Project} from "../../types/ProjectTypes";
import {IconSave} from "../../IconComponents/IconComponents";
import {toast} from 'react-toastify';
import ILaborItemType from "../../Interfaces/ProjectInterfaces/IlaborItemType";
import {patch, post} from "../../api/APIWraper";
import ItemTypeListCalculation from "./ItemTypesLists/ItemTypeListCalculation";
import ItemTypeList from "./ItemTypesLists/ItemTypeList";
import parseTemplateToRequestObject from "../../Parsers/TemplateParse/templateToTemplateRequestParser";
import {
    Fragment,
    FragmentData,
    ItemType,
    Template, TMerchanInfo,
    TTemplateRequestObject
} from "../../types/TemplateType";
import LaborItemTypeList from "./LaborItemLists/LaborItemTypeList";
import {clearSelectedItems, setSelectedItems, setSelectedTools} from "../../Slice/SelectedItems/SelectedItemsSlice";
import MaterialName from "../../components/Assis Step2 Component/MaterialName";
import LaborItemTypeListCalculation from "./LaborItemLists/LaborItemTypeListCalculation";

import 'react-toastify/dist/ReactToastify.css';
import {Loader} from "../../components/Loader/Loader";
import IToolsItemType from "../../Interfaces/ProjectInterfaces/IToolsItemType";
import ToolsItemTypeListCalculation from "./ToolsItemLists/ToolsItemTypeListCalculation";
import ToolsItemTypeList from "./ToolsItemLists/ToolsItemTypeList";
import SwitcherStep2 from "../../components/Switcher/SwitcherStep2";
import ShowMoreList from "../../components/ShowMoreListStep2/ShowMoreListStep2";
import  {
    notifyInfoOrderTemplate
} from "../../components/Toast/AssisCreateToastNotification";
import {filterLaborAndToolsItemTypes} from "../../utils/FilterAndSetLaborToolItemTypes";

interface FragmentWithItemTypes extends Fragment {
    data: {
        item_types: ItemType[];
        [key: string]: any;
    };
}

const appUrl = process.env.REACT_APP_BASE_URL_CUSTOMER;

const Step2: React.FC<PropsFromRedux> = ({
                                             project,
                                             fetchProjectById,
                                             updateLocalTemplate,
                                             selectedItem,
                                             selectedLabor,
                                             selectedTools,
                                             selectedItemTotal,
                                             selectedLaborTotal,
                                             selectedToolsTotal,
                                             setSelectedTools
                                         }) => {
    const location = useLocation();
    const { projectId, templIndex } = location.state;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
    const [currentTemplateIndex, setCurrentTemplateIndex] = useState<number>(0);
    const [isSetTemplateIndex, setIsSetTemplateIndex] = useState<boolean>(false);
    const [activeFragments, setActiveFragments] = useState<number[]>([]);
    const [percentage, setPercentage] = useState(0);
    const [updatedProject, setUpdatedProject] = useState<Project | null>(project);
    const [templateIndex, setTemplateIndex] = useState<number>(location.state.templateIndex || templIndex);
    const [showTemplateDropdown, setShowTemplateDropdown] = useState<boolean>(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const [isLaborActive, setIsLaborActive] = useState<boolean>(false);
    const [isToolsActive, setIsToolsActive] = useState<boolean>(false);

    const [relatedProjectItemTypes, setRelatedProjectItemTypes] = useState<ItemType[]>([]);
    const [laborItemTypes, setLaborItemTypes] = useState<ILaborItemType[]>([]);
    const [toolsItemTypes, setToolsItemTypes] = useState<IToolsItemType[]>([]);

    const [hasFetchedItems, setHasFetchedItems] = useState<boolean>(false);

    const [totalLabor, setTotalLabor] = useState<number>(0);
    const [totalTools, setTotalTools] = useState<number>(0);
    const [totalMaterialPriceItem, setTotalMaterialPriceItem] = useState<number>(0);

    const [viewMode, setViewMode] = useState<'fragments' | 'types'>('types');
    const [viewModeTools, setViewModeTools] = useState<'Recommended' | 'Minimum'>('Recommended');

    const [showAll, setShowAll] = useState(false);
    const [isButtonDisabledOrderTemplate, setIsButtonDisabledOrderTemplate] = useState(false);
    const [isButtonDisabledOrderSelected, setIsButtonDisabledOrderSelected] = useState(false);


    const [triggerAutoAdd, setTriggerAutoAdd] = useState(false);

    const fetchRelatedProjectItemTypes = useCallback(async (template: TTemplateRequestObject) => {
        const itemTypeIds = template.data.fragments
            .flatMap((fragment: { data: { item_types: any; }; }) => fragment.data.item_types)
            .map((itemType: { id: any; }) => itemType.id);

        if (itemTypeIds.length > 0) {
            try {
                const response = await post(`${appUrl}/item_types_all`, {
                    item_type_ids: itemTypeIds
                });
                setRelatedProjectItemTypes(response.data.data);
            } catch (error) {
                toast.error("Failed to fetch item types.");
            }
        }
    }, []);
    const autoAddItemsToItemTypes = useCallback(async () => {
        if (!project || !currentTemplate) return;

        let itemsExist = false;
        currentTemplate.data.fragments.forEach((fragment: { data: { item_types: any[]; }; }) => {
            fragment.data.item_types.forEach((itemType) => {
                if (itemType.items && itemType.items.length > 0) {
                    itemsExist = true;
                }
            });
        });
        if (itemsExist) return;

        const requests: any[] = [];
        currentTemplate.data.fragments.forEach((fragment: { data: { item_types: any[]; }; id: any; }, fragmentIndex: any) => {
            fragment.data.item_types.forEach((itemType, itemTypeIndex) => {
                const relatedItemType = relatedProjectItemTypes.find(relatedItem => relatedItem.id === itemType.id);
                if (relatedItemType && relatedItemType.items.length === 1) {
                    const item = relatedItemType.items[0];
                    requests.push({
                        item_type_id: itemType.id,
                        item_ids: [{ id: item.id, qty: itemType.qty }],
                        template_index: currentTemplateIndex,
                        fragment_index: fragmentIndex,
                        item_type_index: itemTypeIndex,
                        template_id: currentTemplate.id,
                        fragment_id: fragment.id,
                    });
                }
            });
        });

        if (requests.length > 0) {
            try {
                const response = await patch(`${appUrl}/projects/${project.id}/add_item`, { data: requests });
                if (response.status === 200 || response.status === 201) {
                    fetchProjectById(parseInt(projectId))
                }
            } catch (error) {
                toast.error("Failed to add items.");
            }
        }
    }, [currentTemplateIndex,currentTemplate]);


    const filterAndSetLaborItemTypes = useCallback(() => {
        if (currentTemplate) {
            const { laborItemTypes, toolsItemTypes } = filterLaborAndToolsItemTypes(currentTemplate, currentTemplateIndex);
            setLaborItemTypes(laborItemTypes || []);
            setToolsItemTypes(toolsItemTypes || []);
        }
    }, [currentTemplate, currentTemplateIndex]);


    useEffect(() => {
        const checkIfButtonShouldBeDisabled = () => {
            const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
            const hasSelections = Object.keys(storedSelectedFragments).some(fragmentKey => {
                const fragment = storedSelectedFragments[fragmentKey];
                return (
                    (fragment.items && Object.keys(fragment.items).length > 0) ||
                    (fragment.LaborItemTypes && Object.keys(fragment.LaborItemTypes).length > 0) ||
                    (fragment.ToolsItemTypes && Object.keys(fragment.ToolsItemTypes).length > 0)
                );
            });
            setIsButtonDisabledOrderSelected(!hasSelections);
        };

        checkIfButtonShouldBeDisabled();
        const unsubscribeFromSelectedItem = store.subscribe(() => {
            checkIfButtonShouldBeDisabled();
        });
        return () => {
            unsubscribeFromSelectedItem();
        };
    }, [selectedItem, selectedLabor, selectedTools, selectedItemTotal, selectedLaborTotal, selectedToolsTotal]);

    useEffect(() => {
        const checkIfButtonShouldBeDisabled = () => {
            const hasItemsInFragments = currentTemplate?.data.fragments.some((fragment: { data: { item_types: any[]; }; }) =>
                fragment.data.item_types.some((itemType) => itemType.items && itemType.items.length > 0)
            );

            const hasLaborItems = laborItemTypes.some((labor) =>
                labor.item_type.items && labor.item_type.items.length > 0
            );

            const hasToolItems = toolsItemTypes.some((tool) =>
                tool.item_type.items && tool.item_type.items.length > 0
            );

            setIsButtonDisabledOrderTemplate(!(hasItemsInFragments || hasLaborItems || hasToolItems));
        };

        checkIfButtonShouldBeDisabled();
    }, [currentTemplate, laborItemTypes, toolsItemTypes]);

    useEffect(() => {
        if (currentTemplate) {
            const hasItem = currentTemplate.data.fragments.some((fragment: { data: { item_types: ItemType[]; }; }) =>
                fragment.data.item_types.some(itemType => itemType.item)
            );
            setIsButtonDisabledOrderTemplate(!hasItem);
        }
    }, [currentTemplate]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setShowTemplateDropdown(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    useEffect(() => {
        const index = localStorage.getItem('templateIndex');
        if (index !== null) {
            setTemplateIndex(Number(index));
        }
    }, []);

    useEffect(() => {
        if (projectId) {
            fetchProjectById(parseInt(projectId));
        }
    }, []);

    useEffect(() => {
        if (project?.data?.templates) {
            const selectedTemplate = project.data.templates[templateIndex] || project.data.templates[0];
            setCurrentTemplate(selectedTemplate);
            setCurrentTemplateIndex(templateIndex);
            localStorage.setItem('templateIndex', String(templateIndex));
            setIsSetTemplateIndex(true);
            setPercentage(getProjectProgress(project));
        }
    }, [project]);


    useEffect(() => {
        if (currentTemplate) {
            const parsedTemplate = parseTemplateToRequestObject(currentTemplate);
            setTimeout(() => {
                fetchRelatedProjectItemTypes(parsedTemplate).then(() => {
                    autoAddItemsToItemTypes();
                    filterAndSetLaborItemTypes()
                });
            }, 100);
        }
    }, [currentTemplateIndex, currentTemplate]);


    useEffect(() => {
        if (triggerAutoAdd) {
            autoAddItemsToItemTypes();
            setTriggerAutoAdd(false);
        }
    }, [triggerAutoAdd]);

    useEffect(() => {
        if (project && currentTemplate) {
            setTriggerAutoAdd(true);
        }
    }, [currentTemplate, currentTemplateIndex, fetchProjectById]);

    useEffect(() => {
        setTriggerAutoAdd(false);
    }, [currentTemplateIndex]);

    useEffect(() => {
        const savedProject = localStorage.getItem('updatedProject');
        if (savedProject) {
            setUpdatedProject(JSON.parse(savedProject));
        }
    }, []);

    useEffect(() => {
        if (updatedProject) {
            localStorage.setItem('updatedProject', JSON.stringify(updatedProject));
        }
    }, [updatedProject]);

    useEffect(() => {
        const toolsInitialized = localStorage.getItem('ToolsItemTypesInitialized');
        if (!toolsInitialized) {
            const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
            if (toolsItemTypes.length > 0) {
                toolsItemTypes.forEach(item => {
                    if (item.item_type.items && item.item_type.items.length > 0) {
                        const fragmentKey = `${item.fragment_id}-${item.fragment_index}-${item.template_index}`;
                        if (!storedSelectedFragments[fragmentKey]) {
                            storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
                        }

                        const toolKey = `${item.item_type_id}-${item.clone_id || item.item_type_index}-${item.fragment_index}`;

                        storedSelectedFragments[fragmentKey].ToolsItemTypes[toolKey] = {
                            id: item.item_type.id,
                            qty: item.qty,
                            tool: true,
                            items: item.item_type.items.map(i => ({
                                id: i.id,
                                qty: i.qty,
                                unit: i.unit,
                                price: i.price,
                                title: i.title,
                                ordered_qty: i.ordered_qty,
                                payload: {
                                    template_index: item.template_index,
                                    fragment_index: item.fragment_index,
                                    item_type_index: item.item_type_index,
                                    template_id: item.template_id,
                                    fragment_id: item.fragment_id,
                                    item_type_id: item.item_type.id,
                                    item_ids: item.item_type.items.map(i => i.id) || [],
                                }
                            })),
                            labor: false,
                            title: item.item_type.title,
                            percentage: item.item_type.percentage,
                            ordered_qty: item.item_type.items.reduce((sum, toolItem) => sum + (toolItem.ordered_qty || 0), 0) || 0,
                            originalIndex: item.item_type_index,
                            clone_id: item.clone_id || item.item_type_index,
                            payload: {
                                template_index: item.template_index,
                                fragment_index: item.fragment_index,
                                item_type_index: item.item_type_index,
                                template_id: item.template_id,
                                fragment_id: item.fragment_id,
                                item_type_id: item.item_type.id,
                                item_ids: item.item_type.items.map(i => i.id) || [],
                            }
                        };
                    }
                });

                localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
                localStorage.setItem('ToolsItemTypesInitialized', 'true');
                setSelectedTools(Date.now());
                calculateTotalTools(storedSelectedFragments);
            }
        }
    }, [toolsItemTypes]);

    useEffect(() => {
        let itemsExist = false;
        if (currentTemplate) {
            currentTemplate.data.fragments.forEach((fragment: { data: { item_types: any[]; }; }) => {
                fragment.data.item_types.forEach((itemType) => {
                    if (itemType.items && itemType.items.length > 0) {
                        itemsExist = true;
                    }
                });
            });
        }
        setIsButtonDisabledOrderTemplate(!itemsExist);
    }, [currentTemplate, currentTemplateIndex, fetchProjectById, updatedProject]);


    const calculateTotalLabor = (selectedFragments: { [key: string]: any }) => {
        let total = 0;
        Object.keys(selectedFragments).forEach(fragmentKey => {
            const laborItems = selectedFragments[fragmentKey].LaborItemTypes || {};

            Object.keys(laborItems).forEach(key => {
                const laborItem = laborItems[key];

                if (laborItem && laborItem.items) {
                    laborItem.items.forEach((item: { price: any; qty: any; ordered_qty: any }) => {
                        const price = parseFloat(String(item.price));
                        const quantity = item.qty;
                        const orderedQty = item.ordered_qty || 0;
                        const totalLabor = (quantity - orderedQty) * price;
                        total += totalLabor;
                    });
                }
            });
        });

        setTotalLabor(Math.max(0, total));
    };


    const calculateTotalTools = (selectedFragments: { [key: string]: any }) => {
        let total = 0;

        Object.keys(selectedFragments).forEach(fragmentKey => {
            const toolsItems = selectedFragments[fragmentKey].ToolsItemTypes || {};

            Object.keys(toolsItems).forEach(key => {
                const toolsItem = toolsItems[key];

                if (toolsItem && toolsItem.items) {
                    toolsItem.items.forEach((item: { price: any; qty: any; ordered_qty: any }) => {
                        const price = parseFloat(String(item.price));
                        const quantity = item.qty;
                        const orderedQty = item.ordered_qty || 0;
                        const totalTools = (quantity - orderedQty) * price;
                        total += totalTools;
                    });
                }
            });
        });

        setTotalTools(Math.max(0, total));
    };

    const calculateTotalMaterial = (selectedFragments: { [key: string]: any }) => {
        let total = 0;

        Object.keys(selectedFragments).forEach(fragmentKey => {
            const items = selectedFragments[fragmentKey].items || {};

            Object.keys(items).forEach(key => {
                const item = items[key];

                if (item && item.items) {
                    item.items.forEach((subItem: { price: any; qty: any; ordered_qty: any }) => {
                        const price = parseFloat(String(subItem.price));
                        const quantity = subItem.qty;
                        const orderedQty = subItem.ordered_qty || 0;
                        const totalItem = (quantity - orderedQty) * price;
                        total += totalItem;
                    });
                }
            });
        });

        setTotalMaterialPriceItem(Math.max(0, total));
    };


    useEffect(() => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        calculateTotalMaterial(storedSelectedFragments);
        calculateTotalLabor(storedSelectedFragments);
        calculateTotalTools(storedSelectedFragments);
    }, [selectedItem, selectedLabor, selectedTools, selectedItemTotal, selectedLaborTotal, selectedToolsTotal]);

    const navigateToStep1Pen = () => {
        if (currentTemplate) {
            updateLocalTemplate(currentTemplate);
            localStorage.setItem(`selectedTemplate-${currentTemplate.id}`, JSON.stringify(currentTemplate));
        }
        setTimeout(() => {
            navigate(`/assistance/step1/${currentTemplate?.id}`, { state: { templateId: currentTemplate?.id, from: 'Step2', projectId: projectId, templIndex: currentTemplateIndex } });
        }, 1000);
    };

    const getProjectProgress = (project: Project): number => {
        const summaryItemTypesProject = project?.data?.summary_item_types_project || [];
        let percent= 0
        if (summaryItemTypesProject) {
            summaryItemTypesProject.map((ItemType) => {
                if (ItemType.percentage) percent += ItemType.percentage;
            })
            percent /= summaryItemTypesProject.length;
        }
        return Math.ceil((summaryItemTypesProject.length && !isNaN(percent)) ? Math.round(percent) / summaryItemTypesProject.length : 0);
    };

    const navigateToAssistance = () => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
        navigate('/assistance');
    };

    const handleProjectUpdate = (newProject: Project) => {
        setUpdatedProject(newProject);
        fetchProjectById(parseInt(projectId))
    };

    const handleToggle = (fragmentIndex: number) => {
        setActiveFragments(prev => {
            if (prev.includes(fragmentIndex)) {
                return prev.filter(index => index !== fragmentIndex);
            } else {
                return [...prev, fragmentIndex];
            }
        });
    };

    const handleToggleLabor = () => {
        setIsLaborActive((prev) => !prev);
    };

    const handleToggleTools = () => {
        setIsToolsActive((prev) => !prev);
    };

    const handleSwitchChange = (selectedOption: 'fragments' | 'types') => {
        setViewMode(selectedOption);
    };
    const handleSwitchChangeTools = (selectedOption: 'Recommended' | 'Minimum') => {
        setViewModeTools(selectedOption);
    };

    const handleTemplateSelect = (selectedTemplateIndex: number) => {
        const selectedTemplate = project?.data.templates?.[selectedTemplateIndex] || null;
        setCurrentTemplate(selectedTemplate);
        setTemplateIndex(selectedTemplateIndex);
        setCurrentTemplateIndex(selectedTemplateIndex);
        localStorage.setItem('templateIndex', selectedTemplateIndex.toString());
        setShowTemplateDropdown(false);
        setHasFetchedItems(false);
    };

    const renderTemplateOptions = () => {
        const templateTitles = project?.data.templates?.map(template => template.title) || [];
        const templateTitleCounts = templateTitles.reduce((acc, title) => {
            if (!acc[title]) {
                acc[title] = 0;
            }
            acc[title] += 1;
            return acc;
        }, {} as { [key: string]: number });

        const templateTitleIndices = {} as { [key: string]: number };

        return project?.data.templates?.map((template, index) => {
            const title = template.title;
            if (!templateTitleIndices[title]) {
                templateTitleIndices[title] = 1;
            } else {
                templateTitleIndices[title] += 1;
            }
            const displayTitle = templateTitleCounts[title] > 1 ? `${title} (${templateTitleIndices[title]})` : title;

            return (
                <div
                    key={template.id}
                    onClick={() => handleTemplateSelect(index)}
                    className='step-template-container-option'
                >
                    {displayTitle}
                </div>
            );
        });
    };
    if (!updatedProject) {
        return <Loader />;
    }

    if (!currentTemplate) {
        return <Loader />;
    }
    const descriptionText = currentTemplate.short_description || 'No description';

    const handleOrderSelected = () => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const hasSelections = Object.keys(storedSelectedFragments).some(fragmentKey => {
            const fragment = storedSelectedFragments[fragmentKey];
            return (
                (fragment.items && Object.keys(fragment.items).length > 0) ||
                (fragment.LaborItemTypes && Object.keys(fragment.LaborItemTypes).length > 0) ||
                (fragment.ToolsItemTypes && Object.keys(fragment.ToolsItemTypes).length > 0)
            );
        });

        if (!hasSelections) {
            notifyInfoOrderTemplate('You must add at least one product, tool, or labor.');
            return;
        }
        navigate(`/assistance/step3`, { state: { projectId: projectId, templateIndex: templateIndex } });
    };


    const handleOrderTemplate = () => {
        if (isButtonDisabledOrderTemplate) {
            notifyInfoOrderTemplate('You must choose an product, tool, or labor');
            return;
        }
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        if (!currentTemplate) {
            return;
        }

        currentTemplate.data.fragments.forEach((fragment: { id: any; data: { item_types: any[]; }; }, fragmentIndex: any) => {
            const fragmentKey = `${fragment.id}-${fragmentIndex}-${currentTemplateIndex}`;
            if (!storedSelectedFragments[fragmentKey]) {
                storedSelectedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
            }

            const addItemType = (itemType: { id: any; qty: any; title: any; items: any[]; }, itemTypeIndex: number, category: string) => {
                const itemKey = `${itemType.id}-${itemTypeIndex}-${fragmentIndex}`;

                if (!storedSelectedFragments[fragmentKey][category]) {
                    storedSelectedFragments[fragmentKey][category] = {};
                }
                storedSelectedFragments[fragmentKey][category][itemKey] = {
                    id: itemType.id,
                    qty: itemType.qty,
                    tool: category === 'ToolsItemTypes',
                    labor: category === 'LaborItemTypes',
                    item_type_title: itemType.title,
                    items: itemType.items ? itemType.items.map(i => ({
                        id: i.id,
                        qty: i.qty,
                        unit: i.unit,
                        price: i.price,
                        title: i.title,
                        ordered_qty: i.ordered_qty,
                        item_type_title: itemType.title,
                        vendor: i.vendor,
                        merchant_info:i.merchant_info as TMerchanInfo,
                        payload: {
                            template_index: currentTemplateIndex,
                            fragment_index: fragmentIndex,
                            item_type_index: itemTypeIndex,
                            template_id: currentTemplate.id,
                            fragment_id: fragment.id,
                            item_type_id: itemType.id,
                            item_ids: i.id ? [i.id] : [],
                        }
                    })) : [],
                    title: itemType.title,
                    ordered_qty: itemType.items ? itemType.items.reduce((sum, item) => sum + (item.ordered_qty || 0), 0) : 0,
                    originalIndex: itemTypeIndex,
                    clone_id: itemTypeIndex,
                    payload: {
                        template_index: currentTemplateIndex,
                        fragment_index: fragmentIndex,
                        item_type_index: itemTypeIndex,
                        template_id: currentTemplate.id,
                        fragment_id: fragment.id,
                        item_type_id: itemType.id,
                        item_ids: itemType.items ? itemType.items.map(i => i.id) : [],
                    }
                };
            };
            fragment.data.item_types.forEach((itemType, itemTypeIndex) => {
                if (itemType.items && itemType.items.length > 0) {
                    if (!itemType.tool && !itemType.labor) {
                        addItemType(itemType, itemTypeIndex, 'items');
                    } else if (itemType.labor) {
                        addItemType(itemType, itemTypeIndex, 'LaborItemTypes');
                    } else if (itemType.tool) {
                        addItemType(itemType, itemTypeIndex, 'ToolsItemTypes');
                    }
                }
            });
        });
        localStorage.setItem('selectedFragments', JSON.stringify(storedSelectedFragments));
        navigate(`/assistance/step3`, { state: { projectId: projectId, templateIndex: templateIndex } });
    };

    const renderQuestionAnswers = (data: { [key: string]: FragmentData }, hiddenKeys: Set<string>) => {
        return Object.entries(data)
            .filter(([key, _]) => !isNaN(parseInt(key)))
            .filter(([_, dataItem]) => dataItem.question && !hiddenKeys.has(dataItem.key))
            .map(([key, dataItem]) => (
                <div className="step-group-calc" key={`dataItem-${currentTemplate.id}-${key}`}>
                    <div className='step2-calc-field-container'>
                        <label className="step-group-calc-label">{dataItem.question}</label>
                        <div className='step2-calc-field'>
                            <p>{dataItem.value}</p>
                        </div>
                    </div>
                </div>
            ));
    };

    const renderFragments = (fragments: Fragment[]) => {
        return fragments.map((fragment, fragmentIndex) => {
            const hiddenKeys = new Set<string>();
            const varMapping = fragment.data?.var_mapping as unknown as string | undefined;
            if (typeof varMapping === 'string') {
                const mappings = varMapping.split('&&').map((map: string) => map.trim());
                mappings.forEach((mapping: string) => {
                    const [left, right] = mapping.split('=').map((s: string) => s.trim());
                    hiddenKeys.add(right);
                });
            }
            const questionAnswers = renderQuestionAnswers(fragment.data, hiddenKeys);
            return (
                <div className='step2-fragment' key={`fragment-${fragmentIndex}`}>
                    {questionAnswers.length > 0 && <h3>{fragment.title}</h3>}
                    {questionAnswers}
                </div>
            );
        });
    };

    const questionAnswers = currentTemplate?.data ? renderQuestionAnswers(currentTemplate.data, new Set<string>()) : [];
    const fragments = currentTemplate?.data.fragments ? renderFragments(currentTemplate.data.fragments) : [];

    return (
        <div className='step2'>
            {isSetTemplateIndex && (
                <div>
                    <div className='template-name-content'>
                        <div className='template-blog-placeholder'>
                            <p>Home/</p>
                            <p onClick={navigateToAssistance} className='template-link-assistance'>Project group assistance</p>
                            <p className='name-link'>/Create new project space</p>
                        </div>
                    </div>
                    <div className='step-info'>
                        <div className='step-info-block'>
                            <h3>Step 2</h3>
                            <span>/</span>
                            <p className='step-info-block-name'>Specify parameters of building</p>
                        </div>
                    </div>
                    <div className='step-template-container'>
                        <div className='step-template-block left'>
                            <h2>Type:</h2>
                            <div className='step-template-container'>
                                <div className='step-template-dropdown-container'>
                                    {project?.data?.templates && project.data.templates.length > 1 && (
                                        <>
                                            <button
                                                className={`step-template-block-title-dropdown ${showTemplateDropdown ? 'active' : ''}`}
                                                onClick={() => setShowTemplateDropdown(!showTemplateDropdown)}
                                            >
                                                {currentTemplate.title || 'Select template'}
                                            </button>
                                            {showTemplateDropdown && (
                                                <section className='step-template-dropdown'>
                                                    {renderTemplateOptions()}
                                                </section>
                                            )}
                                        </>
                                    )}
                                    {project?.data?.templates && project.data.templates.length === 1 && (
                                        <div className='step-template-block-title'>
                                            {currentTemplate.title || 'Select template'}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div className='step-template-block right'>
                            <h2>Description:</h2>
                            <p className='step-template-block-desc'>{descriptionText}</p>
                        </div>
                        <div className='step-template-block-percent'>
                            <div className='progress-bar-wrapper'>
                                <CircularProgressbar
                                    value={percentage}
                                    styles={buildStyles({
                                        pathColor: '#E30613',
                                        textColor: '#000000',
                                        trailColor: '#d6d6d6',
                                        textSize: '24px',
                                        pathTransitionDuration: 0.5,
                                    })}
                                />
                                <div className='progress-bar-text'>
                                    {percentage}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="step-calc">
                        <h2>Required Information:</h2>
                        <div onClick={navigateToStep1Pen} className='step-pen'>
                            <button>Add Dimensions</button>
                        </div>
                        <div className='step-form-calc-block' key={`template-${currentTemplate.id}`}>
                            <div className='step2-question-answer'>
                                <h3>{currentTemplate.title}</h3>
                                <div className='step-form-calc-block' key={`template-${currentTemplate.id}`}>
                                    <ShowMoreList items={[...questionAnswers, ...fragments]} initialVisibleCount={6} showAll={showAll} />
                                    <button className='show-more-list-button' onClick={() => setShowAll(!showAll)}>
                                        {showAll ? 'Hide' : 'Show more...'}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='step-calc'>
                        <h2>Costs Summary</h2>
                        <SwitcherStep2
                            options={['fragments', 'types']}
                            onChange={handleSwitchChange}
                        />
                        <div className='step2-container'>
                            {currentTemplate.data.fragments.map((fragment: FragmentWithItemTypes, fragmentIndex: number) => (
                                <div key={`fragment-${fragment.id}-${fragmentIndex}`} className='step2-fragment'>
                                    <div className='step2-fragment-block'>
                                        <MaterialName
                                            key={`material-${fragment.id}`}
                                            fragment={fragment}
                                            project={updatedProject}
                                            onToggle={() => handleToggle(fragmentIndex)}
                                            showSettingsButton={true}
                                        />
                                        {activeFragments.includes(fragmentIndex) ? (
                                            <ItemTypeListCalculation
                                                fragment={fragment}
                                                fragmentIndex={fragmentIndex}
                                                templateIndex={currentTemplateIndex}
                                                project={updatedProject}
                                                templateId={currentTemplate.id}
                                                onUpdateProject={handleProjectUpdate}
                                                onToggle={() => handleToggle(fragmentIndex)}
                                                viewMode={viewMode}
                                            />
                                        ) : (
                                            <ItemTypeList
                                                templateId={currentTemplate.id}
                                                fragment={fragment}
                                                fragmentIndex={fragmentIndex}
                                                templateIndex={currentTemplateIndex}
                                                viewMode={viewMode}
                                            />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    {viewMode === 'types' && (
                        <div className='step-calc'>
                            <div className='step2-container'>
                                {laborItemTypes && laborItemTypes.length > 0 && (
                                    <div key={`fragment-labor-`} className='step2-fragment'>
                                        <div className='step2-fragment-block'>
                                            <MaterialName
                                                key={`material-labor`}
                                                project={updatedProject}
                                                onToggle={() => handleToggleLabor()}
                                                showSettingsButton={true}
                                                isLabor={true}
                                            />
                                            {isLaborActive ? (
                                                <LaborItemTypeListCalculation
                                                    laborItemTypes={laborItemTypes || []}
                                                    project={updatedProject}
                                                    templateId={currentTemplate.id}
                                                    templateIndex={currentTemplateIndex}
                                                    onUpdateProject={handleProjectUpdate}
                                                    relatedProjectItemTypes={relatedProjectItemTypes}
                                                    onToggle={() => handleToggleLabor()}
                                                />
                                            ) : (
                                                <LaborItemTypeList laborItemTypes={laborItemTypes || []}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                {toolsItemTypes && toolsItemTypes.length > 0 && (
                                    <div key={`fragment-labor-`} className='step2-fragment'>
                                        <div className='step2-fragment-block'>
                                            <MaterialName
                                                key={`material-labor`}
                                                project={updatedProject}
                                                onToggle={() => handleToggleTools()}
                                                showSettingsButton={true}
                                                isToolStep2={true}
                                                handleSwitchChangeTools={handleSwitchChangeTools}
                                            />

                                            {isToolsActive ? (
                                                <ToolsItemTypeListCalculation
                                                    toolsItemTypes={toolsItemTypes || []}
                                                    project={updatedProject}
                                                    templateId={currentTemplate.id}
                                                    templateIndex={currentTemplateIndex}
                                                    onUpdateProject={handleProjectUpdate}
                                                    relatedProjectItemTypes={relatedProjectItemTypes}
                                                    onToggle={() => handleToggleTools()}
                                                    viewModeTools={viewModeTools}/>
                                            ) : (
                                                <ToolsItemTypeList
                                                    toolsItemTypes={toolsItemTypes || []}
                                                    viewModeTools={viewModeTools}/>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}

                    <div className='step3-bottom-total'>
                        <p>Total:</p>
                        <div className='step3-bottom-total-container'>
                            <div className='step3-bottom-total-price'>
                                <div className='step3-bottom-total-price-block'>
                                    <p>Total labour:</p>
                                    <span className='step3-bottom-price'>R {Math.max(0,totalLabor).toFixed(2)}</span>
                                </div>
                                <div className='step3-bottom-total-price-block'>
                                    <p>Total tools:</p>
                                    <span className='step3-bottom-price'>R {Math.max(0,totalTools).toFixed(2)}</span>
                                </div>
                                <div className='step3-bottom-total-price-block'>
                                    <p>Total material:</p>
                                    <span className='step3-bottom-price'>R {Math.max(0,totalMaterialPriceItem).toFixed(2)}</span>
                                </div>
                            </div>
                            <div className='step3-bottom-total-price-block'>
                                <p>Total payable:</p>
                                <span
                                    className='step3-bottom-price-total'>R {(totalLabor + totalMaterialPriceItem + totalTools).toFixed(2)}</span>
                            </div>
                        </div>
                    </div>

                    <div className='step2-bottom-btn'>
                        <div className='step2-bottom-btn-block'>
                            <div className='step-btn'>
                                <button
                                    className='step2-bottom-block-btn'
                                    onClick={handleOrderSelected}
                                    style={{
                                        backgroundColor: isButtonDisabledOrderSelected ? '#cccccc' : '#E30613',
                                        cursor: isButtonDisabledOrderSelected ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    Order selected
                                </button>
                            </div>
                            <div className='step-btn'>
                                <button
                                    className='step2-bottom-block-btn'
                                    onClick={handleOrderTemplate}
                                    style={{
                                        backgroundColor: isButtonDisabledOrderTemplate ? '#cccccc' : '#E30613',
                                        cursor: isButtonDisabledOrderTemplate ? 'not-allowed' : 'pointer'
                                    }}
                                >
                                    Order Project
                                </button>

                            </div>
                            <div className='step-btn'>
                                <button className='step2-bottom-block-btn'>Save & Request finance</button>
                            </div>
                        </div>
                    </div>

                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
    isFetching: state.projectById.isFetching,
    selectedItem: state.selectedItems.selectedFragments,
    selectedLabor: state.selectedItems.selectedLabor,
    selectedTools: state.selectedItems.selectedTools,
    selectedItemTotal: state.selectedItems.selectedFragmentsTotal,
    selectedLaborTotal: state.selectedItems.selectedLaborTotal,
    selectedToolsTotal: state.selectedItems.selectedToolsTotal
});

const mapDispatchToProps = {
    fetchProjectById,
    updateLocalTemplate,
    clearSelectedItems,
    setSelectedItems,
    setSelectedTools,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step2);
