import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { postCreateProject } from "../../api/APIWraper";
import { Project } from "../../types/ProjectTypes";

interface ProjectsState {
    project: Project[];
    isFetching: boolean;
    error: string | null;
}

const initialState: ProjectsState = {
    project: [],
    isFetching: false,
    error: null,
};

export const createProject = createAsyncThunk<Project, { title: string; storeId: number | null; target_date: string }>(
    'projects/createProject',
    async ({ title, storeId, target_date }) => {
        const response = await postCreateProject({ title, storeId, target_date });
        return response.data;
    }
);

const projectsSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(createProject.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(createProject.fulfilled, (state, action: PayloadAction<Project>) => {
                state.isFetching = false;
                state.project.push(action.payload);
            })
            .addCase(createProject.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default projectsSlice.reducer;
