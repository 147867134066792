// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 1000;
}

.warning-modal{
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 5px;
    width: 550px;
    height: 110px;
}
.warning{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.warning>p{
    color: black;
    font-size: 18px;
    text-align: center;
}

.confirmation-buttons{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.confirm-button,
.cancel-button{
    padding: 5px 35px;
    border: none;
    border-radius: 5px;
    background-color: #c2c2c2;
    cursor: pointer;
}
.confirm-button:hover{
    background-color: #de8181;
}
.cancel-button:hover{
    background-color: #61d759;
}`, "",{"version":3,"sources":["webpack://./src/components/Modals/ModalDelete/ModalDelete.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,qCAAqC;IACrC,aAAa;AACjB;;AAEA;IACI,0CAA0C;IAC1C,kBAAkB;IAClB,YAAY;IACZ,aAAa;AACjB;AACA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,6BAA6B;AACjC;;AAEA;;IAEI,iBAAiB;IACjB,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;AACnB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":[".overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: rgba(0, 0, 0, 0.75);\n    z-index: 1000;\n}\n\n.warning-modal{\n    background-color: rgba(255, 255, 255, 0.9);\n    border-radius: 5px;\n    width: 550px;\n    height: 110px;\n}\n.warning{\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n}\n.warning>p{\n    color: black;\n    font-size: 18px;\n    text-align: center;\n}\n\n.confirmation-buttons{\n    width: 100%;\n    display: flex;\n    justify-content: space-evenly;\n}\n\n.confirm-button,\n.cancel-button{\n    padding: 5px 35px;\n    border: none;\n    border-radius: 5px;\n    background-color: #c2c2c2;\n    cursor: pointer;\n}\n.confirm-button:hover{\n    background-color: #de8181;\n}\n.cancel-button:hover{\n    background-color: #61d759;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
