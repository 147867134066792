import React from "react";
import { Fragment } from "../../types/TemplateType";
import { Project } from "../../types/ProjectTypes";
import './step2HeaderList.css';
import  "../../Page/Assistance Step2/Step2Styles/step2.css"
import "../../Page/Assistance Step2/Step2Styles/step2.css";
import HeaderList from "./HeaderList";
import ProgressBarFragment from "../ProgressBar/ProgressBarFragment";
import SwitcherTools from "../Switcher/SwitcherTools";

interface MaterialNameProps {
    fragment?: Fragment;
    project: Project;
    onToggle?: () => void;
    showSettingsButton?: boolean;
    isLabor?: boolean;
    isTool?: boolean;
    isToolStep2?: boolean;
    handleSwitchChangeTools?: (value: 'Recommended' | 'Minimum') => void;
}

const MaterialName: React.FC<MaterialNameProps> = ({ fragment, onToggle, showSettingsButton , isLabor, isTool, isToolStep2, handleSwitchChangeTools }) => {
    return (
        <>
            <div className='new-step2-material-list '>
                <div className='new-step2-material-list-name'>
                    {isLabor &&
                        <p className='project-item-update-material-list-name-name'>Labor required for task (optional)</p>
                    }
                    {isTool &&
                        <p className='project-item-update-material-list-name-name'>Tools required for task (optional)</p>
                    }
                    {isToolStep2 &&
                        <>
                            <p className='project-item-update-material-list-name-name'>Tools required for task (optional)</p>
                            <SwitcherTools
                                options={['Recommended', 'Minimum']}
                                onChange={handleSwitchChangeTools || (() => {})}
                            />
                        </>
                    }

                    {fragment &&
                        <p className='new-step2-material-list-name-name'>{fragment.title}</p>
                    }
                    {fragment &&
                        <div className='new-step2-progress'>
                            <ProgressBarFragment
                                fragment={fragment}
                            />
                        </div>
                    }
                </div>
                {showSettingsButton && (
                    <div>
                        <button className='material-setting' onClick={onToggle}>
                            {isTool && 'Select Tools'}
                            {isLabor && 'Select Labor'}
                            {!isTool && !isLabor && 'Select Products'}
                        </button>
                    </div>
                )}
            </div>
            <HeaderList showSettingsButton={showSettingsButton} isTool={isTool} isLabor={isLabor} />
        </>
    );
};

export default MaterialName;
