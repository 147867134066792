import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './style.css';
import { fetchAllProjects } from "../../Slice/getProjectAssistance/ProjectAssistanceSlice";
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import PaginationAssistance from "../PaginationAssistance/pagination";
import CreateProjectModal from "../Modals/ModalCreateProject/ModalCreateProject";
import { IconCreateProject } from "../../IconComponents/IconComponents";

const PROJECTS_PER_PAGE = 11;

interface AllProjectsProps {
    setIsLoading: (loading: boolean) => void,
    isLoading: boolean,
}

const AllProjects: React.FC<PropsFromRedux & AllProjectsProps> = ({
                                                                      allProjects, isFetching, error, fetchAllProjects, setIsLoading, isLoading
                                                                  }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const [currentProjectPage, setCurrentProjectPage] = useState(1);

    useEffect(() => {
        setIsLoading(isFetching);
    }, [isFetching]);

    useEffect(() => {
        fetchAllProjects();
    }, [fetchAllProjects]);

    const handleOpenModal = () => setIsModalOpen(true);
    const handleCloseModal = () => setIsModalOpen(false);

    if (error) return <p>Error: {error}</p>;

    const indexOfLastProject = currentProjectPage * PROJECTS_PER_PAGE;
    const indexOfFirstProject = indexOfLastProject - PROJECTS_PER_PAGE;
    const currentProjects = allProjects.slice(indexOfFirstProject, indexOfLastProject);

    return (
        <>
            {!isLoading && (
                <div>
                    <div className='assistance-name-content'>
                        <p>Recent projects group</p>
                        <div className='assistance-name-content-block' onClick={() => navigate(`/projects`)}>
                            <span>/</span>
                            <p>View projects group</p>
                            <img src='/img/arrowrRight.png' alt='Arrow Right' />
                        </div>
                    </div>
                    <div className='assistance-main'>
                        <div className='assistance-main-container'>
                            <div onClick={() => handleOpenModal()} className='assistance-main-new'>
                                <section className='assistance-main-new-create-project-container'>
                                    <IconCreateProject />
                                    <p className='assistance-main-new-create-project-title'>Create New Project Space</p>
                                </section>
                            </div>
                            {currentProjects.map((project) => {
                                const projectImage = project.galleries && project.galleries.length > 0 && project.galleries[0].medias.length > 0
                                    ? project.galleries[0].medias[project.galleries[0].medias.length - 1].url
                                    : '/img_projects/projectdefault.png';
                                return (
                                    <div className='assistance-project-list' key={project.id}>
                                        <div onClick={() => navigate(`/costs/${project.id}`)}
                                             className='assistance-project-get'>
                                            <img className='assistance-project-get-img' src={projectImage}
                                                 alt='project'/>
                                        </div>
                                        <div className='assistance-project-title'>
                                            <p className='assistance-project-name'
                                               data-title={project.title}>{project.title}</p>
                                            <p className='assistance-project-data'>{new Date(project.created_at).toLocaleDateString()}</p>
                                        </div>

                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    {allProjects.length > PROJECTS_PER_PAGE && (
                        <PaginationAssistance
                            projectsPerPage={PROJECTS_PER_PAGE}
                            totalProjects={allProjects.length}
                            paginate={(pageNumber: number) => setCurrentProjectPage(pageNumber)}
                            currentPage={currentProjectPage}
                        />
                    )}
                    <CreateProjectModal
                        isOpen={isModalOpen}
                        onRequestClose={handleCloseModal}
                    />
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    allProjects: state.projectAssistance.allProjects,
    isFetching: state.projectAssistance.isFetching,
    error: state.projectAssistance.error,
});

const mapDispatchToProps = {
    fetchAllProjects,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AllProjects);
