import React, {useEffect, useState} from 'react';
import AllProjects from "../../components/ProjectAssistanceAllProjects/AllProjects";
import Templates from "../../components/ProjectsAssistanceTemplates/Templates";
import Footer from "../../components/ProjectAssistanceFooter/Footer";
import {Loader} from "../../components/Loader/Loader";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";

const ProjectAssistance = () => {
    const [projectsLoading, setProjectsLoading] = useState(true);
    const [templatesLoading, setTemplatesLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);
    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if(!projectsLoading && !templatesLoading)
        {
            setIsLoading(false);
        }
    }, [projectsLoading, templatesLoading]);

    return (
        <div className='wrapper'>
            {!isSmallScreen ? (
                <div className='assistance-placeholder'><p>Home/Project group assistance</p></div>
            ) : (
                <MobileNavigation
                    links={[
                        {to: '/', label: 'Cashbuild'},
                        {to: '', label: '>'},
                        {to: '/', label: 'Home'},
                        {to: '', label: '>'},
                        {to: '/assistance', label: 'Project assistance'},
                    ]}
                >
                </MobileNavigation>
            )}
            {isLoading && (
                <Loader/>
            )}
            <section>
            <AllProjects setIsLoading={setProjectsLoading} isLoading={isLoading}/>
                    <Templates setIsLoading={setTemplatesLoading} isLoading={isLoading}/>
                </section>
            <Footer />
        </div>
    );
};

export default ProjectAssistance;
