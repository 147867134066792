import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getTemplateById } from '../../api/APIWraper';
import { Template } from '../../types/TemplateType';

interface TemplateByIdState {
    template: Template | null;
    isFetching: boolean;
    error: string | null;
}

const initialState: TemplateByIdState = {
    template: null,
    isFetching: false,
    error: null,
};

export const fetchTemplateById = createAsyncThunk<Template, number>(
    'templateById/fetchTemplateById',
    async (tempId) => {
        return await getTemplateById(tempId);
    }
);

const templateByIdSlice = createSlice({
    name: 'templateById',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTemplateById.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchTemplateById.fulfilled, (state, action: PayloadAction<Template>) => {
                state.isFetching = false;
                state.template = action.payload;
            })
            .addCase(fetchTemplateById.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            });
    },
});

export default templateByIdSlice.reducer;
