import {Project} from "../types/ProjectTypes";
import {CreateProjectPayload} from "../types/ApiTypes";

const BASE_URL = process.env.REACT_APP_BASE_URL_CUSTOMER

export async function http (url: string, config: {}): Promise<any> {
    const bearerToken = localStorage.getItem('Bearer');
    config = {...config, headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`,
            "Access-Control-Allow-Origin": `${BASE_URL}`,
            "Origin": `${BASE_URL}`
        },
    }
    if (localStorage.getItem('token')) {
        config = {...config, headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${bearerToken}`,
                "Access-Control-Allow-Origin": `${BASE_URL}`,
                "Origin": `${BASE_URL}`
            }}

    }
    config={...config,
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        redirect: "follow",
        referrer: "no-referrer",
    }
    const request = new Request(url, config);

    const response = await fetch(request);
    const data = await response.json()

    return {data: data, status: response.status}
}


export async function post(url: string, body: {}, config?: {}): Promise<any> {
    const init = {
        method: "POST",
        body: JSON.stringify(body),
        ...config
    }
    return await http(url, init)
}


export async function get(url: string, config?: {}) {
    const init = {
        method: "GET",
        ...config
    }
    return await http(url, init)
}

export async function Delete(url: string, config?: {}): Promise<any> {
    const init = {
        method: "DELETE",
        ...config
    }
    return await http(url, init)
}
export async function patch(url: string, body: {}, config?: {}): Promise<any> {
    const init = {
        method: "PATCH",
        body: JSON.stringify(body),
        ...config
    }
    return await http(url, init)
}


/*
* Projects Api
*/

export async function getAllProjectsAssistance(page: number = 1, perPage: number = 11, sortBy?: string, sortOrder?: string) {
    let url = `${BASE_URL}/projects?page=${page}&perPage=${perPage}`;

    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url);
    return {
        allProjects: response.data.data,
        total: response.data.pagination.total
    };
}

export async function getLastProjectsAssistance(page: number = 1, perPage: number = 11, sortBy?: string, sortOrder?: string) {
    let url = `${BASE_URL}/projects?page=${page}&perPage=${perPage}`;

    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url);
    return {
        projects: response.data.data,
        pagination: response.data.pagination
    };
}

export async function getAllProjectsAssistanceTemplateModal(page: number = 1, perPage: number = 11, sortBy?: string, sortOrder?: string) {
    let url = `${BASE_URL}/projects?page=${page}&perPage=${perPage}`;

    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url);
    return {
        projects: response.data.data,
        pagination: response.data.pagination
    };
}



export async function getProjectById(projectId:string,sortBy?: string, sortOrder?: string)
{
    let url = `${BASE_URL}/projects/${projectId}`
    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url)
    return response.data
}

export async function postSendEmail(body:{}){
    const url = `${BASE_URL}/mailing`
    return await post(url, body)
}

export const postCreateProject = async (payload: CreateProjectPayload): Promise<{ data: Project }> => {
    const url = `${BASE_URL}/projects`;
    return await post(url, payload);
};

export async function updateProject(projectId: number, body: {}){
    const url =`${BASE_URL}/projects/${projectId}`
    return await patch(url,body)
}
export async function deleteProjectById(projectId:number)
{
    const url = `${BASE_URL}/projects/${projectId}`
    return await Delete(url)
}

export async function getAllTemplates(page:number=1,perPage:number=8)
{
    const url = `${BASE_URL}/templates?page=${page}&perPage=${perPage}`;
    const response = await get(url)
    return {
        templates:response.data.data,
        pagination:response.data.pagination
    }
}

export async function getTemplateById(templateId:number)
{
    const url = `${BASE_URL}/templates/${templateId}`
    const response = await get(url)
    return response.data
}

export async function getAllStores()
{
    const url = `${BASE_URL}/stores?page=1&perPage=100`;
    const response = await get(url)
    return response.data
}

export async function addTemplatesToProjects(projectId:number,body:{}){
    const url =`${BASE_URL}/templates/${projectId}/add_to_projects`
    return await patch(url,body)
}
export async function updatePermissions(projectId: string, body: { email: string; permissions: string[] }) {
    const url = `${BASE_URL}/projects/${projectId}/permissions`;
    return await post(url, body);
}

export async function getUsersFromOwnProjects(page: number, perPage: number,sortBy?: string, sortOrder?: string) {
    let url = `${BASE_URL}/users_from_own_projects?page=${page}&perPage=${perPage}`;
    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url);
    return {
        users: response.data,
        total:response.data.pagination
    };
}

export async function deleteUserProjectsPermissions(user_id: number, body: {projects_id:number[]; permissions: string[] })
{
    const url = `${BASE_URL}/users/${user_id}/permissions`;
    return await patch(url,body)
}

export async function itemTypeAll( body: {item_type_ids:number[]}) {
    const url = `${BASE_URL}/item_types_all`;
    const response = await post(url, body);
    return response.data.data
}

export async function getOrderHistory(page: number = 1, perPage: number = 10,sortBy?: string, sortOrder?: string) {
    let url = `${BASE_URL}/order_history?page=${page}&perPage=${perPage}`;
    if (sortBy && sortOrder) {
        url += `&sortField=${sortBy}&sortOrder=${sortOrder}`;
    }
    const response = await get(url);
    return {
        orders: response.data.data,
        totalOrders: response.data.pagination.total,
    };
}
