import React, {ReactNode} from 'react';
import ReactDOM from 'react-dom';
import {IconCross} from "../../../IconComponents/IconComponents";
import "./modal.css"

interface ModalProps {
    isOpen: boolean,
    onClose: () => void,
    children: ReactNode,
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void
}

const Modal: React.FC<ModalProps> = ({isOpen, onClose, children, onClick}) => {
    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={"overlay-main"}>
            <div className={"modal-main"}>
                <button className="closeButton-main" onClick={onClose}>
                    <IconCross width={14} height={14} color={"black"} strokeWidth={2}/>
                </button>
                {children}
            </div>
        </div>,
        document.body
    );
};

export default Modal;
