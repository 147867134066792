import './step2HeaderList.css';
import "../../Page/Assistance Step2/Step2Styles/step2-width.css";
import {useSelector} from "react-redux";
import {RootState} from "../../store";

interface HeaderListProps {
    isLabor?: boolean;
    isTool?: boolean;
    showSettingsButton?: boolean;
}

const HeaderList: React.FC<HeaderListProps> = ({ isLabor, showSettingsButton, isTool }) => {
    const isPageActive = useSelector((state: RootState) => state.fragmentVisible.isPageActive);
    const calcStyleList = isPageActive ? ('step2-material-width-calc') : ('step2-material-width-step2')
    return (
        <>
            {!showSettingsButton ? (
                <div className='step2-header-list '>
                    <p className='step2-material-width'>Product</p>
                    <p className='step2-other-width'>QTY to order</p>
                    <p className='step2-other-width'>QTY ordered</p>
                    <p className='step2-other-width'>Unit price</p>
                    <p className='step2-other-width'>Total Value</p>
                    <p className='step2-other-width step2-item-total-ordered'>Current order</p>
                    <p className='step2-item-vedor'>Vendor</p>
                </div>
            ) : (
                <div className='step2-header-list-step2 '>
                    <p className={calcStyleList}>Product</p>
                    <p className='step2-other-width-ordered'>QTY ordered</p>
                    <p className='step2-other-width-calc'>Calculated QTY</p>
                    <p className='step2-other-width-price'>Unit price</p>
                    <p className='step2-other-width-total-ordered step2-item-total-ordered'>Total ordered</p>
                    <p className='step2-other-width-total'>Total</p>
                    <p className='step2-item-vedor'>Vendor</p>

                </div>
            )}
        </>
    );
};

export default HeaderList;
