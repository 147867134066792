import React, { useState, useEffect } from 'react';
import './SwitcherStep2.css';

interface SwitcherProps {
    options: Array<'fragments' | 'types'>;
    onChange: (selectedOption: 'fragments' | 'types') => void;
}

const SwitcherStep2: React.FC<SwitcherProps> = ({ options, onChange }) => {
    const [selectedOption, setSelectedOption] = useState< 'types'|'fragments'>(options[0]);

    useEffect(() => {
        onChange(selectedOption);
    }, [selectedOption, onChange]);

    const handleClick = (option: 'types' | 'fragments') => {
        setSelectedOption(option);
    };

    return (
        <div className={`switcher switcher-${options.length}`}>
            {options.map((option, index) => (
                <div
                    key={index}
                    className={`switcher-option ${selectedOption === option ? 'active-switcher' : ''}`}
                    onClick={() => handleClick(option)}
                >
                    {option}
                </div>
            ))}
        </div>
    );
};

export default SwitcherStep2;
