import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import './style.css';
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createProject as createProjectAction } from "../../../Slice/createNewProject/createProjectSlice";
import { setProject as setProjectAction } from "../../../Slice/updateProject/updateProjectSlice";
import { getAllStores } from "../../../api/APIWraper";
import { clearTemplates } from "../../../Slice/LocalTemplate/LocalTemplateSlice";
import { Project } from "../../../types/ProjectTypes";
import { Template } from "../../../types/TemplateType";
import { IconTriangleDown, IconTriangleUp } from "../../../IconComponents/IconComponents";

interface Store {
    id: number;
    city: string;
}

interface CreateProjectModalProps extends PropsFromRedux {
    isOpen: boolean;
    onRequestClose: () => void;
    onProjectCreated?: (project: Project) => void;
    template?: Template | null;
}

const CreateProjectModal: React.FC<CreateProjectModalProps> = ({
                                                                   isOpen,
                                                                   onRequestClose,
                                                                   createProject,
                                                                   setProject,
                                                                   onProjectCreated = () => {},
                                                                   template
                                                               }) => {
    const [name, setName] = useState<string>('');
    const [stores, setStores] = useState<Store[]>([]);
    const [selectedStore, setSelectedStore] = useState<Store | null>(null);
    const [targetDate, setTargetDate] = useState<string>(() => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0];
    });
    const [showStoreDropdown, setShowStoreDropdown] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onRequestClose();
        }
    };

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
            document.body.classList.add('modal-open');
            window.addEventListener('keydown', handleKeyDown);
        } else {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);}

        return () => {
            document.body.style.overflow = '';
            document.body.classList.remove('modal-open');
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            getAllStores()
                .then(data => {
                    const sortedStores = data.data.sort((a: Store, b: Store) => a.city.localeCompare(b.city));
                    setStores(sortedStores);
                })
                .catch(error => console.error("Failed to fetch stores:", error));
        }
    }, [isOpen]);

    const clearTemplateDataFromLocalStorage = () => {
        Object.keys(localStorage).forEach(key => {
            if (key.startsWith('selectedTemplate-')) {
                localStorage.removeItem(key);
            }
        });
    };

    const handleCreateProject = async () => {
        if (name) {
            const projectPayload = {
                title: name,
                storeId: selectedStore ? selectedStore.id : null,
                target_date: targetDate,
            };
            const project = await createProject(projectPayload);

            if (project.payload && isProject(project.payload)) {
                setProject(project.payload);
                clearTemplates();
                clearTemplateDataFromLocalStorage();
                setName('');
                setSelectedStore(null);
                setTargetDate(new Date().toISOString().split('T')[0]);
                onRequestClose();

                if (template) {
                    onProjectCreated(project.payload);
                } else {
                    navigate('/assistance/create', { state: { projectId: project.payload.id } });
                }
            } else {
                console.error("Project payload is missing required properties");
            }
        }
    };

    const getTomorrowDate = (): string => {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today.toISOString().split('T')[0];
    };

    const tomorrowDate = getTomorrowDate();

    const isProject = (obj: any): obj is Project => {
        return obj && typeof obj === 'object' && 'id' in obj && 'title' in obj && 'created_at' in obj && 'updated_at' in obj;
    };

    const handleStoreSelect = (storeId: number) => {
        const store = stores.find(store => store.id === storeId);
        setSelectedStore(store || null);
        setShowStoreDropdown(false);
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            className="modal"
            overlayClassName="overlay"
        >
            <div className="modal-header">
                <h2>Save the project space</h2>
                <p>Enter your project space name and select a store</p>
                <button onClick={onRequestClose} className="close-button">&times;</button>
            </div>
            <div className="modal-content">
                <section>
                    Project space name
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter name"
                        className="project-name-input-modal"
                    />
                </section>
                <section>
                    Target date
                    <input
                        type="date"
                        min={tomorrowDate}
                        value={targetDate}
                        onChange={(e) => setTargetDate(e.target.value)}
                        className="project-date-input-modal"
                    />
                </section>
                <section>
                    Store (optional)
                    <div className='step-template-dropdown-container-modal'>
                        <button
                            className={`step-template-block-title-dropdown-create-modal ${showStoreDropdown ? 'active' : ''}`}
                            onClick={() => setShowStoreDropdown(!showStoreDropdown)}
                        >
                            <p className="step-template-block-title-p">{selectedStore ? selectedStore.city : 'Select a store'}</p>
                        </button>
                        <span className="dropdown-indicator-modal"
                              onClick={() => setShowStoreDropdown(!showStoreDropdown)}>
                                {showStoreDropdown ? <IconTriangleUp/> : <IconTriangleDown/>}
                        </span>
                        {showStoreDropdown && (
                            <section className='step-template-dropdown-modal'>
                                {stores.length > 0 ? (
                                    stores.map((store) => (
                                        <div
                                            key={store.id}
                                            onClick={() => handleStoreSelect(store.id)}
                                            className='step-template-container-option-modal'
                                        >
                                            {store.city}
                                        </div>
                                    ))
                                ) : (
                                    <div className='step-template-container-option-modal'>
                                        No options
                                    </div>
                                )}
                            </section>
                        )}
                    </div>
                </section>
            </div>
            <div className="modal-footer">
                <button onClick={handleCreateProject} className="create-button" disabled={!name}>
                    Create project space
                </button>
            </div>
        </Modal>
    );
};

const mapDispatchToProps = {
    createProject: createProjectAction,
    setProject: setProjectAction,
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreateProjectModal);
