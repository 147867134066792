import React, { useEffect } from 'react';
import './style.css';
import TopHeader from "../../components/TopHeader/TopHeader";
import Header from "../../components/Header/Header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ToastNotification from "../../components/Toast/AssisCreateToastNotification";

function Home() {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/') {
            navigate('/assistance');
        }
    }, [location.pathname, navigate]);

    return (
        <div className='home'>
            <TopHeader/>
            <Header/>
            <div className='content-wrapper'>
                <Outlet/>
                <ToastNotification/>
            </div>
        </div>
    );
}

export default Home;
