import React, { useState } from 'react';
import './style.css';
import {
    IconFooterFacebook, IconFooterGeo,
    IconFooterInstagram,
    IconFooterLinkedIn, IconFooterMail, IconFooterPhone,
    IconFooterYouTube
} from "../../IconComponents/IconComponents";

const Footer = () => {
    const [activeSection, setActiveSection] = useState('');

    const toggleSection = (section: React.SetStateAction<string>) => {
        setActiveSection(activeSection === section ? '' : section);
    };
    return (
        <footer className='footer'>
            <div className='ft-info'>
                <div className='ft-logo'>
                    <img className='ft-logo-img' src='/img_projects/gobuild.svg'/>
                </div>
                <div className='ft-info-block'>
                    <div className='ft-h' onClick={() => toggleSection('information')}>
                        <h1>MAIN INFORMATION</h1>
                        <span className={`arrow ${activeSection === 'information' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'information' ? 'show' : ''}`}>
                        <p>Home</p>
                        <p>About Us</p>
                        <p>Pricing</p>
                        <p>Functionality</p>
                        <p>Support</p>
                    </div>
                </div>
                <div className='ft-info-block'>
                    <div className='ft-h' onClick={() => toggleSection('account')}>
                        <h1>CONTACTS</h1>
                        <span className={`arrow ${activeSection === 'account' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'account' ? 'show' : ''}`}>
                        <div className='ft-contacts-geo'>
                            <div><IconFooterGeo/></div>
                            <p>HEAD OFFICE: GoBuild360 Online (Pty) Ltd Unit 5 Circle Park 1 Sacks Circle Bellville
                                South Cape Town 7560 South Africa</p>
                        </div>
                        <div className='ft-contacts'>
                            <div><IconFooterMail/></div>
                            <p>info@gobuild360.io</p>
                        </div>
                        <div className='ft-contacts'>
                            <div><IconFooterPhone/></div>
                            <p>123 456 7890</p>
                        </div>
                    </div>
                </div>
                <div className='ft-info-block contact-us'>
                    <div className='ft-h' onClick={() => toggleSection('contact')}>
                        <h1>SOCIAL MEDIA</h1>
                        <span className={`arrow ${activeSection === 'contact' ? 'open' : ''}`}>&gt;</span>
                    </div>
                    <div className={`ft-list ${activeSection === 'contact' ? 'show' : ''}`}>
                        <div className='ft-list-social'>
                            <IconFooterFacebook/>
                            <IconFooterInstagram/>
                            <IconFooterLinkedIn/>
                            <IconFooterYouTube/>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ft-footer'>
                <div className='ft-footer-block'>
                    <div className='ft-footer-block-container'>
                    <p>Powered by GoBuild360°</p>
                    <p>@All rights reserved</p>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;