import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

interface UserRoleProps {
    redirectPath?: string;
    children: React.ReactNode;
}

const UserRoleRouteMiddleware: React.FC<UserRoleProps> = ({ redirectPath = '/auth/login', children }) => {
    const [isAuthorized, setIsAuthorized] = useState(true);

    useEffect(() => {
        const checkToken = () => {
            const token = localStorage.getItem('Bearer');
            if (!token) {
                setIsAuthorized(false);
            }
        };

        checkToken();

        const handleStorageChange = (event: StorageEvent) => {
            if (event.key === 'Bearer' && !event.newValue) {
                setIsAuthorized(false);
            }
        };
        window.addEventListener('storage', handleStorageChange);
        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, []);

    if (!isAuthorized) {
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</>;
};

export default UserRoleRouteMiddleware;
