import './style.css'
import React from "react";

interface ListProps {
    columns: string[],
    columnsWidthClasses?: string[]
}

const ElementList: React.FC<ListProps> = ({ columns, columnsWidthClasses = [] }) => {
    return (
        <div className='list-item'>
            <ul className='list-item-ul'>
                {columns.map((item, index) => (
                    <li
                        className={`list-item-li ${columnsWidthClasses[index] || ''}`}
                        key={index}
                    >
                        <p className='list-item-p'>{item}</p>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default ElementList;
