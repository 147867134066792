import {Template} from "./TemplateType";

export interface Answer {
    title: string;
    value: string;
}

export interface FragmentData {
    key: string;
    question: string;
    value: string;
    select?: string;
    answers: Answer[];
}

export interface Fragment {
    id: number;
    qty?: number;
    data: Record<string, FragmentData>;
}
export interface Category {
    id: number;
    category: string;
}

export interface Media {
    id: number;
    filename: string;
    mime_type: string;
    size: number;
    url: string;
    gallery_id: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export interface Gallery {
    id: number;
    galleryable_id: number;
    galleryable_type: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    medias: Media[];

}

export interface User {
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    address?:string;
    email: string;
    role_id: number;
    role: {
        role: string;
    }
    projects_count: number;
    galleries?: Gallery[];
}

export interface Permission {
    id: number;
    created_at: string;
    updated_at: string;
    project_id: number;
    user_id: number;
    level: [];
    user: User;
}
export interface Store {
    created_at: string;
    email: string;
    id_country: string;
    id_store:number
}

export interface Project {
    store: Store;
    id: number;
    title: string;
    created_at: string;
    updated_at: string;
    target_date:string;
    galleries?:Gallery[]
    deleted_at: string | null;
    data: {
        summary_item_types_project?: { percentage: number }[];
        templates?: Template[];
    };
    users: User[];
    permissions: Permission[];
}

export interface QuestionAnswer {
    question: string;
    answerTitle: string;
    answerValue: string;
}

export interface ProjectsAssistanceState {
    projects: Project[];
    allProjects:Project[];
    total: number;
    isFetching: boolean;
    error: string | null;
}

export const initialState: ProjectsAssistanceState = {
    projects: [],
    allProjects:[],
    total: 0,
    isFetching: false,
    error: null,
};
