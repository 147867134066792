import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store';
import { setSelectedLabor, clearSelectedItems } from '../../../Slice/SelectedItems/SelectedItemsSlice';
import '../Step2Styles/projectUpdateItems.css';
import ILaborItemType from "../../../Interfaces/ProjectInterfaces/IlaborItemType";
import {getVendorLogo, Vendor} from "../../../Enums/VendorEnum";

interface LaborItemTypeListProps {
    laborItemTypes: ILaborItemType[];
}

const LaborItemTypeList: React.FC<LaborItemTypeListProps & PropsFromRedux> = ({
                                                                                  laborItemTypes,
                                                                                  setSelectedLabor
                                                                              }) => {
    const [selectedLaborItems, setSelectedLaborItemsState] = useState<{ [key: string]: boolean }>({});
    const [totalPrice, setTotalPrice] = useState<number>(0);

    const [visitedItems, setVisitedItems] = useState<string[]>([]);


    const generateLaborKey = (itemType: ILaborItemType) => {
        return `${itemType.item_type.id}-${itemType.clone_id || itemType.item_type_index}-${itemType.fragment_index}`;
    };
    const generateKey = (type: string, fragmentKey: string, itemId: number, cloneId: string) => {
        return `${type}-${fragmentKey}-${itemId}-${cloneId}`;
    };

    useEffect(() => {
        const visitedData = localStorage.getItem('visitedItems');
        if (visitedData) {
            setVisitedItems(JSON.parse(visitedData));
        }
    }, []);

    useEffect(() => {
        syncSelectionsWithLocalStorage(laborItemTypes);
    }, [laborItemTypes]);

    useEffect(() => {
        const calculatedPrice = calculateTotalPrice(laborItemTypes, selectedLaborItems);
        setTotalPrice(calculatedPrice);
        setSelectedLabor(Date.now());
    }, [selectedLaborItems, laborItemTypes, setSelectedLabor]);

    const syncSelectionsWithLocalStorage = (items: ILaborItemType[]) => {
        const storedSelectedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const updatedLaborSelections: { [key: string]: boolean } = {};

        items.forEach(item => {
            const fragmentKey = `${item.fragment_id}-${item.fragment_index}-${item.template_index}`;
            const laborKey = generateLaborKey(item);

            if (storedSelectedFragments[fragmentKey]?.LaborItemTypes?.[laborKey]) {
                updatedLaborSelections[laborKey] = true;
            }
        });

        setSelectedLaborItemsState(updatedLaborSelections);
    };


    const calculateTotalPrice = (items: ILaborItemType[], selected: { [key: string]: boolean }) => {
        let totalPrice = 0;

        const groupedItems: { [key: number]: ILaborItemType[] } = {};
        items.forEach(item => {
            if (!groupedItems[item.item_type.id]) {
                groupedItems[item.item_type.id] = [];
            }
            groupedItems[item.item_type.id].push(item);
        });

        Object.values(groupedItems).forEach(group => {
            const laborKey = generateLaborKey(group[0]);

            if (selected[laborKey]) {
                let totalQty = 0;
                let totalOrderedQty = 0;

                group.forEach(item => {
                    item.item_type.items?.forEach(laborItem => {
                        totalQty += laborItem.qty || 0;
                        totalOrderedQty += laborItem.ordered_qty || 0;
                    });
                });

                const price = parseFloat(String(group[0].item_type.items?.[0]?.price)) || 0;
                const difTotalQty = totalQty - totalOrderedQty;
                const priceTotal = difTotalQty > 0 ? price * difTotalQty : 0;
                totalPrice += priceTotal;
            }
        });

        return totalPrice;
    };

    const handleCheckboxChange = (itemType: ILaborItemType) => {
        const laborKey = generateLaborKey(itemType);
        const isSelected = !selectedLaborItems[laborKey];
        const updatedSelections = { ...selectedLaborItems, [laborKey]: isSelected };

        setSelectedLaborItemsState(updatedSelections);

        if (isSelected) {
            laborItemTypes.forEach(originalItem => {
                if (originalItem.item_type.id === itemType.item_type.id) {
                    mergeAndSaveLocalStorage(originalItem);
                }
            });
        } else {
            laborItemTypes.forEach(originalItem => {
                if (originalItem.item_type.id === itemType.item_type.id) {
                    removeFromLocalStorage(originalItem);
                }
            });
        }

        const calculatedPrice = calculateTotalPrice(laborItemTypes, updatedSelections);
        setTotalPrice(calculatedPrice);
        setSelectedLabor(Date.now());
    };

    const mergeAndSaveLocalStorage = (itemType: ILaborItemType) => {
        const storedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
        const laborKey = generateLaborKey(itemType);

        if (!storedFragments[fragmentKey]) {
            storedFragments[fragmentKey] = { items: {}, LaborItemTypes: {}, ToolsItemTypes: {} };
        }

        const laborItems = storedFragments[fragmentKey].LaborItemTypes || {};
        if (!laborItems[laborKey]) {
            laborItems[laborKey] = {
                ...itemType.item_type,
                items: itemType.item_type.items?.map(item => ({
                    ...item,
                    payload: generatePayload(itemType),
                })),
                payload: generatePayload(itemType),
            };
        } else {
            itemType.item_type.items?.forEach(item => {
                if (!laborItems[laborKey].items.some((i: any) => i.id === item.id)) {
                    laborItems[laborKey].items.push({
                        ...item,
                        payload: generatePayload(itemType),
                    });
                }
            });
        }

        storedFragments[fragmentKey].LaborItemTypes = laborItems;

        localStorage.setItem('selectedFragments', JSON.stringify(storedFragments));
    };

    const removeFromLocalStorage = (itemType: ILaborItemType) => {
        const storedFragments = JSON.parse(localStorage.getItem('selectedFragments') || '{}');
        const fragmentKey = `${itemType.fragment_id}-${itemType.fragment_index}-${itemType.template_index}`;
        const laborKey = generateLaborKey(itemType);

        if (storedFragments[fragmentKey]?.LaborItemTypes) {
            delete storedFragments[fragmentKey].LaborItemTypes[laborKey];

            if (Object.keys(storedFragments[fragmentKey].LaborItemTypes).length === 0) {
                delete storedFragments[fragmentKey].LaborItemTypes;
            }
        }

        localStorage.setItem('selectedFragments', JSON.stringify(storedFragments));
    };

    const generatePayload = (itemType: ILaborItemType): any => ({
        template_index: itemType.template_index,
        fragment_index: itemType.fragment_index,
        item_type_index: itemType.item_type_index,
        template_id: itemType.template_id,
        fragment_id: itemType.fragment_id,
        item_type_id: itemType.item_type.id,
        item_ids: itemType.item_type.items?.map(i => i.id) || [],
    });

    const calculateTotalQty = (id: number) => {
        return laborItemTypes
            .filter(type => type.item_type.id === id)
            .reduce((total, current) => total + (current.qty || 0), 0);
    };

    if (laborItemTypes.length === 0) {
        return <div className="step2-div-no-materials">No labor items</div>;
    }

    const displayedItemTypes = new Set<number>();

    return (
        <div>
            {laborItemTypes.map((itemType,itemIndex) => {
                const laborKey = generateLaborKey(itemType);
                const title = itemType.item_type.title;

                if (displayedItemTypes.has(itemType.item_type.id)) {
                    return null;
                }

                displayedItemTypes.add(itemType.item_type.id);

                const hasNonEmptyItems = laborItemTypes.some(
                    (type) => type.item_type.id === itemType.item_type.id && type.item_type.items && type.item_type.items.length > 0
                );

                if (!hasNonEmptyItems) {
                    const totalQty = calculateTotalQty(itemType.item_type.id);

                    return (
                        <div key={laborKey}>
                            <div className="step2-item-type step2-item-type-labor">
                                <div className='step2-material-width'>
                                    <label className="item-type-list-label">{title}</label>
                                </div>
                                <p className="step2-other-width">0</p>
                                <p className="step2-other-width">{totalQty}</p>
                                <p className="step2-other-width">R 0.00</p>
                                <p className="step2-other-width">R 0.00</p>
                                <p className="step2-other-width">R 0.00</p>
                            </div>
                        </div>
                    );
                }

                if (itemType.item_type.items && itemType.item_type.items.length > 0) {
                    let totalQty = 0;
                    let totalOrderedQty = 0;
                    laborItemTypes
                        .filter(type => type.item_type.id === itemType.item_type.id)
                        .forEach(type => {
                            type.item_type.items?.forEach(laborItem => {
                                totalQty += laborItem.qty || 0;
                                totalOrderedQty += laborItem.ordered_qty || 0;
                            });
                        });
                    const unit = itemType.item_type.items[0].unit || '';
                    const price = parseFloat(String(itemType.item_type.items[0].price)) || 0;
                    const checkTotalQty = !isNaN(totalQty) ? Math.max(totalQty, 0) : 0;
                    const checkTotalOrderedQty = !isNaN(totalOrderedQty) ? Math.max(totalOrderedQty, 0) : 0;
                    const totalPriceOrdered = price * checkTotalOrderedQty;
                    const totalPriceRemaining = price * (checkTotalQty - checkTotalOrderedQty);
                    const safeTotalPriceRemaining = Math.max(totalPriceRemaining, 0);

                    return (
                        <div key={laborKey}>
                            <p className="item-type-list-label">{title}</p>
                            <div key={laborKey} className="step2-item-type step2-item-type-labor">
                                <div className="step2-material-width">
                                    <div className="step2-item-checkbox-block">
                                        <input
                                            type="checkbox"
                                            id={`checkbox-${laborKey}`}
                                            checked={selectedLaborItems[laborKey]}
                                            onChange={() => handleCheckboxChange(itemType)}
                                        />
                                        <label htmlFor={`checkbox-${laborKey}`}>{itemType.item_type.items[0].title}</label>
                                    </div>
                                </div>
                                <p className="step2-other-width">{checkTotalOrderedQty} ({unit})</p>
                                <p className="step2-other-width">{checkTotalQty} ({unit})</p>
                                <p className="step2-other-width">R {price.toFixed(2)}</p>
                                <p className="step2-other-width">R {totalPriceOrdered.toFixed(2)}</p>
                                <p className="step2-other-width">R {safeTotalPriceRemaining.toFixed(2)}</p>
                                <div className='step2-other-width-vendor'>
                                    <img className='step3-vendor-img' src={getVendorLogo(itemType.item_type.items[0].vendor as Vendor)} />
                                </div>
                            </div>
                        </div>
                    );
                }
                return null;
            })}
            <div className="step2-bottom-block">
                <p>Total:</p>
                {totalPrice ? <span>R {totalPrice.toFixed(2)}</span> : <span>R 0</span>}
                <button className="step2-bottom-block-btn-cart">Add labor to cart</button>
            </div>
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    oldProject: state.projectById.oldProject,
});

const mapDispatchToProps = {
    setSelectedLabor,
    clearSelectedItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LaborItemTypeList);
