import React, { useState } from 'react';
import ProgressBar from "../ProgressBar/ProgressBar";
import './style.css';
import { Project} from "../../types/ProjectTypes";
import { useNavigate} from "react-router-dom";
import { deleteProject } from "../../Slice/getProjectAssistance/ProjectAssistanceSlice";
import DeleteModal from "../Modals/ModalDelete/ModalDelete";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import {IconRemove, IconSettings} from "../../IconComponents/IconComponents";

interface IProjectCostHeader{
    isSmallScreen: boolean;
}

const ProjectCostsHeader: React.FC<IProjectCostHeader & PropsFromRedux> = ({ project, deleteProject,isSmallScreen }) => {
    const navigate = useNavigate();
    const [projectToDelete, setProjectToDelete] = useState<Project | null>(null);

    const handleDelete = (project: Project) => {
        setProjectToDelete(project);
    };

    const handleRemoveProject = () => {
        if (projectToDelete) {
            deleteProject(projectToDelete.id);
            setProjectToDelete(null);
            navigate('/assistance');
        }
    };

    if (!project) return null;

    return (
        <>
            {!isSmallScreen ? (
                    <div className='costs-header' key={project.id}>
                        <div className='costs-header-name'>
                            <p>{project.title}</p>
                        </div>
                        <div className='costs-header-date'>
                            <div className='costs-header-date-block'>
                                <p className='costs-header-date-p'>{new Date(project.created_at).toLocaleDateString()}</p>
                            </div>
                        </div>
                        <div className='costs-header-progress'>
                            <ProgressBar project={project}/>
                        </div>
                        <div className='costs-header-users'>
                            {project.users?.map(user => {
                                const userImg = user.galleries && user.galleries.length > 0 && user.galleries[0].medias.length > 0
                                    ? user.galleries[0].medias[0].url
                                    : '/img/defuser.png';
                                return (
                                    <img key={user.id} src={userImg} alt={user.username} className='user-image'/>
                                );
                            })}
                        </div>
                        <div className='action-block-costs'>
                            <button onClick={() => navigate(`/users/${project.id}`)} className='costs-action-btn'>
                                <div className='setting-costs'><IconSettings/></div>
                            </button>
                            <button onClick={() => handleDelete(project)} className='costs-btn-costs'>
                                <div className='delete-costs'><IconRemove/></div>
                            </button>
                        </div>
                    </div>
                ): (
                    <div className='costs-header' key={project.id}>
                        <div className='costs-header-name'>
                            <p className='costs-header-project-name'>Project space name</p>
                            <input
                                type='text'
                                value={project.title}
                                disabled={true}
                                className='mh-name-input-costs'
                            />
                        </div>
                        <div className='costs-header-date'>
                                <p className='costs-header-data-text'>Creation date</p>
                                <p className='costs-header-date-p'>{new Date(project.created_at).toLocaleDateString()}</p>
                        </div>
                        <div className='costs-header-progress'>
                            <p className='costs-header-progress-text'>Progress:</p>
                            <ProgressBar project={project} showPercentagePosition={'above'}/>
                        </div>
                        <div className='costs-header-users'>
                            <p className='costs-header-users-text'>Team</p>
                            <div className='costs-header-users-img'>
                            {project.users?.map(user => {
                                const userImg = user.galleries && user.galleries.length > 0 && user.galleries[0].medias.length > 0
                                    ? user.galleries[0].medias[0].url
                                    : '/img/defuser.png';
                                return (
                                    <img key={user.id} src={userImg} alt={user.username} className='user-image'/>
                                );
                            })}
                            </div>
                        </div>
                        <div className='action-block-costs'>
                                <div onClick={() => navigate(`/users/${project.id}`)} className='costs-header-navigate-user'>Edit Team</div>
                                <div onClick={() => handleDelete(project)} className='costs-header-delete-project'>Delete Project Space</div>
                        </div>
                    </div>



                )}

            {projectToDelete && (
                <DeleteModal
                    isOpen={true}
                    onRequestClose={() => setProjectToDelete(null)}
                    onConfirm={handleRemoveProject}
                    onCancel={() => setProjectToDelete(null)}
                    message={`Are you sure you want to delete the project space "${projectToDelete.title}"?`}
                    confirmText="Yes"
                    cancelText="No"
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project
});

const mapDispatchToProps = {
    deleteProject
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProjectCostsHeader);
