// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step3-bottom-total{
    font-size: 19px;
    position: relative;
    background-color: #E0E0E0;
    padding: 5px 20px;
}
.step3-bottom-total > p {
    display: block;
    position: relative;
    margin-bottom: 10px;
}

.step3-bottom-total > p::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -10px;
    width: 100%;
    border-bottom: 1px solid black;
}

.step3-bottom-total-container{
    display: flex;
    justify-content: space-between;
}
.step3-bottom-total-price{
    display: flex;
    justify-content: space-between;
    width: 40%;
}
.step3-bottom-total-price-block{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.step3-bottom-price,
.step3-bottom-price-total{
    padding-left: 10px;
    font-weight: 700;
    font-size: 22px;
}
.step3-bottom-price-total{
    color: #E30613;
}`, "",{"version":3,"sources":["webpack://./src/Page/Assistance Step3/Step3.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,iBAAiB;AACrB;AACA;IACI,cAAc;IACd,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,OAAO;IACP,aAAa;IACb,WAAW;IACX,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,UAAU;AACd;AACA;IACI,aAAa;IACb,6BAA6B;IAC7B,mBAAmB;AACvB;AACA;;IAEI,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,cAAc;AAClB","sourcesContent":[".step3-bottom-total{\n    font-size: 19px;\n    position: relative;\n    background-color: #E0E0E0;\n    padding: 5px 20px;\n}\n.step3-bottom-total > p {\n    display: block;\n    position: relative;\n    margin-bottom: 10px;\n}\n\n.step3-bottom-total > p::after {\n    content: '';\n    position: absolute;\n    left: 0;\n    bottom: -10px;\n    width: 100%;\n    border-bottom: 1px solid black;\n}\n\n.step3-bottom-total-container{\n    display: flex;\n    justify-content: space-between;\n}\n.step3-bottom-total-price{\n    display: flex;\n    justify-content: space-between;\n    width: 40%;\n}\n.step3-bottom-total-price-block{\n    display: flex;\n    justify-content: space-evenly;\n    align-items: center;\n}\n.step3-bottom-price,\n.step3-bottom-price-total{\n    padding-left: 10px;\n    font-weight: 700;\n    font-size: 22px;\n}\n.step3-bottom-price-total{\n    color: #E30613;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
