import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {AppDispatch} from "../../store";

interface AuthState {
    isAuthenticated: boolean;
    hasBeenAuthenticated: boolean;
}

const initialState: AuthState = {
    isAuthenticated: !!localStorage.getItem('Bearer'),
    hasBeenAuthenticated: false
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.isAuthenticated = action.payload;
        },
        setHasBeenAuthenticated: (state, action: PayloadAction<boolean>) => {
            state.hasBeenAuthenticated = action.payload;
        },
        checkAuthentication: (state) => {
            state.isAuthenticated = !!localStorage.getItem('Bearer');
        },
    },
});

export const { setAuthenticated, checkAuthentication, setHasBeenAuthenticated } = authSlice.actions;

export const handleTokenRemoval = () => (dispatch: AppDispatch) => {
    localStorage.removeItem('Bearer');
    dispatch(setAuthenticated(false));
    dispatch(setHasBeenAuthenticated(true));
};

export default authSlice.reducer;
