import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { connect, ConnectedProps } from "react-redux";
import './style.css';
import { updateProject } from "../../Slice/updateProject/updateProjectSlice";
import { transformTemplate } from "../../utils/TransformTemplate";
import { Template } from "../../types/TemplateType";
import { clearTemplates, updateLocalTemplate } from "../../Slice/LocalTemplate/LocalTemplateSlice";
import { RootState } from "../../store";
import parseTemplateToRequestObject from "../../Parsers/TemplateParse/templateToTemplateRequestParser";

interface WallCalculatorModalProps {
    templateIndex: number;
    isOpen: boolean;
    onSave: () => void;
    onClose: () => void;
    onTemplateUpdate: (updatedTemplates: Template[]) => void;
}

const WallCalculatorModal: React.FC<WallCalculatorModalProps & PropsFromRedux> = ({
                                                                                      project,
                                                                                      templateIndex,
                                                                                      isOpen,
                                                                                      onSave,
                                                                                      onClose,
                                                                                      updateProject,
                                                                                      updateLocalTemplate,
                                                                                      localTemplates,
                                                                                      onTemplateUpdate,
                                                                                      clearTemplates
                                                                                  }) => {
    const [selectedAnswers, setSelectedAnswers] = useState<Record<string, string>>({});
    const template = localTemplates[templateIndex] || project?.data?.templates?.[templateIndex] || null;

    useEffect(() => {
        if (template) {
            const initialSelectedAnswers: Record<string, string> = {};

            Object.entries(template.data || {}).forEach(([key, item]: [string, any]) => {
                if (!isNaN(parseInt(key)) && item?.value) {
                    initialSelectedAnswers[`${template.id}-calc-${key}`] = item.value;
                } else if (item?.defaultValue) {
                    initialSelectedAnswers[`${template.id}-calc-${key}`] = item.defaultValue;
                }
            });
            template.data?.fragments?.forEach((fragment: any, fragmentIndex: number) => {
                Object.entries(fragment.data || {}).forEach(([key, item]: [string, any]) => {
                    const uniqueKey = `fragment-${fragmentIndex}-${key}`;
                    if (item?.value) {
                        initialSelectedAnswers[uniqueKey] = item.value;
                    } else if (item?.defaultValue) {
                        initialSelectedAnswers[uniqueKey] = item.defaultValue;
                    }
                });
            });

            setSelectedAnswers(initialSelectedAnswers);
        }
    }, [template, updateProject]);

    const handleAnswerChange = (key: string, value: string) => {
        setSelectedAnswers((prevState) => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleInputChange = (key: string, value: string) => {
        if (/^\d*\.?\d*$/.test(value)) {
            setSelectedAnswers((prevState) => ({
                ...prevState,
                [key]: value
            }));
        }
    };

    const handleSubmit = () => {
        if (template && project && project.data && project.data.templates) {
            const updatedTemplate = {
                ...template,
                isCalculated: true,
                data: {
                    ...template.data,
                    ...Object.entries(template.data || {})
                        .filter(([key, item]: [string, any]) => !isNaN(parseInt(key)) && item?.question && item?.select)
                        .reduce((acc: Record<string, any>, [key, item]: [string, any]) => {
                            acc[key] = {
                                ...item,
                                value: selectedAnswers[`${template.id}-calc-${key}`] || item?.value,
                            };
                            return acc;
                        }, {}),
                    fragments: template.data?.fragments?.map((fragment: any, fragmentIndex: number) => ({
                        ...fragment,
                        data: Object.entries(fragment.data || {}).reduce((acc: Record<string, any>, [key, item]: [string, any]) => {
                            const uniqueKey = `fragment-${fragmentIndex}-${key}`;
                            acc[key] = {
                                ...item,
                                value: selectedAnswers[uniqueKey] || item?.value,
                                answers: item?.answers ? item.answers.map((answer: any) => ({
                                    ...answer,
                                    value: item.select === 'input' ? selectedAnswers[uniqueKey] : answer?.value,
                                    selected: item.select === 'input' ? true : selectedAnswers[uniqueKey] === answer?.value?.toString()
                                })) : item?.answers
                            };
                            return acc;
                        }, {})
                    }))
                }
            };

            updateLocalTemplate(updatedTemplate);

            const updatedTemplates = project.data.templates.map((tmpl, index) => index === templateIndex ? { ...updatedTemplate, counted: true } : tmpl);
            const transformedTemplates = updatedTemplates.map(transformTemplate);

            const updatedProjectData = {
                data: {
                    templates: transformedTemplates
                }
            };

            updateProject({ projectId: project.id, projectData: updatedProjectData });
            onTemplateUpdate(updatedTemplates);
            onSave();
            clearTemplates();
            onClose();
        } else {
            console.error("Project data or templates are undefined.");
        }
    };

    if (!template) {
        return <div>Loading...</div>;
    }

    const parserTemplate = parseTemplateToRequestObject(template);

    const filterVarMappingQuestions = (fragment: any) => {
        const hiddenKeys = new Set<string>();
        if (typeof fragment?.var_mapping === 'string') {
            const mappings = fragment.var_mapping.split('&&').map((map: string) => map.trim());
            mappings.forEach((mapping: string) => {
                const [left, right] = mapping.split('=').map((s: string) => s.trim());
                hiddenKeys.add(right);
            });
        }
        return hiddenKeys;
    };

    return (
        <Modal className="costs-calc" isOpen={isOpen} onRequestClose={onClose} overlayClassName="overlay-calc">
            <div className="costs-calc-content">
                <button onClick={onClose} className="calc-close-button">&times;</button>
                <h2>Required Information:</h2>
                <h3>{template.title}</h3>
                {Object.entries(template.data || {})
                    .filter(([key, item]: [string, any]) => !isNaN(parseInt(key)) && item?.question && item?.select)
                    .map(([key, templateQuestion]) => (
                        <div className="form-group-ccalc" key={`${template.id}-calc-${key}`}>
                            <label>{templateQuestion?.question || 'Default Question'}:</label>
                            <div className='calc-field'>
                                <input
                                    className='calc-field-input'
                                    type="text"
                                    value={selectedAnswers[`${template.id}-calc-${key}`] || templateQuestion.defaultValue || ''}
                                    onChange={(e) => handleInputChange(`${template.id}-calc-${key}`, e.target.value)}
                                    onBlur={(e) => handleAnswerChange(`${template.id}-calc-${key}`, e.target.value)}
                                />
                            </div>
                        </div>
                    ))
                }
                {parserTemplate?.data?.fragments?.map((fragment: any, fragmentIndex: number) => {
                    const hiddenKeys = filterVarMappingQuestions(fragment.data);

                    const hasQuestions = Object.entries(fragment.data || {}).some(([key, fragmentData]: [string, any]) => {
                        return !hiddenKeys.has(fragmentData?.key) && fragmentData?.question && fragmentData?.select;
                    });

                    if (!hasQuestions) {
                        return null;
                    }
                    return (
                        <div key={`fragment-${fragmentIndex}`} className="modal-calc-form-group">
                            <h4>{fragment.title || `Fragment ${fragmentIndex + 1}`}</h4>
                            {Object.entries(fragment.data || {})
                                .filter(([key, item]: [string, any]) => {
                                    return !hiddenKeys.has(item?.key) && item?.question && item?.select;
                                })
                                .map(([key, item]: [string, any]) => (
                                    <div key={`${fragmentIndex}-${key}`} className="form-group">
                                        <div className="question">{item.question || 'No question available'}</div>
                                        {item.select === 'select' ? (
                                            item.answers ? (
                                                item.answers.map((answer: any, idx: number) => (
                                                    <label key={idx} className="answer">
                                                        <input
                                                            type="radio"
                                                            name={`fragment-${fragmentIndex}-${key}`}
                                                            value={answer.value}
                                                            checked={selectedAnswers[`fragment-${fragmentIndex}-${key}`] === answer.value}
                                                            onChange={() => handleAnswerChange(`fragment-${fragmentIndex}-${key}`, answer.value)}
                                                        />
                                                        <span className="radio-custom"></span>
                                                        {answer.value} ({answer.title})
                                                    </label>
                                                ))
                                            ) : <p>No answers available</p>
                                        ) : (
                                            <div className='calc-field'>
                                                <input
                                                    className='calc-field-input'
                                                    type="text"
                                                    value={selectedAnswers[`fragment-${fragmentIndex}-${key}`] || item.defaultValue || ''}
                                                    onChange={(e) => handleInputChange(`fragment-${fragmentIndex}-${key}`, e.target.value)}
                                                    onBlur={(e) => handleAnswerChange(`fragment-${fragmentIndex}-${key}`, e.target.value)}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                        </div>
                    );
                })}
                <div className='form-calc-block' key={template.id}>
                </div>
                <div className='calc-btn-block'>
                    <button className="calc-btn" type="button" onClick={handleSubmit}>Save</button>
                </div>
            </div>
        </Modal>
    );
};

const mapStateToProps = (state: RootState) => ({
    localTemplates: state.localTemplate.templates,
    project: state.projectById.project
});

const mapDispatchToProps = {
    updateProject,
    updateLocalTemplate,
    clearTemplates
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(WallCalculatorModal);
