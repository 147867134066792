import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface UserRoleProps {
    redirectPath?: string;
    children: React.ReactNode;
}

const UserRoleRouteMiddleware: React.FC<UserRoleProps> = ({ redirectPath = '/auth/login', children }) => {
    const [isAuthorized, setIsAuthorized] = useState(true);

    useEffect(() => {
        const token = localStorage.getItem('Bearer');

        if (!token) {
            setIsAuthorized(false);
        }
    }, []);

    if (!isAuthorized) {
        return <Navigate to={redirectPath} replace />;
    }

    return <>{children}</>;
};

export default UserRoleRouteMiddleware;