import React, { useEffect, useState, useRef } from "react";
import Modal from "react-modal";
import { RootState } from "../../store";
import { connect, ConnectedProps } from "react-redux";
import 'react-toastify/dist/ReactToastify.css';
import './style.css';
import { fetchLazyProjects } from "../../Slice/getProjectAssistance/ProjectAssistanceSlice";
import { User } from "../../types/ProjectTypes";
import { IconUserDefaultProfile } from "../../IconComponents/IconComponents";
import { notifySuccess } from "../Toast/AssisCreateToastNotification";
import { addTemplatesToProjects } from "../../api/APIWraper";
import CreateProjectModal from "../Modals/ModalCreateProject/ModalCreateProject";

interface ModalAddTemplateProps {
    isOpen: boolean;
    tempId: number;
    tempTitle: string;
    onClose: () => void;
}

const PROJECTS_PER_PAGE = 10;

type PropsFromRedux = ConnectedProps<typeof connector>;
type CombineProps = ModalAddTemplateProps & PropsFromRedux;

const ModalAddTemplate: React.FC<CombineProps> = ({
                                                      allProjectsModalTemplate,
                                                      isFetchingLazy,
                                                      error,
                                                      total,
                                                      fetchLazyProjects,
                                                      isOpen,
                                                      onClose,
                                                      tempId,
                                                      tempTitle
                                                  }) => {
    const [selectedProjects, setSelectedProjects] = useState<number[]>([]);
    const [userImages, setUserImages] = useState<Map<number, string | null>>(new Map());
    const modalContentRef = useRef<HTMLDivElement>(null);
    const [localCurrentPage, setLocalCurrentPage] = useState<number>(1);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleCloseModal = () => setIsModalOpen(false);
    const handleOpenModal = () => setIsModalOpen(true);

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }

        return () => {
            document.body.classList.remove('no-scroll');
        };
    }, [isOpen]);

    useEffect(() => {
        const loadUserImages = () => {
            const imagesMap = new Map<number, string | null>();
            allProjectsModalTemplate.forEach(project => {
                const uniqueUsers = getUniqueUsers(project.users, project.permissions.map(permission => permission.user));
                uniqueUsers.forEach(user => {
                    const imageUrl = user.galleries && user.galleries.length > 0 && user.galleries[0].medias.length > 0
                        ? user.galleries[0].medias[0].url
                        : null;
                    imagesMap.set(user.id, imageUrl);
                });
            });
            setUserImages(imagesMap);
        };

        if (allProjectsModalTemplate.length > 0) {
            loadUserImages();
        }
    }, [allProjectsModalTemplate]);

    const handleCheckboxChange = (projectId: number) => {
        setSelectedProjects(prevSelectedProjects =>
            prevSelectedProjects.includes(projectId)
                ? prevSelectedProjects.filter(id => id !== projectId)
                : [...prevSelectedProjects, projectId]
        );
    };

    const handleAddTemplate = async () => {
        const selectedProjectTitles = allProjectsModalTemplate
            .filter(project => selectedProjects.includes(project.id))
            .map(project => `${project.title}`);

        const payload = {
            projects: selectedProjects,
        };
        await addTemplatesToProjects(tempId, payload);
        notifySuccess(`The template "${tempTitle}" was successfully added to ${selectedProjectTitles.join(', ')}`);
        setSelectedProjects([]);
        onClose();
    };

    const getUniqueUsers = (users: User[], permissionsUsers: User[]): User[] => {
        const allUsers = [...users, ...permissionsUsers];
        const uniqueUsersMap = new Map<number, User>();
        allUsers.forEach(user => {
            if (user && !uniqueUsersMap.has(user.id)) {
                uniqueUsersMap.set(user.id, user);
            }
        });
        return Array.from(uniqueUsersMap.values());
    };

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - scrollTop === clientHeight && hasMore && !isFetchingLazy) {
            const nextPage = localCurrentPage + 1;
            fetchLazyProjects({ page: nextPage, perPage: PROJECTS_PER_PAGE });
            setLocalCurrentPage(nextPage);
            if (nextPage * PROJECTS_PER_PAGE >= total) {
                setHasMore(false);
            }
        }
    };

    useEffect(() => {
        if (isOpen) {
            setLocalCurrentPage(1);
            setHasMore(true);
            setSelectedProjects([]);
            setUserImages(new Map());
            if (allProjectsModalTemplate.length === 0) {
                fetchLazyProjects({ page: 1, perPage: PROJECTS_PER_PAGE });
            } else {
                const initialPage = Math.ceil(allProjectsModalTemplate.length / PROJECTS_PER_PAGE);
                setLocalCurrentPage(initialPage);
                if (allProjectsModalTemplate.length >= total) {
                    setHasMore(false);
                }
            }
        }
    }, [isOpen, fetchLazyProjects, allProjectsModalTemplate.length, total]);

    if (error) return <p>Error: {error}</p>;
    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={onClose} className='modal-template' overlayClassName='overlay'>
                <div className='modal-template-header'>
                    <div className="modal-template-header-content">
                        <p>Select which project group you want to add the <strong>"{tempTitle}"</strong> project to</p>
                        <button onClick={onClose} className="template-close-button">&times;</button>
                    </div>
                </div>
                <div className="modal-template-content" ref={modalContentRef} onScroll={handleScroll}>
                    {allProjectsModalTemplate.map((project) => {
                        const uniqueUsers = getUniqueUsers(project.users, project.permissions.map(permission => permission.user));
                        const usersToShow = uniqueUsers.slice(0, 3);
                        const remainingUsersCount = uniqueUsers.length - usersToShow.length;

                        return (
                            <div key={project.id} className="template-project-item">
                                <div className="template-project-details">
                                    <div className="template-add-project-title">{project.title}</div>
                                    <div
                                        className="template-add-project-date">{new Date(project.created_at).toLocaleDateString()}</div>
                                    <div className="template-add-project-members">
                                        {usersToShow.map((user, index) => {
                                            const imageUrl = userImages.get(user.id);
                                            return imageUrl ? (
                                                <img key={index} src={imageUrl} alt="user" className="member-avatar"/>
                                            ) : (
                                                <IconUserDefaultProfile className='custom-avatar-member' key={index}/>
                                            );
                                        })}
                                        {remainingUsersCount > 0 && (
                                            <div className="more-members"><p>+{remainingUsersCount}</p></div>
                                        )}
                                    </div>
                                </div>
                                <input
                                    type="checkbox"
                                    className="template-project-checkbox"
                                    onChange={() => handleCheckboxChange(project.id)}
                                    checked={selectedProjects.includes(project.id)}
                                />
                            </div>
                        );
                    })}
                    {isFetchingLazy && (
                        <div className="loader-modal">
                            <div className="loader-template"></div>
                        </div>
                    )}
                    {!hasMore && (
                        <div className="end-of-list">
                        </div>
                    )}
                </div>
                <div className='template-add-project-btn'>
                    <button onClick={handleAddTemplate} className="template-add-btn">Add to projects group</button>
                </div>
                <div className='template-add-project-btn'>
                    <button onClick={handleOpenModal} className="template-add-btn">Create a New Project</button>
                </div>
                <CreateProjectModal
                    isOpen={isModalOpen}
                    onRequestClose={handleCloseModal}
                />
            </Modal>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    allProjectsModalTemplate: state.projectAssistance.allProjectsModalTemplate,
    isFetchingLazy: state.projectAssistance.isFetchingLazy,
    error: state.projectAssistance.error,
    total: state.projectAssistance.total,
});

const mapDispatchToProps = {
    fetchLazyProjects,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(ModalAddTemplate);
