import React, { useEffect, useState } from 'react';
import HeaderList from "../../components/HeaderRoutList/HeaderListRout";
import ElementList from "../../components/ElementList/ElementList";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "../../store";
import PaginationAssistance from "../../components/PaginationAssistance/pagination";
import { OrderHistory } from "../../types/OrderHistory";
import { Loader } from "../../components/Loader/Loader";
import './OrderHistory.css';
import { fetchOrderHistory } from "../../Slice/OrderHistory/OrderHistorySlice";
import MobileNavigation from "../../components/MobileNavigation/MobileNavigation";
import {IconArrowDownOrderHistory, IconArrowUpOrderHistory} from "../../IconComponents/IconComponents";

const OrdersHistory: React.FC<PropsFromRedux> = ({ orderHistory, totalOrders, isFetching, error, fetchOrderHistory }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [expandedOrders, setExpandedOrders] = useState<number[]>([]);
    const [isSmallScreen, setIsSmallScreen] = useState<boolean>(window.innerWidth <= 920);

    const perPage = 10;

    useEffect(() => {
        fetchOrderHistory({ page: currentPage, perPage });
    }, [currentPage]);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth <= 920);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    const toggleExpand = (orderId: number) => {
        setExpandedOrders(prevState =>
            prevState.includes(orderId)
                ? prevState.filter(id => id !== orderId)
                : [...prevState, orderId]
        );
    };

    if (!orderHistory) return null;
    if (isFetching) return <Loader />;
    if (error) return <p>Error: {error}</p>;

    const columns = ['ID', 'DATE', 'Project', 'Customer', 'Product', 'Address', 'Status', 'Total'];
    const columnsWidthClasses = ['column-id', 'column-data', 'column-project', 'column-user', 'column-items', 'column-address', 'column-pay', 'column-total', 'column-button'];

    return (
        <>
            {isSmallScreen && (
                <MobileNavigation
                    links={[
                        { to: '/', label: 'Cashbuild' },
                        { to: '', label: '>' },
                        { to: '/', label: 'Home' },
                        { to: '', label: '>' },
                        { to: '/assistance', label: 'Project assistance' },
                        { to: '', label: '>' },
                        { to: '/order_history', label: 'Order History' }
                    ]}
                />
            )}

            <HeaderList />

            <div>
                <div className='name-content-history all-projects'>
                    <p>Order history</p>
                    <img src='/img_name_content/oHistory.svg' alt='All projects' />
                </div>

                {!isSmallScreen && (
                    <ElementList columns={columns} columnsWidthClasses={columnsWidthClasses} />
                )}

                <div className='wrapper'>
                    <ul className="table-content-data-section-container">
                        {orderHistory.map((order: OrderHistory) => {
                            const isExpanded = expandedOrders.includes(order.id);
                            const hasMultipleItems = order.items.length > 1;
                            const totalPrice = parseFloat(order.total);
                            if (!isSmallScreen) {
                                return (
                                    <li className='order-history-li' key={order.id}>
                                        <section className="order-history-section">
                                            <p className='project-table-content-data-section-field-center-history history-id'>{order.id}</p>
                                            <p className='project-table-content-data-section-field-center-history history-data'>{new Date(order.created_at).toLocaleDateString()}</p>
                                            <p className='project-table-content-data-section-field-center-history history-project'>{order.project?.title}</p>
                                            <p className='project-table-content-data-section-field-center-history history-user'>{order.user.email}</p>
                                            <div className='project-table-content-data-section-field-center-history history-items'>
                                                <ul className='order-history-ul'>
                                                    {order.items.slice(0, 1).map((item, itemIndex) => (
                                                        <li key={itemIndex}>{item.title} ({item.pivot.qty}{item.unit})</li>
                                                    ))}
                                                    {isExpanded && order.items.slice(1).map((item, itemIndex) => (
                                                        <li key={itemIndex}>{item.title} ({item.pivot.qty}{item.unit})</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <p className='project-table-content-data-section-field-center-history history-address'>{order.address === undefined ? order.address : 'No address'}</p>
                                            <p className='project-table-content-data-section-field-center-history history-status'>{order.status === undefined ? order.status : 'No status'}</p>
                                            <p className='project-table-content-data-section-field-center-history history-total'>R {totalPrice.toFixed(2)}</p>
                                            {hasMultipleItems && (
                                                <img
                                                    onClick={() => toggleExpand(order.id)}
                                                    src={isExpanded ? '/img/up.svg' : '/img/down.svg'}
                                                    alt='Toggle'
                                                    className='history-button'
                                                />
                                            )}
                                        </section>
                                    </li>
                                );
                            }
                            return (
                                <li className={`order-history-li ${isExpanded ? 'expanded' : ''}`} key={order.id}>
                                    <section className="order-history-section">
                                        <div className="mobile-header">
                                            <p>{order.id}</p>
                                            <p>{new Date(order.created_at).toLocaleDateString()}</p>
                                            <p className="project-title">Project name: {order.project?.title}</p>
                                        </div>
                                        <div className="mobile-toggle-button" onClick={() => toggleExpand(order.id)}>
                                            {isExpanded ? <IconArrowUpOrderHistory/> : <IconArrowDownOrderHistory/>}
                                        </div>
                                    </section>
                                    {isExpanded && (
                                        <div className="expanded-content">
                                            <div>
                                                <div className="status">
                                                    <p className='expanded-content-name'>
                                                        {(order.user.first_name && order.user.last_name)
                                                            ? `${order.user.first_name} ${order.user.last_name}`
                                                            : order.user.username}
                                                    </p>
                                                    <div className='status-block'>
                                                    <div className={`status-indicator ${order.status === undefined ? 'green' : 'red'}`}></div>
                                                    <p className='expanded-content-status'>{order.status === undefined ? order.status : 'No status'}</p>
                                                    </div>
                                                </div>
                                                <p className='expanded-content-address'>{order.address === undefined ? order.address : 'No address'}</p>
                                            </div>
                                            <ul>
                                                {order.items.map((item, index) => (
                                                    <li className='expanded-content-items' key={index}>
                                                        {item.title} ({item.pivot.qty} {item.unit})
                                                    </li>
                                                ))}
                                            </ul>
                                            <p className="total-amount">Total: R {totalPrice.toFixed(2)}</p>
                                        </div>
                                    )}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>

            <PaginationAssistance
                projectsPerPage={perPage}
                totalProjects={totalOrders}
                paginate={paginate}
                currentPage={currentPage}
            />
        </>
    );

};

const mapStateToProps = (state: RootState) => ({
    orderHistory: state.orderHistory.orderHistory,
    totalOrders: state.orderHistory.totalOrders,
    isFetching: state.orderHistory.isFetching,
    error: state.orderHistory.error,
});
const mapDispatchToProps = {
    fetchOrderHistory
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OrdersHistory);
