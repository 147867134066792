import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import "../Assistance Step2/Step2Styles/step2.css";
import MaterialName from "../../components/Assis Step2 Component/MaterialName";
import { fetchProjectById } from '../../Slice/projectById/projectByIdSlice';
import { ItemType, Template, TItem } from "../../types/TemplateType";
import { Project } from "../../types/ProjectTypes";
import './Step3.css';
import { clearSelectedItems } from "../../Slice/SelectedItems/SelectedItemsSlice";
import { RootState } from "../../store";
import { Loader } from "../../components/Loader/Loader";
import { patch } from "../../api/APIWraper";
import { clearLocalStorageExceptToken } from "../../utils/ClearLocalStorage";
import { clearTemplates } from "../../Slice/LocalTemplate/LocalTemplateSlice";
import SwitcherStep2 from "../../components/Switcher/SwitcherStep2";
import {
    toastItemError,
    toastItemTrue
} from "../../components/Toast/AssisCreateToastNotification";
import {IconEyes} from "../../IconComponents/IconComponents";
import {getVendorLogo, Vendor} from "../../Enums/VendorEnum";

interface IPayloadData {
    item_type_id: number;
    item_id: number;
    template_index: number;
    fragment_index: number;
    item_type_index: number;
    template_id: number;
    fragment_id: number;
    ordered_qty: number;
}

const Step3: React.FC<PropsFromRedux> = ({ fetchProjectById, clearSelectedItems, project, isFetching }) => {
    const location = useLocation();
    const { projectId, templateIndex } = location.state;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [currentTemplate, setCurrentTemplate] = useState<Template | null>(null);
    const [currentTemplateIndex, setCurrentTemplateIndex] = useState<number>(-1);
    const [isSetTemplateIndex, setIsSetTemplateIndex] = useState<boolean>(false);
    const [percentage, setPercentage] = useState(0);
    const [inputQuantities, setInputQuantities] = useState<{ [key: string]: number }>({});
    const [items, setItems] = useState<{ [key: string]: ItemType[] }>({});
    const [laborItems, setLaborItems] = useState<any[]>([]);
    const [toolsItems, setToolsItems] = useState<any[]>([]);
    const [fragments, setFragments] = useState<{ [key: string]: any }>({});
    const [totalMaterial, setTotalMaterial] = useState<{ [key: string]: number }>({});
    const [totalLabor, setTotalLabor] = useState(0);
    const [totalTools, setTotalTools] = useState(0);
    const [viewMode, setViewMode] = useState<'fragments' | 'types'>('types');

    const [visitedItems, setVisitedItems] = useState<string[]>([]);


    const handleSwitchChange = (selectedOption: 'fragments' | 'types') => {
        setViewMode(selectedOption);
    };

    const addCloneId = (data: any) => {
        const updatedData = { ...data };

        for (const fragmentKey in updatedData) {
            if (updatedData.hasOwnProperty(fragmentKey)) {
                const fragment = updatedData[fragmentKey];

                for (const itemKey in fragment.items) {
                    if (fragment.items.hasOwnProperty(itemKey)) {
                        const item = fragment.items[itemKey];
                        item.clone_id = `${item.id}-${item.payload.item_type_index}-0`;
                        item.items = item.items.map((subItem: any, idx: number) => ({
                            ...subItem,
                            clone_id: `${subItem.id}-${item.payload.item_type_index}-${idx}`
                        }));
                    }
                }

                for (const laborKey in fragment.LaborItemTypes) {
                    if (fragment.LaborItemTypes.hasOwnProperty(laborKey)) {
                        const laborItem = fragment.LaborItemTypes[laborKey];
                        laborItem.clone_id = `${laborItem.id}-${laborItem.payload.item_type_index}-0`;
                        laborItem.items = laborItem.items.map((subItem: any, idx: number) => ({
                            ...subItem,
                            fragmentKey: fragmentKey,
                            clone_id: `${subItem.id}-${laborItem.payload.item_type_index}-${idx}`
                        }));
                    }
                }

                for (const toolKey in fragment.ToolsItemTypes) {
                    if (fragment.ToolsItemTypes.hasOwnProperty(toolKey)) {
                        const toolItem = fragment.ToolsItemTypes[toolKey];
                        toolItem.clone_id = `${toolItem.id}-${toolItem.payload.item_type_index}-0`;
                        toolItem.items = toolItem.items.map((subItem: any, idx: number) => ({
                            ...subItem,
                            fragmentKey: fragmentKey,
                            clone_id: `${subItem.id}-${toolItem.payload.item_type_index}-${idx}`
                        }));
                    }
                }
            }
        }

        return updatedData;
    };


    useEffect(() => {
        const data = localStorage.getItem('selectedFragments');
        const visitedData = localStorage.getItem('visitedItems');
        if (visitedData) {
            setVisitedItems(JSON.parse(visitedData));
        }

        if (data) {
            const parsedData = JSON.parse(data);
            const updatedData = addCloneId(parsedData);
            const itemsMap: { [key: string]: any[] } = {};
            const laborArray: any[] = [];
            const toolsArray: any[] = [];

            for (const fragmentKey in updatedData) {
                if (updatedData.hasOwnProperty(fragmentKey)) {
                    const fragment = updatedData[fragmentKey];
                    itemsMap[fragmentKey] = Object.values(fragment.items).flatMap((item: any) => item.items);

                    for (const laborKey in fragment.LaborItemTypes) {
                        if (fragment.LaborItemTypes.hasOwnProperty(laborKey)) {
                            fragment.LaborItemTypes[laborKey].items.forEach((item: any) => {
                                laborArray.push({
                                    ...item,
                                    fragmentKey: fragmentKey,
                                    template_index: fragment.LaborItemTypes[laborKey].payload.template_index,
                                    fragment_index: fragment.LaborItemTypes[laborKey].payload.fragment_index,
                                    item_type_index: fragment.LaborItemTypes[laborKey].payload.item_type_index,
                                    template_id: fragment.LaborItemTypes[laborKey].payload.template_id,
                                    item_type_id: fragment.LaborItemTypes[laborKey].payload.item_type_id,
                                });
                            });
                        }
                    }
                    for (const toolKey in fragment.ToolsItemTypes) {
                        if (fragment.ToolsItemTypes.hasOwnProperty(toolKey)) {
                            fragment.ToolsItemTypes[toolKey].items.forEach((item: any) => {
                                toolsArray.push({
                                    ...item,
                                    fragmentKey: fragmentKey,
                                    template_index: fragment.ToolsItemTypes[toolKey].payload.template_index,
                                    fragment_index: fragment.ToolsItemTypes[toolKey].payload.fragment_index,
                                    item_type_index: fragment.ToolsItemTypes[toolKey].payload.item_type_index,
                                    template_id: fragment.ToolsItemTypes[toolKey].payload.template_id,
                                    item_type_id: fragment.ToolsItemTypes[toolKey].payload.item_type_id,
                                });
                            });
                        }
                    }
                }
            }
            setItems(itemsMap);
            setLaborItems(laborArray);
            setToolsItems(toolsArray);
            setFragments(updatedData);
        }
    }, []);

    const handleLogoClick = (link:string,type:string,fragmentKey: string, itemId: number, cloneId?: number) => {
        const id = generateKey(type, fragmentKey, itemId, cloneId);
        const storedVisitedItems = JSON.parse(localStorage.getItem('visitedItems') || '[]');
        if (!storedVisitedItems.includes(id)) {
            const updatedVisited = [...storedVisitedItems, id];
            setVisitedItems(updatedVisited);
            localStorage.setItem('visitedItems', JSON.stringify(updatedVisited));
        }
        if (link) {
            window.location.href = link;
        }
    };


    useEffect(() => {
        if (templateIndex >= 0) {
            setCurrentTemplateIndex(templateIndex);
            localStorage.setItem('templateIndex', templateIndex.toString());
        }
        if (project) {
            if (project.data.templates && project.data.templates.length > 0) {
                const firstTemplate = project.data.templates[currentTemplateIndex];
                setCurrentTemplate(firstTemplate);
                setIsSetTemplateIndex(true);
            }
            setPercentage(getProjectProgress(project));
        }
    }, [project, templateIndex, currentTemplateIndex]);

    useEffect(() => {
        if (projectId) {
            fetchProjectById(parseInt(projectId));
        }
    }, [projectId, fetchProjectById]);

    useEffect(() => {
        calculateTotals(inputQuantities);
    }, [inputQuantities, viewMode]);

    const generateKey = (type: string, fragmentKey: string, itemId: number, cloneId: number | undefined) => {
        return `${type}-${fragmentKey}-${itemId}-${cloneId}`;
    };

    const handleQtyChange = (key: string, value: string) => {
        const newQuantities = {
            ...inputQuantities,
            [key]: parseInt(value, 10) || 0,
        };
        setInputQuantities(newQuantities);
        calculateTotals(newQuantities);
    };

    const calculateTotals = (quantities: { [key: string]: number }) => {
        const materialTotals: { [key: string]: number } = {};
        let laborTotal = 0;
        let toolsTotal = 0;
        for (const key in items) {
            materialTotals[key] = 0;
            items[key].forEach((item) => {
                const price = item.price ? parseFloat(item.price) : 0;
                const qtyKey = generateKey('items', key, item.id, item.clone_id);
                const qty = quantities[qtyKey] || 0;
                materialTotals[key] += price * qty;
            });
        }

        laborItems.forEach((item) => {
            const price = item.price ? parseFloat(item.price) : 0;
            const qtyKey = generateKey('labors', item.fragmentKey, item.id, item.clone_id);
            const qty = quantities[qtyKey] || 0;
            laborTotal += price * qty;
        });

        toolsItems.forEach((item) => {
            const price = item.price ? parseFloat(item.price) : 0;
            const qtyKey = generateKey('tools', item.fragmentKey, item.id, item.clone_id);
            const qty = quantities[qtyKey] || 0;
            toolsTotal += price * qty;
        });

        setTotalMaterial(materialTotals);
        setTotalLabor(laborTotal);
        setTotalTools(toolsTotal);
    };

    const getProjectProgress = (project: Project): number => {
        const summaryItemTypesProject = project?.data?.summary_item_types_project || [];
        let percent = 0;
        if (summaryItemTypesProject.length) {
            summaryItemTypesProject.forEach((ItemType) => {
                if (ItemType.percentage) percent += ItemType.percentage;
            });
            percent /= summaryItemTypesProject.length;
        }
        return Math.ceil((summaryItemTypesProject.length && !isNaN(percent)) ? percent : 0);
    };

    const getPayloadData = (
        fragments: { [key: string]: any },
        inputQuantities: { [key: string]: number }
    ): IPayloadData[] => {
        const payloadData: IPayloadData[] = [];

        const extractPayloadData = (subItem: any, itemType: any, key: string) => {
            const templateIndex = itemType.payload?.template_index !== undefined ? itemType.payload.template_index : itemType.template_index;
            const fragmentIndex = itemType.payload?.fragment_index !== undefined ? itemType.payload.fragment_index : itemType.fragment_index;
            const itemTypeIndex = itemType.payload?.item_type_index !== undefined ? itemType.payload.item_type_index : itemType.item_type_index;
            const templateId = itemType.payload?.template_id !== undefined ? itemType.payload.template_id : itemType.template_id;
            const fragmentId = itemType.payload?.fragment_id !== undefined ? itemType.payload.fragment_id : itemType.fragment_id;
            const itemTypeId = itemType.payload?.item_type_id !== undefined ? itemType.payload.item_type_id : itemType.item_type_id;
            const itemId = subItem.id;

            const currentQty = subItem.ordered_qty || 0;
            const inputQty = inputQuantities[key] || 0;

            return {
                item_type_id: itemTypeId,
                item_id: itemId,
                template_index: templateIndex,
                fragment_index: fragmentIndex,
                item_type_index: itemTypeIndex,
                template_id: templateId,
                fragment_id: fragmentId,
                ordered_qty: currentQty + inputQty,
            };
        };

        for (const fragmentKey in fragments) {
            const fragment = fragments[fragmentKey];

            Object.values(fragment.items).forEach((item: any) => {
                item.items.forEach((subItem: TItem) => {
                    const key = generateKey('items', fragmentKey, subItem.id, subItem.clone_id);
                    const payloadItem = extractPayloadData(subItem, item, key);
                    payloadData.push(payloadItem);
                });
            });

            Object.values(fragment.LaborItemTypes || {}).forEach((laborItem: any) => {
                laborItem.items.forEach((subItem: TItem) => {
                    const key = generateKey('labors', fragmentKey, subItem.id, subItem.clone_id);
                    const payloadItem = extractPayloadData(subItem, laborItem, key);
                    payloadData.push(payloadItem);
                });
            });

            Object.values(fragment.ToolsItemTypes || {}).forEach((toolItem: any) => {
                toolItem.items.forEach((subItem: TItem) => {
                    const key = generateKey('tools', fragmentKey, subItem.id, subItem.clone_id);
                    const payloadItem = extractPayloadData(subItem, toolItem, key);
                    payloadData.push(payloadItem);
                });
            });
        }

        return payloadData;
    };

    const handleSave = async () => {
        const payloadData = getPayloadData(fragments, inputQuantities);
        if (payloadData.length === 0) {
            console.error("No payload data to save.");
            return;
        }
        try {
            const response = await patch(`${process.env.REACT_APP_BASE_URL_CUSTOMER}/projects/${project?.id}/item_ordered_qty`, { data: payloadData });
            if (response.status === 200 || response.status === 201) {
                toastItemTrue(`Success!`);
            } else {
                toastItemError(`Error!`);
            }
        } catch (error) {
            console.error("Failed to save selections:", error);
        }
    };

    const buyAndNavigateToStep2 = async () => {
        await handleSave();
        localStorage.removeItem('selectedFragments');
        localStorage.removeItem('ToolsItemTypesInitialized');
        clearSelectedItems();
        setTimeout(() => {
            navigate('/assistance/step2', {state: {projectId: projectId, templIndex: currentTemplateIndex}});
        }, 1000);
    };

    const navigateToAssistance = () => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
        navigate('/assistance');
    };

    if (isFetching) {
        return <Loader />;
    }
    if (!project) {
        return null;
    }

    const descriptionText = currentTemplate ? currentTemplate.short_description || 'No description' : '';

    const renderItems = (items: { [key: string]: ItemType[] }) => {
        return Object.keys(items).map((fragmentKey) => {
            const [fragmentId, fragmentIndex, templateIndex] = fragmentKey.split('-').map(Number);
            const template = project?.data.templates?.[templateIndex];
            const fragment = template?.data.fragments?.[fragmentIndex];
            const fragmentItems = items[fragmentKey];
            const fragmentLaborItems = laborItems.filter(item => item.fragmentKey === fragmentKey);
            const fragmentToolsItems = toolsItems.filter(item => item.fragmentKey === fragmentKey);

            if (fragmentItems.length === 0 && fragmentLaborItems.length === 0 && fragmentToolsItems.length === 0) {
                return null;
            }
            let fragmentTotalLabor = 0;
            let fragmentTotalTools = 0;
            let fragmentTotalMaterial = 0;

            const renderItemsWithTitle = (items: ItemType[], type: "items" | "labors" | "tools") => {
                let lastItemTypeTitle = '';

                return items.map((item, index) => {
                    const key = generateKey(type, fragmentKey, item.id, item.clone_id);
                    const newOrderedQty = inputQuantities[key] || '';
                    const price = parseFloat(String(item.price)) || 0;
                    const newPrice = price * Number(newOrderedQty);
                    if (type === 'items') {
                        fragmentTotalMaterial += newPrice;
                    } else if (type === 'labors') {
                        fragmentTotalLabor += newPrice;
                    } else if (type === 'tools') {
                        fragmentTotalTools += newPrice;
                    }
                    const showTitle = lastItemTypeTitle !== item.item_type_title;
                    lastItemTypeTitle = item.item_type_title;
                    const visited = visitedItems.includes(key);
                    return (
                        <div key={key}>
                            {showTitle && <h4 className='view-fragments-h3'>{item.item_type_title}</h4>}
                            <div className='step2-item-type'>
                                <div className='step2-item-checkbox step3-material-width'>
                                    <div className='step3-other-bold'>
                                        <label>{item.title}</label>
                                    </div>
                                </div>
                                <div className="step2-tooltip-container">
                                    <input
                                        type="number"
                                        className="step3-other-width-input step3-ordered-qty-input"
                                        value={newOrderedQty}
                                        onChange={(e) => handleQtyChange(key, e.target.value)}
                                        min={0}
                                    />
                                </div>
                                <p className="step3-other-width">{item.ordered_qty || 0}/{item.qty} ({item.unit})</p>
                                <p className="step3-other-width">R {price.toFixed(2)}</p>
                                <p className="step3-other-width">R {(item.qty * price).toFixed(2)}</p>
                                <p className="step3-other-width-current-order step3-other-bold">R {isNaN(newPrice) ? 0 : newPrice.toFixed(2)}</p>
                                {!visited ? (
                                    <div className='step3-other-width-vendor'>
                                        <p className='step3-vendor-visited-proceed'>
                                            <span onClick={() => handleLogoClick(item.merchant_info?.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-eyes'><IconEyes/></span>
                                            <span onClick={() =>  handleLogoClick(item.merchant_info?.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-p'>Proceed to</span>
                                        </p>
                                            <img onClick={() =>  handleLogoClick(item.merchant_info?.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-img' src={getVendorLogo(item.vendor)}/>
                                    </div>
                                ) : (
                                    <div className='step3-other-width-vendor'>
                                        <img
                                            onClick={() => handleLogoClick(item.merchant_info?.link, type, fragmentKey, item.id, item.clone_id)}
                                            className='step3-vendor-img' src={getVendorLogo(item.vendor)}/>
                                        <p className='step3-vendor-visited'>
                                            <span onClick={() => handleLogoClick(item.merchant_info.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-eyes-check'><IconEyes/></span>
                                            <span onClick={() => handleLogoClick(item.merchant_info.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-p-check'>Visited</span></p>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                });
            };

            return (
                <div className='step2-fragment-block' key={`fragment-${fragmentId}-${fragmentIndex}`}>
                    <div className='step2-fragment'>
                        <MaterialName key={`material-${fragmentId}`} fragment={fragment} project={project}
                                      showSettingsButton={false}/>
                        {fragmentItems.length > 0 && (
                            <div>
                                <h3>Product name</h3>
                                {renderItemsWithTitle(fragmentItems, 'items')}
                            </div>
                        )}

                        {viewMode === 'fragments' && (
                            <>
                                {fragmentLaborItems.length > 0 && (
                                    <div>
                                        <h3>Labor Items</h3>
                                        {renderItemsWithTitle(fragmentLaborItems, 'labors')}
                                    </div>
                                )}
                                {fragmentToolsItems.length > 0 && (
                                    <div>
                                        <h3>Tools Items</h3>
                                        {renderItemsWithTitle(fragmentToolsItems, 'tools')}
                                    </div>
                                )}
                                <div className='step3-bottom-block'>

                                    <p>Total product:</p>
                                    <span>R {isNaN(fragmentTotalMaterial) ? '0.00' : fragmentTotalMaterial.toFixed(2)}</span>
                                    {fragmentLaborItems.length > 0 && (
                                        <>
                                        <p>Total labor:</p>
                                        <span>R {isNaN(fragmentTotalLabor) ? '0.00' : fragmentTotalLabor.toFixed(2)}</span>
                                        </>
                                    )}
                                    {fragmentToolsItems.length > 0 && (
                                        <>
                                            <p>Total labor:</p>
                                            <span>R {isNaN(fragmentTotalTools) ? '0.00' : fragmentTotalTools.toFixed(2)}</span>
                                        </>
                                    )}
                                </div>
                            </>
                        )}
                        {viewMode === 'types' && (
                            <div className='step3-bottom-block'>
                                <p>Total product:</p>
                                <span>R {isNaN(fragmentTotalMaterial) ? '0.00' : fragmentTotalMaterial.toFixed(2)}</span>
                            </div>
                        )}
                    </div>
                </div>
            );
        });
    };
    const renderLaborAndTools = () => {
        const renderItems = (items: any[], type: 'labors' | 'tools') => {
            return items.map((item) => {
                const title = item.title;
                const key = generateKey(type, item.fragmentKey, item.id, item.clone_id);
                const newOrderedQty = inputQuantities[key] || '';
                const price = item.price ? parseFloat(String(item.price)) : 0;
                const newPrice = price * parseFloat(String(newOrderedQty));
                const visited = visitedItems.includes(key);
                const fragmentKey = item.fragmentKey
                return (
                    <div key={key} className='step2-item-type'>
                        <div className='step2-item-checkbox step3-material-width'>
                            <div className='step3-other-bold'>
                                <label>{title}</label>
                            </div>
                        </div>
                        <div className="step2-tooltip-container">
                            <input
                                type="number"
                                className="step3-other-width-input step3-ordered-qty-input"
                                value={newOrderedQty}
                                onChange={(e) => handleQtyChange(key, e.target.value)}
                                min={0}
                            />
                        </div>
                        <p className="step3-other-width">{item.ordered_qty || 0}/{item.qty} ({item.unit})</p>
                        <p className="step3-other-width">R {price.toFixed(2)}</p>
                        <p className="step3-other-width">R {(item.qty * price).toFixed(2)}</p>
                        <p className="step3-other-width-current-oderd step3-other-bold">R {isNaN(newPrice) ? 0 : newPrice.toFixed(2)}</p>
                        {!visited ? (
                            <div className='step3-other-width-vendor'>
                                <p className='step3-vendor-visited-proceed'>
                                    <span onClick={() => handleLogoClick(item.merchant_info.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-eyes'><IconEyes/></span>
                                    <span onClick={() =>  handleLogoClick(item.merchant_info.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-p'>Proceed to</span>
                                </p>
                                <img onClick={() =>  handleLogoClick(item.merchant_info.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-img' src={getVendorLogo(item.vendor as Vendor)}/>
                            </div>
                        ) : (
                            <div className='step3-other-width-vendor'>
                                <img onClick={() =>  handleLogoClick(item.merchant_info?.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-img' src={getVendorLogo(item.vendor)}/>
                                <p className='step3-vendor-visited'>
                                    <span onClick={() => handleLogoClick(item.merchant_info.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-eyes'><IconEyes/></span>
                                    <span onClick={() => handleLogoClick(item.merchant_info.link,type,fragmentKey, item.id, item.clone_id)} className='step3-vendor-p-check'>Visited</span></p>
                            </div>
                        )}
                    </div>
                );
            });
        };

        return (
            <>
                {laborItems.length > 0 && (
                    <div className='step2-fragment-block'>
                        <MaterialName key={`labor-combined`} project={project} showSettingsButton={false}
                                      isLabor={true}/>
                        {renderItems(laborItems, 'labors')}
                        <div className='step3-bottom-block'>
                        <p>Total labor:</p>
                            <span>R {totalLabor.toFixed(2)}</span>
                        </div>
                    </div>
                )}
                {toolsItems.length > 0 && (
                    <div className='step2-fragment-block'>
                        <MaterialName key={`tools-combined`} project={project} showSettingsButton={false} isTool={true} />
                        {renderItems(toolsItems, 'tools')}
                        <div className='step3-bottom-block'>
                            <p>Total tools:</p>
                            <span>R {totalTools.toFixed(2)}</span>
                        </div>
                    </div>
                )}
            </>
        );
    };
    const hasItems = Object.values(items).some(array => array.length > 0);

    return (
        <div className='step2'>
            {isSetTemplateIndex && (
                <div>
                    <div className='template-name-content'>
                        <div className='template-blog-placeholder'>
                            <p>Home/</p>
                            <p onClick={navigateToAssistance} className='template-link-assistance'>Project assistance</p>
                            <p className='name-link'>/Create new project</p>
                        </div>
                    </div>
                    <div className='step-info'>
                        <div className='step-info-block'>
                            <h3>Step 3</h3>
                            <span>/</span>
                            <p className='step-info-block-name'>Specify parameters of building</p>
                        </div>
                    </div>
                    <div className='step-template-container'>
                        <div className='step-template-block left'>
                            <h2>Type:</h2>
                            <p className='step-template-block-title'>{currentTemplate?.title || 'template'}</p>
                        </div>
                        <div className='step-template-block right'>
                            <h2>Description:</h2>
                            <p className='step-template-block-desc'>{descriptionText}</p>
                        </div>
                        <div className='step-template-block-percent'>
                            <div className='progress-bar-wrapper'>
                                <CircularProgressbar
                                    value={percentage}
                                    styles={buildStyles({
                                        pathColor: '#E30613',
                                        textColor: '#000000',
                                        trailColor: '#d6d6d6',
                                        textSize: '24px',
                                        pathTransitionDuration: 0.5,
                                    })}
                                />
                                <div className='progress-bar-text'>
                                    {percentage}%
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='step-calc'>
                        <h2>Costs Summary</h2>
                            <SwitcherStep2
                                options={['fragments', 'types']}
                                onChange={handleSwitchChange}
                            />
                        <div className='step2-container'>
                            {viewMode === 'types' ? (
                                <>
                                    {hasItems && renderItems(items)}
                                    {renderLaborAndTools()}
                                </>
                            ) : (
                                <>
                                    {renderItems(items)}
                                </>
                            )}
                        </div>
                        <div className='step3-bottom-total'>
                            <p>Total:</p>
                            <div className='step3-bottom-total-container'>
                                <div className='step3-bottom-total-price'>
                                    <div className='step3-bottom-total-price-block'>
                                        <p>Total labour:</p>
                                        <span className='step3-bottom-price'>R {totalLabor.toFixed(2)}</span>
                                    </div>
                                    <div className='step3-bottom-total-price-block'>
                                        <p>Total tools:</p>
                                        <span className='step3-bottom-price'>R {totalTools.toFixed(2)}</span>
                                    </div>
                                    <div className='step3-bottom-total-price-block'>
                                        <p>Total product:</p>
                                        <span
                                            className='step3-bottom-price'>R {Object.values(totalMaterial).reduce((acc, val) => acc + val, 0).toFixed(2)}</span>
                                    </div>
                                </div>
                                <div className='step3-bottom-total-price-block'>
                                    <p>Total payable:</p>
                                    <span
                                        className='step3-bottom-price-total'>R {(Object.values(totalMaterial).reduce((acc, val) => acc + val, 0) + totalLabor + totalTools).toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='step2-bottom-btn'>
                        <div className='step2-bottom-btn-block'>
                            <div className='step-btn'>
                                <button className='step2-bottom-block-btn' title={'in development'}>Save & Request finance</button>
                            </div>
                            <div className='step-btn'>
                                <button onClick={buyAndNavigateToStep2} className='step2-bottom-block-btn'>Proceed to checkout</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: RootState) => ({
    project: state.projectById.project,
    isFetching: state.projectById.isFetching
});

const mapDispatchToProps = {
    fetchProjectById,
    clearSelectedItems,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Step3);
