import React from 'react';
import {ToastContainer, toast, Bounce} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assis-toastify-custom.css';

const ToastNotification = () => {
    return (
        <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            closeButton={false}
            newestOnTop
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
        />
    );
};

export const notifySuccess = (message: string) => {
    toast.dismiss();
    toast.success(message, {
        className: 'custom-toast-container custom-toast',
        position: 'top-left',
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
    });
};
export const notifySuccessProject = (message: string) => {
    toast.dismiss();
    toast.success(message, {
        className: 'custom-toast-container custom-toast',
        position: 'top-left',
        autoClose: 5000,
        hideProgressBar: true,
        closeButton: false,
    });
};

export const notifyInfo = (message: string) => {
    toast.dismiss();
    toast.info(message, {
        className: 'custom-toast-container custom-toast',
        position: 'top-left',
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: false,
    });
};

export const notifyInfoOrderTemplate = (message: string) => {
    toast.dismiss();
    toast.info(message, {
        className: 'custom-toast-container-order custom-toast-order',
        position: 'top-left',
        autoClose: 2500,
        hideProgressBar: true,
        closeButton: false,
    });
};

export const notifySuccessOrderTemplate = (message: string) => {
    toast.dismiss();
    toast.success(message, {
        className: 'custom-toast-container-order custom-toast-order',
        position: 'top-left',
        autoClose: 2500,
        hideProgressBar: true,
        closeButton: false,
    });
};

export const toastItemTrue = (message: string) => {
    toast.dismiss();
    toast.success(message, {
        position: "bottom-center",
        className: 'custom-toast-checkbox',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
};
export const toastItemCheckboxFalse = (message: string) => {
    toast.dismiss();
    toast.info(message, {
        className: 'custom-toast-checkbox',
        position: "bottom-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
    });
};

export const toastItemError = (message: string) => {
    toast.dismiss();
    toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}
export const notifyModalSuccess = (message: string) => {
    toast.success(message, {
        className: 'modal-toast',
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeButton: true,
    });
};

export default ToastNotification;
