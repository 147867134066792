// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.content-wrapper {
    padding: 0 35px;
}
@media (max-width: 700px) {
    .content-wrapper {
        overflow: hidden;
        padding: 0 5px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Page/Home/style.css"],"names":[],"mappings":";AACA;IACI,eAAe;AACnB;AACA;IACI;QACI,gBAAgB;QAChB,cAAc;IAClB;AACJ","sourcesContent":["\n.content-wrapper {\n    padding: 0 35px;\n}\n@media (max-width: 700px) {\n    .content-wrapper {\n        overflow: hidden;\n        padding: 0 5px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
