import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    deleteProjectById,
    getAllProjectsAssistance,
    getAllProjectsAssistanceTemplateModal, getLastProjectsAssistance,
    getOrderHistory
} from "../../api/APIWraper";
import { Project } from "../../types/ProjectTypes";
import { updateProjectPermissions } from "../../Slice/updateProject/updateProjectSlice";
import {OrderHistory} from "../../types/OrderHistory";

export interface ProjectsAssistanceState {
    projects: Project[];
    allProjects: Project[];
    allProjectsModalTemplate: Project[];
    total: number;
    isFetching: boolean;
    isFetchingLazy: boolean;
    error: string | null;
    currentPage: number;
    lazyPage: number;
    lastPage: number;
    hasMoreLazy: boolean;
    pages: Record<number, Project[]>;
    sortBy: string | null;
    sortOrder: 'asc' | 'desc' | null;
}

export const initialState: ProjectsAssistanceState = {
    projects: [],
    allProjects: [],
    allProjectsModalTemplate:[],
    total: 0,
    isFetching: false,
    isFetchingLazy: false,
    error: null,
    currentPage: 1,
    lazyPage: 1,
    lastPage: 1,
    hasMoreLazy: true,
    pages: {},
    sortBy: null,
    sortOrder: null
};



export const fetchAllProjects = createAsyncThunk<{allProjects: Project[]; total: number }, {page: number; perPage: number; sortBy?: string; sortOrder?: 'asc' | 'desc'  }>(
    'projectAssistance/fetchAllProjects',
    async ({  page, perPage,sortBy, sortOrder  }) => {
        return await getAllProjectsAssistance(page, perPage,sortBy, sortOrder);
    }
);


export const fetchLastProjects = createAsyncThunk(
    'projectAssistance/fetchLastProjects',
    async () => {
        const response = await getLastProjectsAssistance(1, 5, 'created_at', 'desc');
        return {
            projects: response.projects,
            pagination: response.pagination,
        };
    }
);


export const fetchLazyProjects = createAsyncThunk(
    'projectAssistance/fetchLazyProjects',
    async ({ page = 1, perPage = 10 }: { page?: number; perPage?: number }) => {
        const response = await getAllProjectsAssistanceTemplateModal(page, perPage);
        return {
            projects: response.projects,
            pagination: response.pagination,
            page,
        };
    }
);

export const deleteProject = createAsyncThunk(
    'projectAssistance/deleteProject',
    async (projectId: number) => {
        await deleteProjectById(projectId);
        return projectId;
    }
);

const projectAssistanceSlice = createSlice({
    name: 'projectAssistance',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAllProjects.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(fetchAllProjects.fulfilled, (state, action: PayloadAction<{ allProjects: Project[]; total: number }>) => {
                state.isFetching = false;
                state.allProjects = action.payload.allProjects;
                state.total = action.payload.total;
            })
            .addCase(fetchAllProjects.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            })
            .addCase(fetchLastProjects.pending, (state) => {
                state.isFetching = true;
            })
            .addCase(
                fetchLastProjects.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        projects: Project[];
                    }>
                ) => {
                    state.isFetching = false;
                    const { projects } = action.payload;
                    state.allProjects = projects;
                    const existingProjectIds = new Set(state.allProjects?.map((proj) => proj.id) || []);
                    const newProjects = projects.filter((proj) => !existingProjectIds.has(proj.id));
                    state.allProjects = [...(state.allProjects || []), ...newProjects];

                }
            )
            .addCase(fetchLastProjects.rejected, (state, action) => {
                state.isFetching = false;
                state.error = action.error.message ?? null;
            })

            .addCase(fetchLazyProjects.pending, (state) => {
                state.isFetchingLazy = true;
            })
            .addCase(
                fetchLazyProjects.fulfilled,
                (
                    state,
                    action: PayloadAction<{
                        projects: Project[];
                        pagination: {
                            total: number;
                            perPage: number;
                            currentPage: number;
                            lastPage: number;
                        };
                        page: number;
                    }>
                ) => {
                    state.isFetchingLazy = false;
                    const { projects, pagination, page } = action.payload;

                    state.lazyPage = page;
                    state.total = pagination.total;
                    state.hasMoreLazy = page < pagination.lastPage;

                    const existingProjectIds = new Set(state.allProjectsModalTemplate.map((proj) => proj.id));
                    const newProjects = projects.filter((proj) => !existingProjectIds.has(proj.id));
                    state.allProjectsModalTemplate = [...state.allProjectsModalTemplate, ...newProjects];
                }
            )
            .addCase(fetchLazyProjects.rejected, (state, action) => {
                state.isFetchingLazy = false;
                state.error = action.error.message ?? null;
            })
            .addCase(deleteProject.fulfilled, (state, action: PayloadAction<number>) => {
                const projectId = action.payload;
                state.allProjects = state.allProjects.filter(project => project.id !== projectId);
                Object.keys(state.pages).forEach((page) => {
                    state.pages[parseInt(page)] = state.pages[parseInt(page)].filter(
                        (project) => project.id !== projectId
                    );
                });
                state.total = state.allProjects.length;

                state.projects = state.pages[state.currentPage] || [];
            })
            .addCase(deleteProject.rejected, (state, action) => {
                state.error = action.error.message ?? null;
            })

            .addCase(updateProjectPermissions.fulfilled, (state, action: PayloadAction<Project>) => {
                const updatedProject = action.payload;
                state.allProjects = state.allProjects.map((project) =>
                    project.id === updatedProject.id ? updatedProject : project
                );
                Object.keys(state.pages).forEach((page) => {
                    state.pages[parseInt(page)] = state.pages[parseInt(page)].map((project) =>
                        project.id === updatedProject.id ? updatedProject : project
                    );
                });
                state.projects = state.pages[state.currentPage] || [];
            });
    },
});

export default projectAssistanceSlice.reducer;
