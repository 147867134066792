import React from 'react';
import './style.css';
import { useDispatch } from 'react-redux';
import { clearTemplates } from '../../Slice/LocalTemplate/LocalTemplateSlice';
import {clearLocalStorageExceptToken} from "../../utils/ClearLocalStorage";
import {useNavigate} from "react-router-dom";

function Header() {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const handleLinkClick = () => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
    };
    const handleLogoClick = () => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
        navigate('/assistance')
    };

    return (
        <div className='header'>
            <div className="hamburger-menu">
                <input id="menu__toggle" type="checkbox"/>
                <label className="menu__btn" htmlFor="menu__toggle">
                    <span></span>
                    <p>MENU</p>
                </label>
                <ul className="menu__box">
                    <li><a className="menu__item" href="/assistance" onClick={handleLinkClick}>Home</a></li>
                    <li><a className="menu__item" href="/projects" onClick={handleLinkClick}>Project group</a></li>
                    <li><a className="menu__item" href="/team" onClick={handleLinkClick}>Team Management</a></li>
                    <li><a className="menu__item" href="/order_history" onClick={handleLinkClick}>Order history</a></li>
                    <li><a className="menu__item" href="#" onClick={handleLinkClick}>Contacts</a></li>
                </ul>
            </div>

            <div className='menu-logo'>
                <a href='' onClick={handleLogoClick}><img src={'/img/placeholder-logo-1.png'} alt="Icon"/></a>
            </div>
            <div className='menu-form-wrapper'>
                <form className='menu-form'>
                    <div className='menu-search'>
                        <div className='search-icon'>
                            <img className='icon' src={'/img/search24.png'} alt='search'/>
                        </div>
                        <input className='search-input' placeholder='Search our products' type='search'/>
                    </div>
                </form>
            </div>
            <div className='menu-media'>
                <img src={'/img/stiker.png'} alt={'stik'}/>
                <img src={'/img/basket.png'} alt={'basket'}/>
                <img src={'/img/heart.png'} alt={'heart'}/>
                <img src={'/img/profile.png'} alt={'profile'}/>
            </div>
        </div>
    );
}

export default Header;
