import './style.css';
import { useState } from 'react';
import { clearLocalStorageExceptToken } from "../../utils/ClearLocalStorage";
import { clearTemplates } from "../../Slice/LocalTemplate/LocalTemplateSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IconLogOut } from "../../IconComponents/IconComponents";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function TopHeader() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isSpinning, setIsSpinning] = useState(false);

    const navigateToAssistance = () => {
        clearLocalStorageExceptToken();
        dispatch(clearTemplates());
        navigate('/assistance');
    };

    const handleLogOut = () => {
        toast.info('Wait, we\'re coming out...');
        setIsSpinning(true);

        setTimeout(() => {
            localStorage.removeItem('Bearer');
            navigate('/auth/login');
            setIsSpinning(false);
            toast.success('You have successfully logged out!!');
        }, 1500);
    };

    return (
        <div className='top-header'>
            <div className='top-header-delivery'>
                <img className='top-header-truck' src={'/img/truck.png'} alt="truck"/>
                <p>Deliveries throughout South Africa T&C’s apply</p>
            </div>
            <div className='top-header-red'>
                <div className='top-header-left-container'>
                    <button onClick={handleLogOut} className='top-header-btn-logout'>
                        <div className={`icon-container ${isSpinning ? 'spin-logout' : ''}`}>
                            <IconLogOut />
                        </div>
                    </button>
                    <button onClick={navigateToAssistance} className='top-header-btn'>
                        Project Assistance
                    </button>
                </div>
                <div className='top-header-right-container'>
                    <button className='top-header-btn-calc'>Building Material Calculator</button>
                </div>
            </div>
        </div>
    );
}

export default TopHeader;
