export enum Vendor {
    Cashbuild = 'cashbuild',
    Afrisam = 'afrisam',
    LeroyMerlin = 'leroy merlin',
    Default = 'default',
}
export const vendorLogos: Record<Vendor, string> = {
    [Vendor.Cashbuild]: '/img_vendor/cashbuild.svg',
    [Vendor.Afrisam]: '/img_vendor/afrisam.svg',
    [Vendor.LeroyMerlin]: '/img_vendor/LeroyMerlin.svg',
    [Vendor.Default]: '/img_vendor/default.png',
};

export const getVendorLogo = (vendor: Vendor): string => {
    if (!vendor) {
        return vendorLogos[Vendor.Default];
    }
    return vendorLogos[vendor] || vendorLogos[Vendor.Default];
};