import './ImageUpload.css'
import { IconRemoveImage } from "../../IconComponents/IconComponents";
import { Delete } from "../../api/APIWraper";
import { TMedia } from "../../types/TMedia";

interface ImageMultipleUploadProps {
    imageFiles: File[];
    setImageFiles: React.Dispatch<React.SetStateAction<File[]>>;
    imagePreviews: (TMedia | string)[];
    setImagePreviews: React.Dispatch<React.SetStateAction<(TMedia | string)[]>>;
    disabled?: boolean;
    qtyRule?: number;
}

const ImageUpload: React.FC<ImageMultipleUploadProps> = ({
                                                             setImageFiles,
                                                             imagePreviews,
                                                             setImagePreviews,
                                                             disabled = false,
                                                             qtyRule
                                                         }) => {
    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = Array.from(event.target.files || []);

        if (qtyRule !== undefined && (imagePreviews.length + files.length) > qtyRule) {
            const allowedFiles = files.slice(0, qtyRule - imagePreviews.length);
            setImageFiles(prevFiles => [...prevFiles, ...allowedFiles]);

            const filePreviews = allowedFiles.map(file => URL.createObjectURL(file));
            setImagePreviews(prevPreviews => [...prevPreviews, ...filePreviews]);
        } else {
            setImageFiles(prevFiles => [...prevFiles, ...files]);

            const filePreviews = files.map(file => URL.createObjectURL(file));
            setImagePreviews(prevPreviews => [...prevPreviews, ...filePreviews]);
        }
        event.target.value = '';
    };

    const removeImage = (index: number) => {
        setImageFiles(prevFiles => prevFiles.filter((_, i) => i !== index));
        setImagePreviews(prevPreviews => prevPreviews.filter((_, i) => i !== index));
        if (typeof imagePreviews[index] !== 'string') {
            const imagePreview = imagePreviews[index] as TMedia;
            Delete(`/media/${imagePreview.id}`);
        }
    };

    const isMaxImagesReached = qtyRule !== undefined && imagePreviews.length >= qtyRule;

    return (
        <section className="image-upload-container">
            {!isMaxImagesReached && (
                <div className='custom-modal-content'>
                    <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                        className="custom-modal-input"
                        id="imageUpload"
                        disabled={isMaxImagesReached}
                    />
                    <label htmlFor="imageUpload" className="file-upload-container">
                        Add project image
                    </label>
                </div>
            )}

            <div className="image-previews">
                {imagePreviews.map((preview, index) => (
                    <div
                        key={index}
                        className={`image-preview-container ${imagePreviews.length === 1 ? 'single-image' : ''}`}
                    >
                        <img
                            src={typeof preview === "string" ? preview : preview.url}
                            alt={`Preview ${index + 1}`}
                            className="image-preview"
                        />
                        {!disabled && (
                            <button
                                className="remove-image-button"
                                disabled={disabled}
                                onClick={() => removeImage(index)}
                            >
                                <IconRemoveImage />
                            </button>
                        )}
                    </div>
                ))}
            </div>
        </section>
    );
};

export default ImageUpload;
